import React, { useContext } from "react";
import useClasses from '../../../../../hooks/useClasses';
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "../../../../Shared/TextField/TextField";
import AMAvatar from "./AMAvatar/AMAvater";
// import Moment from "react-moment";
// import Tooltip from "@mui/material/Tooltip";
import ClientsContext from "../../../../../context/clients-context";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    minWidth: 200,
  },
  country: {
    width: 70,
  },
  clearIcon: {
    color: "grey",
  },
  cellPadding: {
    paddingRight: 5,
  },
  tooltipCell: {
    paddingRight: 5,
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "rgb(55 150 211 / 0.08)",
    },
  },
});

// function formatDate(d) {
//   if (d) {
//     const date = new Date(d);
//     const day = "0" + date.getDate();
//     const month = "0" + (date.getMonth() + 1);
//     const year = date.getFullYear();
//     // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
//     return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3);
//   } else {
//     return null;
//   }
// }

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#009be5",
//     color: "rgba(255, 255, 255, 0.87)",
//     maxWidth: 400,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }))(Tooltip);

export default function ClientRow(props) {
  const classes = useClasses(styles);
  const rowStatus = props.getRowStatus();
  const labelId = `enhanced-table-checkbox-${props.client.id}`;
  const ref = React.createRef();

  const clientsContext = useContext(ClientsContext);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => {
          if (rowStatus.state === "read") {
            props.handleClick(event, props.client.id, ref);
          }
        }}
        role="checkbox"
        aria-checked={rowStatus.checked}
        tabIndex={-1}
        selected={rowStatus.checked}
        ref={ref}
        className={classes.tableRow}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={rowStatus.checked}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          className={classes.cellPadding}
        >
          <TextField
            value={props.client.name}
            state={rowStatus.state}
            updateValue={(value) => props.editClient("name", value)}
            href={"./" + props.client.id}
          />
        </TableCell>
        <TableCell align="left" padding="none" className={classes.cellPadding}>
          <TextField
            value={props.client.reportingName}
            state={rowStatus.state}
            updateValue={(value) => props.editClient("reportingName", value)}
          />
        </TableCell>
        <TableCell align="left" padding="none" className={classes.cellPadding}>
          {(() => {
            const hideDropdown =
              props.client.sector &&
              (props.client.sector === "Other" ||
                clientsContext.sectors.findIndex((s) => {
                  return s.sector === props.client.sector;
                }) < 0) &&
              props.client.sector !== "";

            if (rowStatus.state === "edit") {
              return (
                <span>
                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.input}
                      value={props.client.sector || ""}
                      onChange={(e) => {
                        props.editClient("sector", e.target.value);
                      }}
                      style={{ display: hideDropdown ? "none" : "unset" }}
                      inputProps={{
                        name: "sector",
                        id: "sector-simple",
                      }}
                    >
                      {clientsContext.sectors.map((item) => {
                        return (
                          <MenuItem key={item.sector} value={item.sector}>
                            {item.sector}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      value={props.client.sector}
                      state={rowStatus.state}
                      updateValue={(value) => props.editClient("sector", value)}
                      hidden={!hideDropdown}
                    />
                  </FormControl>
                </span>
              );
            } else {
              return props.client.sector;
            }
          })()}
        </TableCell>
        <TableCell align="right" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ marginLeft: 10 }}
          >
            {(() => {
              if (props.client.accountManagers) {
                return props.client.accountManagers.map((AM, idx) => {
                  return <AMAvatar am={AM} key={idx} />;
                });
              } else {
                return null;
              }
            })()}
          </Grid>
        </TableCell>
        <TableCell align="left" padding="none">
          {(() => {
            if (rowStatus.state === "edit") {
              return (
                <FormControl className={classes.formControl}>
                  <Select
                    className={classes.country}
                    value={props.client.country || ""}
                    onChange={(e) => {
                      props.editClient("country", e.target.value);
                    }}
                    inputProps={{
                      name: "country",
                      id: "country-simple",
                    }}
                  >
                    {clientsContext.countries.map((item) => {
                      return (
                        <MenuItem key={item.country} value={item.country}>
                          {item.country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            } else {
              if (props.client.nextRenewals && props.client.nextRenewals[0]) {
                return (
                  // <HtmlTooltip
                  //   title={
                  //     <table>
                  //       <thead>
                  //         <tr>
                  //           <th className={classes.tooltipCell}>Vendor</th>
                  //           <th className={classes.tooltipCell}>Product</th>
                  //           <th className={classes.tooltipCell}>Qty</th>
                  //           <th className={classes.tooltipCell}>Renewal Yr</th>
                  //         </tr>
                  //       </thead>
                  //       <tbody>
                  //         {props.client.nextRenewals.map((renewal, idx) => {
                  //           return (
                  //             <tr key={props.client + "-" + idx}>
                  //               <td className={classes.tooltipCell}>
                  //                 {renewal.softwareVendor}
                  //               </td>
                  //               <td className={classes.tooltipCell}>
                  //                 {renewal.softwareProduct}
                  //               </td>
                  //               <td className={classes.tooltipCell}>
                  //                 {renewal.qty}
                  //               </td>
                  //               <td className={classes.tooltipCell}>
                  //                 {renewal.renewalYear} /{" "}
                  //                 {renewal.contractLength}
                  //               </td>
                  //             </tr>
                  //           );
                  //         })}
                  //       </tbody>
                  //     </table>
                  //   }
                  // >
                  //   <Moment format="D MMM YYYY">
                  //     {formatDate(
                  //       parseInt(props.client.nextRenewals[0].renewalDate)
                  //     )}
                  //   </Moment>
                  // </HtmlTooltip>
                  null
                );
              } else {
                return null;
              }
            }
          })()}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
