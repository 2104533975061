import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const userMutation = gql`
  mutation User(
    $salesSearches: [UpdateSalesSearch]
  ) {
    updateUser(
      input: {
        config : {
          salesPortal : {
            salesSearches: $salesSearches
          }
        }
      }
    ) {
      config {
            salesPortal {
                salesSearches {
                    name
                    filters {
                        id
                        value
                    }
                }
            }
        }
    }
  }
`;

const parseFilters = async (filters) => {
  return filters.map((filter) => {
    if (filter.type === "select") {
      let valStr = "";
      filter.value.forEach((val) => {
        if (valStr === "") {
          valStr = val.value;
        } else {
          valStr += ","+val.value;
        }
      });
      return {
        id: filter.id,
        value: valStr
      };
    } else {
      const value = filter.value;
      return {
        id: filter.id,
        value: value.toString()
      };
    }
  });

  
};

export default function FormDialog(props) {
  const {
    close,
    show,
    filters,
    quickFilters,
    updateQuickFilters
  } = props;
  const [filterName, setFilterName] = useState('');

const [
  saveQuickFilters,
    { loading: mutationLoading },
  ] = useMutation(userMutation, {
    update: (store, { data: { updateUser }, error }) => {
      console.log("[SaveQuickFilter.js] graphql update", updateUser);
      updateQuickFilters(updateUser.config.salesPortal.salesSearches);
      close();
    },
    onError: (error) => {
      console.error("[SaveQuickFilter.js] GraphQL Error", error);
    },
  });

  return (
    <div>
      <Dialog open={show} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Save Quick Filter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Save your current filter state for quick access in the future.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Filter Name"
            type="text"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        { mutationLoading ? <LinearProgress /> : null}
        <DialogActions>
          <Button onClick={close}>
            Cancel
          </Button>
          <Button color="primary" onClick={async () => {
              const newQuickFilters = quickFilters;
              newQuickFilters.map((qf) => {
                delete qf.__typename
                return qf
              })
              const newFilters = await parseFilters(filters);
              newQuickFilters.push({
                name: filterName,
                filters: newFilters
              });
              console.log("[SaveQuickFilter.js] Saving Quick Filters", newQuickFilters);
              saveQuickFilters({
                variables: {
                  salesSearches: newQuickFilters
                },
              });
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
