import React, { useState, useEffect } from "react";
import useClasses from '../../../../hooks/useClasses';
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import General from "./General";
import Licenses from "./Licenses";
import Admin from "./Admin";
import Signature from "./Signature";
//import { Test } from './Sale.styles';

const styles = theme => ({
  list: {
    width: "90vw",
    backgroundColor: "#eaeff1",
    height: "100vh",
    overflowX: "hidden",
    marginTop: 48,
  },
  fullList: {
    width: "auto",
  },
  title: {
    flexGrow: 1,
  },
});

const Sale = (props) => {
  const classes = useClasses(styles);
  const [sale, setSale] = useState({
    id: null,
    date: null,
    vendorOrderNumber: null,
    user: null,
    amountDollars: null,
    amountGbp: null,
    amountEuro: null,
    clientName: null,
    faInvoiceNumber: null,
    paymentGroup: null,
    sam: null,
    poNumber: null,
    payee: null,
    products: [],
    status: null,
    region: null,
    renewal: null,
    notes: null,
    regionalInvoiceNumber: null,
    adminNotes: null,
    online: null,
    conversion: null,
    amountTax: null,
    vendorInvoice: null,
    legacy: false,
    signed: false,
    signedBy: null,
    signedAt: null,
    licenseStart: null,
    licenseEnd: null
  });

  const saleQuery = gql`
    query Sale($id: ID) {
      getSales(id: $id) {
        id
        date
        vendorOrderNumber
        user
        amountDollars
        amountGbp
        amountEuro
        clientName
        faInvoiceNumber
        paymentGroup
        sam
        poNumber
        payee
        products {
          vendor
          name
          count
        }
        status
        region
        renewal
        notes
        regionalInvoiceNumber
        adminNotes
        online
        conversion
        amountTax
        vendorInvoice
        vendorEntity
        legacy
        signed
        signedBy
        signedAt
        licenseStart
        licenseEnd
      }
    }
  `;

  const { loading, data } = useQuery(saleQuery, {
    variables: {
      id: props.id,
    },
    skip: props.id === null,
    fetchPolicy: "cache-and-network",
    pollInterval: 60000,
  });

  useEffect(() => {
    console.log("[Sale.js] data", data);
    if (data && data.getSales) {
      setSale(data.getSales[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!sale || (sale.id === null && loading)) {
    return (
      <div className={classes.list}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Loading ...
            </Typography>
            <IconButton color="inherit" onClick={() => props.close()} size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
  return (
    <div className={classes.list}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {sale.id} - {sale.clientName}
          </Typography>
          <IconButton color="inherit" onClick={() => props.close()} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} style={{ margin: 0 }}>
        <Grid item xs={12}>
          <General saleRecord={sale} />
        </Grid>
        <Grid item xs={12}>
          <Signature saleRecord={sale} userProfile={props.userProfile} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Licenses saleRecord={sale} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Admin saleRecord={sale} />
        </Grid>
      </Grid>
    </div>
  );
};

Sale.propTypes = {
  // bla: PropTypes.string,
};

Sale.defaultProps = {
  // bla: 'test',
};

export default Sale;
