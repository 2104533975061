import React, {useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import LinearProgress from '@mui/material/LinearProgress';

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export default function ThePlan({noToken}) {

  const [viz, setViz] = useState(null);
  const [curToken, setCurToken] = useState("");
  const [width, setWidth] = useState(getWidth());

  const vizRef = useRef(null);

  const tableauToken = gql`
    query TableauToken {
      getTableauJWT {
        jwt
        result
      }
    }
  `;

  const { data, refetch, loading } = useQuery(tableauToken, {
    fetchPolicy: "no-cache",
  });

  function loadViz(token) {
    const showMobile = width <= 1050;
    let vizWidth = (width / 3);
    let vizHeight = 400;
    if (showMobile && width <= 600) {
      vizWidth = width - 150;
      vizHeight = 350;
    } else if (showMobile) {
      vizWidth = (width / 3);
      vizHeight = 400;
    }

    setViz(
      <tableau-viz 
        ref={vizRef}
        id="tableauViz"       
        src={"https://tableauserver.theinformationlab.co.uk/t/til2/views/Plan/Thisweek"}
        height={vizHeight}
        width={vizWidth}
        device={showMobile ? "phone" : "desktop"}
        hide-tabs={true}
        token={noToken ? null : token}
        toolbar='hidden'
        >
        </tableau-viz>
    );
  }

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurToken("");
    if (data && data.getTableauJWT) {
      refetch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.getTableauJWT && data.getTableauJWT.jwt) {
      setCurToken(data.getTableauJWT.jwt);
    }
  }, [data]);

  useEffect(() => {
    if (curToken && curToken.length > 0) {
      loadViz(curToken);
    }
    return () => {
      setCurToken("");
    };
    // eslint-disable-next-line
  }, [curToken]);

  return (
    <>
      <Helmet> 
        <script type="module" src="https://tableauserver.theinformationlab.co.uk/javascripts/api/tableau.embedding.3.1.0.min.js" async></script>
      </Helmet>
      <Card>
      <CardHeader  title="The Plan" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
        <CardMedia image="/static/images/whiteBubbles.png" />
      </CardHeader>
      {loading ? <LinearProgress /> : null}
      <CardContent>
        {viz}
      </CardContent>
    </Card>
    </>
    
  );
  
}
