import React, { useState  } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import useClasses from '../../../hooks/useClasses';
import TableHeaderRow from "./TableHeaderRow/TableHeaderRow";
import TableActionsRow from "./TableActionsRow/TableActionsRow";
import TableBodyClients from "./TableBodyClients/TableBodyClients";
import MultiEdit from "./MultiEdit/MultiEdit";
import { useSetUrlFilters } from "../../Shared/UrlFiltering";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  edit: {
    marginTop: -5,
  },
});

function Clients(props) {
  const classes = useClasses(styles);
  const { state, updateState } = props;
  const [accounts, setAccounts] = React.useState([]);
  const [rowStates, setRowStates] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(accounts.length || 0);
  const [showFilter, setShowFilter] = React.useState(props.filters.length > 0);
  const [showMultiEdit, setShowMultiEdit] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(true);
  const [ globalSearch , setGlobalSearch ] = useState();
  const [filterable, setFilterable] = useState([
    {
      id: "name",
      label: "Client Name",
      type: "text",
      inUse: false,
    },
    {
      id: "reportingName",
      label: "Reporting Name",
      type: "text",
      inUse: false,
    },
    {
      id: "sector",
      context: {
        array: "sectors",
        label: "sector",
        value: "sector",
        type: "string",
      },
      label: "Sector",
      type: "select",
      inUse: false,
    },
    {
      id: "accountManager",
      context: {
        array: "accountManagers",
        label: "name",
        value: "id",
        type: "int",
      },
      label: "Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "tag",
      context: {
        array: "tags",
        label: "tag",
        value: "tag",
        type: "string",
      },
      label: "Tag",
      type: "select",
      inUse: false,
    },
    {
      id: "tableauAccountManager",
      context: {
        array: "tableauAccountManagers",
        label: "name",
        value: "id",
        type: "int",
      },
      label: "Tableau Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "alteryxAccountManager",
      context: {
        array: "alteryxAccountManagers",
        label: "name",
        value: "id",
        type: "int",
      },
      label: "Alteryx Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "cmmLevel",
      context: {
        array: "cmmLevel",
        label: "name",
        value: "id",
        type: "int",
      },
      label: "Customer Maturiy Level",
      type: "select",
      inUse: false,
    },
    {
      id: "cmmRanking",
      context: {
        array: "cmmRanking",
        label: "rank",
        value: "rank",
        type: "string",
      },
      label: "Customer Ranking",
      type: "select",
      inUse: false,
    },
    {
      id: "country",
      context: {
        array: "countries",
        label: "country",
        value: "country",
        type: "string",
      },
      label: "Country",
      type: "select",
      inUse: false
    },
    {
      id: "sfdcCustomer",
      label: "Salesforce Customer",
      type: "boolean",
      inUse: false,
    },
    {
      id: "awsCustomer",
      label: "AWS Customer",
      type: "boolean",
      inUse: false,
    },
  ]);

  function handleUpdateRowCount(count) {
    setRowCount(count);
  }

  function handleRequestSort(event, property) {
    console.log(
      "[Clients.js] Sort Order",
      state.orderBy,
      property,
      state.order,
      state.orderBy === property && state.order === "desc"
    );
    const sameProperty = state.orderBy === property;
    const isAsc = state.order === "asc";
    updateState({
      ...state,
      order: sameProperty && isAsc ? "desc" : "asc",
      orderBy: property,
      page: 0,
    });
  }

  function handleRowClick(event, id, ref) {
    console.log("[Clients.js] handleRowClick", id);
    const source = event.target.type;
    console.log("[Clients.js] handleRowClick source", source);
    const rowStateIndex = rowStates.findIndex((r) => {
      return r.id === id;
    });
    let newRowStates = [];
    if (source !== "checkbox") {
      console.log("[Clients.js] handleClick checkbox");
      newRowStates = [
        {
          id,
          state: "read",
          checked: true,
        },
      ];
    } else if (rowStateIndex === -1) {
      const state = {
        id,
        state: "read",
        checked: true,
      };
      console.log("[Clients.js] handleRowClick - newly selected row", state);
      newRowStates = newRowStates.concat(rowStates, state);
    } else if (
      rowStateIndex === 0 &&
      rowStates[rowStateIndex].state === "read"
    ) {
      console.log("[Clients.js] handleRowClick - remove first item in array");
      newRowStates = newRowStates.concat(rowStates.slice(1));
    } else if (
      rowStateIndex === rowStates.length - 1 &&
      rowStates[rowStateIndex].state === "read"
    ) {
      console.log("[Clients.js] handleRowClick - remove last item in array");
      newRowStates = newRowStates.concat(rowStates.slice(0, -1));
    } else if (rowStateIndex > 0 && rowStates[rowStateIndex].state === "read") {
      console.log("[Clients.js] handleRowClick - remove middle of array");
      newRowStates = newRowStates.concat(
        rowStates.slice(0, rowStateIndex),
        rowStates.slice(rowStateIndex + 1)
      );
    }
    console.log("[Clients.js] handleRowClick - new rowStates", newRowStates);
    setRowStates(newRowStates);
  }

  function getRowStatus(id) {
    const selectedIndex = rowStates.findIndex((r) => {
      return r.id === id;
    });
    if (selectedIndex > -1) {
      return rowStates[selectedIndex];
    } else {
      return {
        id,
        state: "read",
        checked: false,
        msg: null,
      };
    }
  }

  function handleChangeRowState(id, state, msg) {
    console.log("[Clients.js] handleChangeRowState", id, state);
    const idx = rowStates.findIndex((r) => {
      return r.id === id;
    });
    const newStates = [...rowStates];
    newStates[idx].state = state;
    if (msg) {
      newStates[idx].msg = msg;
    } else {
      newStates[idx].msg = null;
    }
    setRowStates(newStates);
    console.log("[Clients.js] handleChangeRowState - New State", newStates);
  }

  function handleUpdateClient(client, name, value) {
    // console.log('[Clients.js] handleUpdateClient', client, name, value)
    const clientIndex = accounts.findIndex((a) => {
      return a.id === client.id;
    });

    const account = accounts[clientIndex];
    account[name] = value;

    const accountsArr = [...accounts];
    accountsArr[clientIndex] = account;
    setAccounts(accountsArr);
  }

  function handleChangePage(event, newPage) {
    updateState({
      ...state,
      page: newPage,
    });
  }

  function handleChangeRowsPerPage(event) {
    updateState({
      ...state,
      rowsPerPage: event.target.value,
      page: 0,
    });
  }

  function handleShowFilters() {
    setShowFilter(!showFilter);
  }

  function handleShowMultiEdit() {
    console.log("[Clients.js] Opening Multi Edit");
    setShowMultiEdit(true);
  }

  function handleCloseMultiEdit(updatedValues) {
    console.log("[Clients.js] Closing Multi Edit", updatedValues);
    console.log("[Clients.js] Selected Values", rowStates);
    for (let i = 0; i < rowStates.length; i += 1) {
      const accountIndex = accounts.findIndex((p) => {
        return p.id === rowStates[i].id;
      });
      const client = accounts[accountIndex];
      console.log("[Clients.js] Updating client", client);
      if (updatedValues.reportingName) {
        client.reportingName = updatedValues.reportingName;
      }
      if (updatedValues.sector) {
        client.sector = updatedValues.sector;
      }
      if (updatedValues.accountType) {
        client.accountType = updatedValues.accountType;
      }
      if (updatedValues.country) {
        client.country = updatedValues.country;
      }
      // handleSaveToDB(client);
    }
    setShowMultiEdit(false);
    setRowStates([]);
  }

  function archiveClients(rows) {
    console.log("[Clients.js] Archiving Clients", rows);
    const archived = rows.map((row, idx) => {
      const accountIndex = accounts.findIndex((p) => {
        return p.id === row.id;
      });
      const client = accounts[accountIndex];
      client.Archived = true;
      // handleSaveToDB(client);
      return client;
    });
    console.log("[Clients.js] Archived", archived);
    setRowStates([]);
  }

  function handleDeselectAll() {
    console.log("[Clients.js] handleDeselectAll");
    setRowStates([]);
  }

  function handleAddSector(sector) {
    console.log("[Clients.js] Adding Sector", sector);
    // const existing  = [...sectors, {Sector: sector}];
    // setSectors(existing);
  }

  useSetUrlFilters(filterable, props.changeFilters);

  function handleGlobalSearch(query) {
    console.log("[Sales.js] handleGlobalSearch", query);
    if (query && query.length > 0) {
      setGlobalSearch(query);
    } else {
      setGlobalSearch(null);
    }
    updateState({
      ...state,
      page: 0,
    });
  }


  if (!props.salesAccess) {
    console.log('[Sales.js] No access to Clients page', props.salesAccess);
    return (
      <GeneralMessage type="error" message="No Sales Access"/>
    )
  }

  return (
    <div className={classes.root}>
      <MultiEdit
        open={showMultiEdit}
        save={handleCloseMultiEdit}
        close={() => setShowMultiEdit(false)}
        addSector={handleAddSector}
      />
      <Paper className={classes.paper}>
        <TableActionsRow
          numSelected={rowStates.length}
          changeDisplayFilters={handleShowFilters}
          archiveClients={() => archiveClients(rowStates)}
          showMultiEdit={handleShowMultiEdit}
          deselectAll={handleDeselectAll}
          updateGlobalSearch={(q) => handleGlobalSearch(q)}
        />
        <div className={classes.tableWrapper}>
          {(() => {
            if (tableLoading) {
              return <LinearProgress />;
            }
          })()}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHeaderRow
              classes={classes}
              numSelected={rowStates.length}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowCount}
              filter={showFilter}
              filters={props.filters}
              changeFilters={props.changeFilters}
              filterable={filterable}
              setFilterable={setFilterable}
            />
            <TableBodyClients
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              rowStates={rowStates}
              getRowStatus={(id) => getRowStatus(id)}
              changeRowState={(id, state) => handleChangeRowState(id, state)}
              rowClick={handleRowClick}
              updateClient={(row, name, value) =>
                handleUpdateClient(row, name, value)
              }
              updateRowCount={(count) => handleUpdateRowCount(count)}
              changeLoading={(loading) => setTableLoading(loading)}
              order={state.order}
              orderBy={state.orderBy}
              filters={props.filters}
              globalSearch={globalSearch}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default withAuthenticationRequired(Clients, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});