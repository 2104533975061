import React, { useState } from "react";
import useClasses from '../../../../hooks/useClasses';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const styles = theme => ({
  card: {
    width: 275,
    position: "fixed",
    zIndex: 2,
    marginTop: -45,
  },
  title: {
    backgroundColor: "#009be5",
    padding: 10,
    paddingLeft: 17,
  },
  apply: {
    marginLeft: "auto",
  },
});

export default function TextFilter(props) {
  const classes = useClasses(styles);
  const [value, setValue] = useState("");

  return (
    <Card className={classes.card} square={true} variant="outlined">
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            style={{ color: "#FFF" }}
            onClick={props.close}
            size="large">
            <CloseIcon style={{ paddingTop: 5 }} />
          </IconButton>
        }
        title={props.label}
        titleTypographyProps={{ style: { color: "#FFF", fontSize: 15 } }}
        className={classes.title}
      />
      <CardContent>
        <TextField
          id="standard-basic"
          label="Contains"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          fullWidth
          variant="standard"
        />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          className={classes.apply}
          disabled={value.length === 0}
          onClick={() => props.apply(value)}
        >
          Apply
        </Button>
      </CardActions>
    </Card>
  );
}
