import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useClasses from '../../../../../hooks/useClasses';
import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import EditField from "../../../../Shared/EditField/EditField";
import { saleSignStatus } from "../../Shared/saleSignStatus";

const saleMutation = gql`
  mutation Sale(
    $id: ID
    $signed: Boolean
    $signedBy: String
    $signedAt: String
  ) {
    updateSales(
      input: {
        id: $id
        signed: $signed
        signedBy: $signedBy
        signedAt: $signedAt
      }
    ) {
      id
      signed
      signedBy
      signedAt
    }
  }
`;

const styles = theme => ({
  card: {
    marginTop: 10,
    cursor: "pointer",
    width: "calc(100% - 15px)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

const headerStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.75rem",
  lineHeight: "1.3125rem",
  fontWeight: 500,
};

const Signature = (props) => {
  const classes = useClasses(styles);
  const [undo, setUndo] = useState(false);
  const [readyForSignature, setReadyForSignature] = useState(false);
  const [sale, setSale] = useState({
    id: 0,
    clientName: "Acme",
  });

  const [
    saveSale
  ] = useMutation(saleMutation, {
    update: (store, { data: { updateSale }, error }) => {
      console.log("[Signature.js] graphql update", updateSale);
      setSale({
        ...sale,
        updateSale,
      });
    },
    onError: (error) => {
      console.error("[Signature.js] GraphQL Error", error);
    },
  });

  useEffect(() => {
    setSale({ ...props.saleRecord });
    const signStatus = saleSignStatus(props.saleRecord);
    setReadyForSignature(signStatus !== "notReady");
  }, [props.saleRecord]);

  // function handleUpdateSale(name, value) {
  //   console.log("[Signature.js] handleUpdateSale", name, value);
  //   const curSale = { ...sale };
  //   curSale[name] = value;
  //   setSale(curSale);
  // }

  function signSale() {
    const dt = new Date();
    const signedDt = `${dt.getUTCFullYear()}-${
      dt.getUTCMonth() + 1
    }-${dt.getUTCDate()} ${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}`;

    saveSale({
      variables: {
        id: sale.id,
        signed: true,
        signedBy: props.userProfile.name ? props.userProfile.name : "Unknown",
        signedAt: signedDt,
      },
    });
  }

  function signedButton() {
    if (undo) {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={undoSignature}
          startIcon={<Icon>undo</Icon>}
          style={{ backgroundColor: '#7B1DA2'}}
        >
          Remove Signature?
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        onClick={() => setUndo(true)}
        startIcon={<Icon>check_circle</Icon>}
        style={{ backgroundColor: 'rgb(224,224,224)', color: '#000'}}>
        Signed
      </Button>
    );
  }

  function undoSignature() {
    saveSale({
      variables: {
        id: sale.id,
        signed: false,
        signedBy: "",
        signedAt: "",
      },
    });
  }

  return (
    <Card className={classes.card} square={true}>
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        title={"Signature"}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {!sale.signed ? (
              <Button
                variant="contained"
                color="primary"
                onClick={signSale}
                endIcon={<Icon>gesture</Icon>}
                disabled={!readyForSignature}
                style={{ backgroundColor: '#1976d2'}}
              >
                Mark as Signed
              </Button>
            ) : (
              signedButton()
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Signed by
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.signedBy}
                  edit={false}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Signed at
              </Grid>
              <Grid item xs={12} sm={12}>
                {sale.signedAt !== '-2208988800000' ? 
                (<EditField
                  type={"datetime"}
                  value={sale.signedAt}
                  edit={false}
                  naked={false}
                />) :
              (null)}
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Signature.propTypes = {
  sale: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Signature.defaultProps = {
  sale: {
    id: "123",
  },
};

export default Signature;
