import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export default function OfficeAnalytics({open, close, getToken}) {

	const [viz, setViz] = useState(null);
	const [curToken, setCurToken] = useState("");	
  	const [width, setWidth] = useState(getWidth());

	const vizRef = useRef(null);

	const tableauToken = gql`
		query TableauToken {
		getTableauJWT {
			jwt
			result
		}
		}
	`;

	const { data, loading } = useQuery(tableauToken, {
		fetchPolicy: "no-cache",
	});

	function loadViz(token) {
		const showMobile = width <= 1050;
		let vizWidth = (width / 3);
		let vizHeight = 400;
		if (showMobile && width <= 600) {
		vizWidth = width - 150;
		vizHeight = 350;
		} else if (showMobile) {
		vizWidth = (width / 3);
		vizHeight = 400;
		}

		setViz(
		<tableau-viz 
			ref={vizRef}
			id="footfallViz"       
			src={"https://tableauserver.theinformationlab.co.uk/t/til2/views/25WatlingStreetFootfall/OfficeOccupancy"}
			height={vizHeight}
			width={vizWidth}
			device={showMobile ? "phone" : "desktop"}
			hide-tabs={true}
			token={token ? token : null}
			toolbar='hidden'
			>
			</tableau-viz>
		);
	}

	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
		// prevent execution of previous setTimeout
		clearTimeout(timeoutId);
		// change width from the state object after 150 milliseconds
		timeoutId = setTimeout(() => setWidth(getWidth()), 150);
		};
		// set resize listener
		window.addEventListener("resize", resizeListener);

		// clean up function
		return () => {
		// remove resize listener
		window.removeEventListener("resize", resizeListener);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (data && data.getTableauJWT && data.getTableauJWT.jwt && getToken) {
			setCurToken(data.getTableauJWT.jwt);
		} else {
			loadViz(null)
		}
	}, [data, getToken]);

	useEffect(() => {
		if (curToken && curToken.length > 0) {
			loadViz(curToken);
		}
		return () => {
			setCurToken("");
		};
		// eslint-disable-next-line
	}, [curToken]);

	return (
		<>
			<Dialog
				open={open}
				onClose={close}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={false}
				sx={{'& .MuiDialog-paper': {
					backgroundColor: '#f5f5f5'
				}}}
			>
				<DialogTitle id="alert-dialog-title">
				{"Office Footfall Analytics"}
				</DialogTitle>
				<DialogContent className='w-screen h-screen'>
					{viz}
				</DialogContent>
				<DialogActions>
				<Button onClick={close} autoFocus>
					Close
				</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}