import React from "react";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Moment from "react-moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={10000} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #9ca3af',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const statusIndicators = {
  1 : '#44b700',
  4 : 'red',
  2 : 'orange',
  3 : 'blue',
  0 : 'grey'
}

const statusLabel = {
  1 : 'Available',
  4 : 'Busy',
  2 : 'Let\'s Collaborate',
  3 : 'Game of Table Tennis?',
  0 : 'Unknown'
}

export default function Avatars({people}) {
  return (
    <HtmlTooltip
        title={
          <React.Fragment>
            <div className="grid grid-cols-1 gap-2 p-2">
              {people.map((person) => (
                <div className="flex flex-row" key={person.mac}>
                  <div>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    sx={{'& .MuiBadge-badge': {
                      backgroundColor: statusIndicators[person.status || 0],
                      color: statusIndicators[person.status || 0],
                      '&::after': {
                        animation: person.status > 0 ?'ripple 1.2s infinite ease-in-out' : ''
                      }
                    }
                    }}
                  >
                    <Avatar alt={person.name} src={person.avatar}/>
                  </StyledBadge>
                  </div>
                  <div className="grid grid-cols-1 gap-0 ml-3">
                    <div>{person.name}</div>
                    {person.status > 0 && <div className="font-normal">
                      {statusLabel[person.status]}
                    </div>}
                    <div className="font-normal">
                      Updated: <Moment format="ddd D MMM hh:mm" date={new Date(parseInt(person.updated))} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        }
      >
      <AvatarGroup max={16}>
        {people.map((person) => {
          return (
            <StyledBadge
              key={person.mac} 
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              sx={{'& .MuiBadge-badge': {
                backgroundColor: statusIndicators[person.status || 0],
                color: statusIndicators[person.status || 0],
                '&::after': {
                  animation: person.status > 0 ? 'ripple 1.2s infinite ease-in-out' : ''
                }
              }}}
            >
              <Avatar alt={person.name} src={person.avatar}/>
            </StyledBadge>
          )
        })}
      </AvatarGroup>
    </HtmlTooltip>
  );
}
