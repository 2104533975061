import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import FilterBar from "../../../Shared/FilterBar/FilterBar";

export default function TableHeaderRow(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;

  const [ headerCount, setHeaderCount ] = useState(9);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    let cellCount = 0;
    if (headCells && headCells.length > 0) {
      headCells.forEach((cell) => {
        if (cell.visible) {cellCount += 1}
      });
      setHeaderCount(cellCount);
    }
  }, [headCells])

  return (
    <TableHead>
      <TableRow style={{ visibility: props.filter ? "visible" : "collapse" }}>
        <TableCell colSpan={headerCount + 1} style={{border: 0}}>
          <FilterBar
            changeFilters={props.changeFilters}
            filters={props.filters}
            filterable={props.filterable}
            setFilterable={props.setFilterable}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells && headCells.length > 0 ? headCells.map((headCell) => {
          if (headCell.visible) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.dbField ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.dbField}
                  direction={order}
                  onClick={createSortHandler(headCell.dbField)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          }
          return ( null )
        }) : null}
      </TableRow>
    </TableHead>
  );
}

TableHeaderRow.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
