import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RenewalsTable from "./RenewalsTable/RenewalsTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const classes = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appbar: {
    border: 0,
    backgroundColor: "unset",
    marginLeft: -20,
  },
  indicator: {
    backgroundColor: "#009be5",
  },
  tabPanelRoot: {
    paddingBottom: 0,
  },
});

export default function VerticalTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.changeVendor(props.renewals[newValue].name);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="inherit"
        className={classes.appbar}
        elevation={0}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ className: classes.indicator }}
        >
          {props.renewals.map((vendor, idx) => {
            if (props.country !== "DE" && vendor.name === "Theobald") {
              return null;
            }
            return (
              <Tab
                key={vendor.name}
                label={`${vendor.name} (${vendor.count || 0})`}
                {...a11yProps(idx)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {props.renewals.map((vendor, idx) => {
        if (props.country !== "DE" && vendor.name === "Theobald") {
          return null;
        }
        return (
          <TabPanel
            key={vendor.name}
            style={{ marginBottom: -30 }}
            value={value}
            index={idx}
          >
            <RenewalsTable
              renewals={vendor.renewals}
              country={props.country}
              externalRef={props.externalRef}
              vendor={vendor.name}
              isEditing={props.isEditing}
              editRenewal={props.editRenewal}
              addingNew={props.addingNew}
              deleteRenewal={props.deleteRenewal}
              auth0Profile={props.auth0Profile}
              refreshAuth0={props.refreshAuth0}
              sector={props.sector}
            />
          </TabPanel>
        );
      })}
    </div>
  );
}
