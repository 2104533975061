import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const statusMutation = gql`
  mutation LocationStatus(
    $email: String
    $status: Int
  ) {
    updateLocatorStatus(
      email: $email,
      status: $status
    ) {
      id
      mac
      email
      country
      updated
      status
    }
  }
`;

export default function StatusSelector({userEmail, status, refresh}) {
  const [selectedStatus, setSelectedStatus] = useState(status);

  useEffect(() => {
    setSelectedStatus(status)
  }, [status])

  const [
    saveStatus,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(statusMutation, {
    update: (store, { data: { updateLocatorStatus }, error }) => {
      console.log("[StatusSelector.js] graphql update", updateLocatorStatus);
      setSelectedStatus(updateLocatorStatus[0].status);
      refresh();
    },
    onError: (error) => {
      console.error("[StatusSelector.js] GraphQL Error", error);
      // setError(error.message);
    },
  });

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    saveStatus({
      variables: {
        email: userEmail,
        status: event.target.value,
      }})
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120, marginTop: '3px' }} 
      size="small"
      >
      <InputLabel 
        id="demo-select-small"
        sx={{
          '&' : {
            color: '#FFF'
          }
        }}
      >Status</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={status}
        label="Status"
        onChange={handleChange}
        sx={{
          '& .MuiOutlinedInput-notchedOutline' : {
            borderColor: '#FFF',
            '$::hover' : {
              borderColor: '#FFF',
            }
          },
          '& .MuiSelect-select' : {
            color: '#FFF',
            paddingTop: '6px',
            paddingBottom: '6px'
          },
          '& .MuiSvgIcon-root' : {
            color: '#FFF'
          },
          '& .MuiListItemText-root' : {
            margin: 0
          }
        }}
      >
        <MenuItem value={0}>
          <ListItemText>None</ListItemText>
        </MenuItem>
        <MenuItem value={1}>
          <ListItemText>Available</ListItemText>
        </MenuItem>
        <MenuItem value={4}>
          <ListItemText>Busy</ListItemText>
        </MenuItem>
        <MenuItem value={2}>
          <ListItemText>Let's Collaborate</ListItemText>
        </MenuItem>
		    <MenuItem value={3}>
          <ListItemText>Game of Table Tennis?</ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
}