import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import HigherEdu from "./HigherEdu/HigherEdu";
import PreviousEmployment from "./PreviousEmployment/PreviousEmployment";
import Experience from "./Experience/Experience";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';

const backgroundMutation = gql`
  mutation Background(
    $firstJob: String
	$jobTitle: String
	$jobDescription: String
	$softwareExperience: [String]
	$languagesSpoken: [String]
	$passports: [String]
	$dualCitizenship: String
	$achievements: String
  ) {
    updateMyBobEmployeeProfile(
      input: {
        firstJob: $firstJob
		jobTitle: $jobTitle
		jobDescription: $jobDescription
		softwareExperience: $softwareExperience
		languagesSpoken: $languagesSpoken
		passports: $passports
		dualCitizenship: $dualCitizenship
		achievements: $achievements
      }
    ) {
		previousEmployment {
          firstJob
          jobTitle
          jobDescription
        }
		softwareExperience
		languagesSpoken
		passports
		dualCitizenship
		achievements
	}
  }
`;

export default function Background({
		profile,
		higher, 
		softwareExperienceList, 
		languages, 
		passportList,
		loading
	}) {

	const [ edit, setEdit ] = useState(false);
	const [ profileObj, setProfileObj ] = useState(profile);

	const [
		save,
		{ loading: mutationLoading },
		] = useMutation(backgroundMutation, {
		update: (store, { data, error }) => {
			console.log("[Background.js] graphql update", data);
			if (data && data.updateMyBobEmployeeProfile) {
				setProfileObj({
					...profile,
					previousEmployment: {
						firstJob: data.updateMyBobEmployeeProfile.previousEmployment.firstJob,
						jobTitle: data.updateMyBobEmployeeProfile.previousEmployment.jobTitle,
						jobDescription: data.updateMyBobEmployeeProfile.previousEmployment.jobDescription,
					},
					softwareExperience: data.updateMyBobEmployeeProfile.softwareExperience,
					languagesSpoken: data.updateMyBobEmployeeProfile.languagesSpoken,
					passports: data.updateMyBobEmployeeProfile.passports,
					dualCitizenship: data.updateMyBobEmployeeProfile.dualCitizenship,
					achievements: data.updateMyBobEmployeeProfile.achievements
			})
			}
		},
		onError: (error) => {
			console.error("[Background.js] GraphQL Error", error);
		},
		});	

	const handleSave = () => {
		console.log('[Background.js] Save', profileObj);
		save({
			variables: {
				firstJob: profileObj.previousEmployment.firstJob,
				jobTitle: profileObj.previousEmployment.firstJob === "254626567" ? '' : profileObj.previousEmployment.jobTitle,
				jobDescription: profileObj.previousEmployment.firstJob === "254626567" ? '' : profileObj.previousEmployment.jobDescription,
				softwareExperience: profileObj.softwareExperience,
				languagesSpoken: profileObj.languagesSpoken,
				passports: profileObj.passports,
				dualCitizenship: profileObj.dualCitizenship,
				achievements: profileObj.achievements
			},
			});
		setEdit(false);
	}

	const handleEdit = (name, value, section) => {
		console.log('[Background.js] Update', name, value, section);
		const updateRecord = {...profileObj};
		if (section) {
			const secRec = {...profileObj[section]};
			secRec[name] = value;
			updateRecord[section] = secRec;
		} else {
			updateRecord[name] = value;
		}
		setEdit(true);
		setProfileObj(updateRecord);
	}

	useEffect(() => {
		loading(mutationLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mutationLoading])

  return (
	<Grid container spacing={3} className="p-4">
		<Grid item xs={12}>
			<Typography variant="subtitle1" sx={{color: "#6f6f6f", marginRight: 20, height: 40,}}>
				Higher Education & Qualifications
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<HigherEdu higher={higher} loading={loading}/>
		</Grid>
		<Grid item xs={12}>
			<Typography variant="subtitle1" sx={{color: "#6f6f6f", marginRight: 20, height: 40,}}>
				Experience
			</Typography>
			{edit ? <Fab 
				size="small" 
				color="primary" 
				aria-label="add"
				className="absolute right-4 -mt-2 bg-[#1976d2]"
				onClick={() => handleSave()}
			>
				<SaveIcon />
			</Fab>
			: null}
		</Grid>
		<Grid item xs={12} md={6} sx={{paddingTop: '0px'}}>
			<PreviousEmployment previousEmployment={profileObj.previousEmployment} edit={(name, value) => handleEdit(name, value, 'previousEmployment')}/>
		</Grid>
		<Grid item xs={12} md={6} sx={{paddingTop: '0px'}}>
			<Experience 
				experience={{
					softwareExperience: profileObj.softwareExperience,
					languagesSpoken: profileObj.languagesSpoken,
					passports: profileObj.passports,
					dualCitizenship: profileObj.dualCitizenship,
					achievements: profileObj.achievements
				}}
				softwareExperienceList={softwareExperienceList}
				passportList={passportList}
				languages={languages}
				edit={(name, value) => handleEdit(name, value)}
			/>
		</Grid>
	</Grid>
  );
}
