import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useClasses from '../../../../../hooks/useClasses';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  LinearProgress,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import EditField from "../../../../Shared/EditField/EditField";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const saleMutation = gql`
  mutation Sale(
    $id: ID
    $notes: String
    $adminNotes: String
    $conversion: Boolean
    $amountTax: Float
    $vendorInvoice: String
    $paymentGroup: String
    $regionalInvoiceNumber: String
    $legacy: Boolean
    $licenseStart: String
    $licenseEnd: String
  ) {
    updateSales(
      input: {
        id: $id
        notes: $notes
        adminNotes: $adminNotes
        conversion: $conversion
        amountTax: $amountTax
        vendorInvoice: $vendorInvoice
        paymentGroup: $paymentGroup
        regionalInvoiceNumber: $regionalInvoiceNumber
        legacy: $legacy
        licenseStart: $licenseStart
        licenseEnd: $licenseEnd
      }
    ) {
      id
      notes
      adminNotes
      conversion
      amountTax
      vendorInvoice
      paymentGroup
      regionalInvoiceNumber
      legacy
      licenseStart
      licenseEnd
    }
  }
`;

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3);
  } else {
    return null;
  }
}

const styles = theme => ({
  card: {
    marginTop: 10,
    cursor: "pointer",
    width: "calc(100% - 15px)",
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

const headerStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.75rem",
  lineHeight: "1.3125rem",
  fontWeight: 500,
};

const onHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(250, 250, 250)",
};

const offHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(255, 255, 255)",
};

const Admin = (props) => {
  const classes = useClasses(styles);

  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sale, setSale] = useState({
    id: 0,
    clientName: "Acme",
  });
  const [loadedSale, setLoadedSale] = useState(props.saleRecord);
  const [error, setError] = useState();

  const [
    saveSale,
    { loading: mutationLoading },
  ] = useMutation(saleMutation, {
    update: (store, { data: { updateSale }, error }) => {
      console.log("[Admin.js] graphql update", updateSale);
      setSale({
        ...sale,
        updateSale,
      });
      setIsEditing(!isEditing);
    },
    onError: (error) => {
      console.error("[Admin.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    setSale({ ...props.saleRecord });
    setLoadedSale({ ...props.saleRecord });
    console.log("[Admin.js] Set Sale Value", props.saleRecord);
  }, [props]);

  function handleUpdateSale(name, value) {
    console.log("[Admin.js] handleUpdateSale", name, value);
    const curSale = { ...sale };
    curSale[name] = value;
    setSale(curSale);
  }

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setSale({ ...loadedSale });
  }

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={"Admin"}
      />
      <CardContent>
        <Grid container spacing={3}>
          {sale.payee === 'Tableau' ? <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={sale.conversion || false}
                  onChange={() =>
                    handleUpdateSale("conversion", !sale.conversion)
                  }
                  name="Conversion"
                  color="primary"
                />
              }
              label="Conversion"
            />
          </Grid> : null}
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={sale.legacy || false}
                  onChange={() =>
                    handleUpdateSale("legacy", !sale.legacy)
                  }
                  name="Legacy"
                  color="primary"
                />
              }
              label="Legacy"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                License Start Date
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"date"}
                  value={sale.licenseStart}
                  updateValue={(val) => handleUpdateSale("licenseStart", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                License End Date
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"date"}
                  value={sale.licenseEnd}
                  updateValue={(val) => handleUpdateSale("licenseEnd", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Notes
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.notes}
                  updateValue={(val) => handleUpdateSale("notes", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Payment Group
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.paymentGroup}
                  updateValue={(val) => handleUpdateSale("paymentGroup", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Admin Notes
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.adminNotes}
                  updateValue={(val) => handleUpdateSale("adminNotes", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                TIL Region Invoice Number
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.regionalInvoiceNumber}
                  updateValue={(val) =>
                    handleUpdateSale("regionalInvoiceNumber", val)
                  }
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Vendor Invoice
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.vendorInvoice}
                  updateValue={(val) => handleUpdateSale("vendorInvoice", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Vendor Entity
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.vendorEntity}
                  updateValue={(val) => handleUpdateSale("vendorEntity", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Tax Amount
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.amountTax}
                  updateValue={(val) => handleUpdateSale("amountTax", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: mutationLoading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              saveSale({
                variables: {
                  id: sale.id,
                  conversion: sale.conversion,
                  vendorInvoice: sale.vendorInvoice,
                  notes: sale.notes,
                  adminNotes: sale.adminNotes,
                  amountTax: parseFloat(sale.amountTax),
                  paymentGroup: sale.paymentGroup,
                  regionalInvoiceNumber: sale.regionalInvoiceNumber,
                  legacy: sale.legacy,
                  licenseStart: sale.licenseStart ? formatDate(parseInt(sale.licenseStart)) : "",
                  licenseEnd: sale.licenseEnd ? formatDate(parseInt(sale.licenseEnd)) : ""
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
};

Admin.propTypes = {
  sale: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Admin.defaultProps = {
  sale: {
    id: "123",
  },
};

export default Admin;
