import React from "react";
import PropTypes from "prop-types";
import TextField from "./TextField/TextField";
import Typography from "@mui/material/Typography";
import SelectField from "./SelectField/SelectField";
import DateField from "./DateField/DateField";
import DateTimeField from "./DateTimeField/DateTimeField";
import Link from "@mui/material/Link";
import MultiSelectField from "./MultiSelectField/MultiSelectField";

const EditField = (props) => {
  if (props.type === "text" || props.type === "number") {
    return (
      <TextField
        value={props.value}
        state={props.edit ? "edit" : null}
        updateValue={(value) => props.updateValue(value)}
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.maxWidth < 200 ? props.maxWidth : 200,
          width: props.width ? props.width : "100%",
          textAlign: props.type === "number" ? "right" : "left",
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
        type={props.type === "number" ? "number" : "string"}
        fullWidth={props.width || props.maxWidth ? false : true}
        naked={props.naked}
        currency={props.currency}
        href={props.href}
        multiline={props.maxRows && props.maxRows > 1}
        maxRows={props.maxRows}
      />
    );
  } else if (props.type === "select" && props.edit) {
    return (
      <SelectField
        value={props.value}
        updateValue={(value) => props.updateValue(value)}
        list={props.list}
        initialValue={props.initialValue || "Select"}
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.maxWidth < 200 ? props.maxWidth : 200,
          width: props.width ? props.width : "100%",
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
      />
    );
  } else if (props.type === "multiselect" && props.edit) {
    return (
      <MultiSelectField
        value={props.value}
        updateValue={(value) => props.updateValue(value)}
        list={props.list}
        initialValue={props.initialValue || "Select"}
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.maxWidth < 200 ? props.maxWidth : 200,
          width: props.width ? props.width : "100%",
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
      />
    );
  }else if (props.type === "date") {
    return (
      <DateField
        edit={props.edit}
        value={props.value}
        locale={props.locale}
        minDate={props.minDate}
        maxDate={props.maxDate}
        updateValue={(value) => props.updateValue(value)}
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.maxWidth < 200 ? props.maxWidth : 200,
          width: props.width ? props.width : "100%",
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
      />
    );
  } else if (props.type === "datetime") {
    return (
      <DateTimeField
        edit={props.edit}
        value={props.value}
        locale={props.locale}
        updateValue={(value) => props.updateValue(value)}
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.maxWidth < 200 ? props.maxWidth : 200,
          width: props.width ? props.width : "100%",
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
      />
    );
  } else if (props.href && props.href.length > 0) {
    return (
      <Link 
        href={props.href}
        target="_blank" 
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}>
        {props.value}
      </Link>
    );
  } else {
    return (
      <Typography
        noWrap={true}
        style={{
          maxWidth: props.maxWidth,
          color: props.greyOut ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
        }}
        variant="inherit"
      >
        {props.value}
      </Typography>
    );
  }
};

EditField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  type: PropTypes.oneOf(['text', 'number', 'select', 'multiselect', 'date', 'datetime']),
  edit: PropTypes.bool,
  updateValue: PropTypes.func,
  href: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

EditField.defaultProps = {};

export default EditField;
