import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import ClientRow from "./ClientRow/ClientRow";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export default function TableBodyClients(props) {
  const {
    rowsPerPage,
    page,
    rowStates,
    getRowStatus,
    changeRowState,
    rowClick,
    updateClient,
    updateRowCount,
    resetClient,
    saveToDB,
    changeLoading,
    order,
    orderBy,
  } = props;
  const [pageCached, setPageCached] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const clientsQuery = gql`
    query Client(
      $limit: Int
      $offset: Int
      $order: String
      $orderBy: String
      $sectors: [String]
      $country: [String]
      $name: String
      $reportingName: String
      $archived: Boolean
      $accountManagers: [Int]
      $tableauAccountManagers: [Int]
      $alteryxAccountManagers: [Int]
      $tags: [String]
      $cmmLevel: [Int]
      $cmmRanking: [String]
      $sfdcCustomer: Boolean
      $awsCustomer: Boolean
      $globalSearch: String
    ) {
      getClients(
        limit: $limit
        offset: $offset
        order: $order
        orderBy: $orderBy
        sectors: $sectors
        country: $country
        name: $name
        reportingName: $reportingName
        archived: $archived
        accountManagers: $accountManagers
        tableauAccountManagers: $tableauAccountManagers
        alteryxAccountManagers: $alteryxAccountManagers
        tags: $tags
        cmmLevel: $cmmLevel
        cmmRanking: $cmmRanking
        sfdcCustomer: $sfdcCustomer
        awsCustomer: $awsCustomer
        globalSearch: $globalSearch
      ) {
        id
        name
        reportingName
        sector
        nextRenewals {
          softwareVendor
          softwareProduct
          renewalYear
          contractLength
          qty
          renewalDate
        }
        accountManagers {
          id
          name
          photo
          percentOwned
        }
      }
      countClients(
        sectors: $sectors
        country: $country
        name: $name
        reportingName: $reportingName
        archived: $archived
        accountManagers: $accountManagers
        tableauAccountManagers: $tableauAccountManagers
        alteryxAccountManagers: $alteryxAccountManagers
        tags: $tags
        cmmLevel: $cmmLevel
        cmmRanking: $cmmRanking
        globalSearch: $globalSearch
      )
    }
  `;

  const { loading, data, client } = useQuery(clientsQuery, {
    variables: {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      order,
      orderBy,
      sectors: filterValues.sector,
      tags: filterValues.tag,
      country: filterValues.country,
      name: filterValues.name,
      reportingName: filterValues.reportingName,
      archived: filterValues.archived,
      accountManagers: filterValues.accountManager,
      tableauAccountManagers: filterValues.tableauAccountManager,
      alteryxAccountManagers: filterValues.alteryxAccountManager,
      cmmLevel: filterValues.cmmLevel,
      cmmRanking: filterValues.cmmRanking,
      sfdcCustomer: filterValues.sfdcCustomer,
      awsCustomer: filterValues.awsCustomer,
      globalSearch: props.globalSearch,
    },
  });

  useEffect(() => {
    // console.log("[TableBodyClients.js] Filters changed", props.filters);
    if (props.filters) {
      const newFilters = {};
      props.filters.forEach((filter, idx) => {
        console.log(
          "[TableBodyClients.js] Checking filter",
          filter,
          newFilters,
          newFilters[filter.id]
        );
        if (Array.isArray(filter.value)) {
          const valueArray = filter.value.map((val, idx) => {
            if (filter.context && filter.context.type === "int") {
              return parseInt(val.value);
            } else {
              return val.value;
            }
          });
          newFilters[filter.id] = valueArray;
        } else {
          newFilters[filter.id] = filter.value;
        }
      });
      setFilterValues(newFilters);
    } /* eslint-disable */
  }, [props.filters]);

  useEffect(() => {
    changeLoading(loading);
  }, [loading, changeLoading]);

  useEffect(() => {
    if (data && data.countClients) {
      updateRowCount(data.countClients);
    }
  }, [data, updateRowCount]);

  useEffect(() => {
    if (page >= pageCached) {
      console.log("[TableBodyClients.js] Caching next page");
      client.query({
        query: clientsQuery,
        variables: {
          limit: rowsPerPage,
          offset: rowsPerPage * (page + 1),
          order,
          orderBy,
        },
      });
      setPageCached(page + 1);
    } /* eslint-disable */
  }, [page]);

  if (data && data.getClients) {
    return (
      <TableBody>
        {data.getClients.map((row, index) => {
          return (
            <ClientRow
              key={row.id}
              selectedCount={rowStates.length}
              client={row}
              getRowStatus={() => getRowStatus(row.id)}
              changeRowState={(state) => changeRowState(row.id, state)}
              handleClick={rowClick}
              editClient={(name, value) => updateClient(row, name, value)}
              resetClient={resetClient}
              save={saveToDB}
            />
          );
        })}
      </TableBody>
    );
  }

  return <TableBody />;
}
