import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const outOutMutation = gql`
  mutation optout($email: String) {
    optOutOfficeLocations(
      email: $email
    )
  }
`;

export default function OptOut({ open, close, userEmail }) {
  
  const handleClose = () => {
    console.log('[optout.js] close');
    close();
  }

  const [
    optOut,
    { 
      loading: mutationLoading,
    },
  ] = useMutation(outOutMutation, {
    update: (store, { data: { optOutOfficeLocations }, error }) => {
      console.log("[optout.js] graphql update", optOutOfficeLocations);
      handleClose();
    },
    onError: (error) => {
      console.error("[optout.js] graphql update", error);
      handleClose();
    },
    variables : {
      email: userEmail
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Opt Out of Office Locator</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Opting out of the office locator will remove the database record of your email address
            linking you to your computer's mac address. You can then opt back in at any time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {mutationLoading ? <LinearProgress /> : null}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
              optOut()
            }}>Opt Out</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}