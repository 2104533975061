import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const macMutation = gql`
  mutation Mac(
    $mac: String
    $email: String
    $country: String
  ) {
    insertMacAddressAllocation(
      input: {
        mac: $mac
        email: $email
        country: $country
      }
    ) {
      id
      email
      mac
      country
    }
  }
`;

export default function SaveMac({userEmail}) {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [macs, setMacs] = useState([]);

  const handleClose = () => {
    console.log('[SaveMac.js] close');
    searchParams.delete('mac');
    setSearchParams(searchParams);
    setOpen(false);
  }

  useEffect(() => {
    if (searchParams && searchParams.get('mac')) {
        console.log('[SaveMac.js] mac', searchParams.get('mac'));
        const macParam = searchParams.get('mac');
        setMacs(macParam.split(','));
        setOpen(true);
    }
  }, [searchParams])

  const [
    saveMac,
    { loading: mutationLoading },
  ] = useMutation(macMutation, {
    update: (store, { data: { mac }, error }) => {
      console.log("[SaveMac.js] graphql update", mac);
      handleClose();
    },
    onError: (error) => {
      console.error("[SaveMac.js] GraphQL Error", error);
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Register MAC Address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            In order to match your user profile with your laptop we need to record
            your computer's MAC Address. To complete the process click save. You will
            be able to opt out at any time.
          </DialogContentText>
          {macs.map((mac) => (
          <TextField
            key={mac}
            margin="dense"
            id="mac"
            label="MAC Address"
            type="text"
            fullWidth
            variant="standard"
            disabled
            value={mac}
          />
          ))
        }
        </DialogContent>
        <DialogActions>
        {mutationLoading ? <LinearProgress /> : null}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
              macs.forEach((mac) => {
                saveMac({
                    variables: {
                      mac: mac,
                      email: userEmail,
                      country: 'UK'
                    },
                  });
              })
            }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}