import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Background from './Background/Background';
import { LinearProgress } from "@mui/material";
import PlacementPreferences from './PlacementPreferences/PlacementPreferences';

export default function DSInfoSelector({
    background, 
    softwareExperienceList, 
    languages, 
    passportList,
    shiftedHoursAnswers,
    otherOfficesList,
    profile, 
    refetch
  }) {
  const [expanded, setExpanded] = React.useState(false);
  const [ backgroungLoading, setBackgroundLoading ] = React.useState(false);
  const [ placementLoading, setPlacementLoading ] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    isExpanded && refetch();
  };

  return (
    <div className='mt-4'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} TransitionProps={{ unmountOnExit: true }} >
        {backgroungLoading && <LinearProgress/>}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500 }}>
            Your Background
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Tell us a bit more about you</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Background 
            higher={background && background.higher ? background.higher : null} 
            profile={profile}
            softwareExperienceList={softwareExperienceList}
            passportList={passportList}
            languages={languages}
            loading={setBackgroundLoading}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        {placementLoading && <LinearProgress/>}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500 }}>Placement Preferences</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Not promising...but we'll try
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PlacementPreferences
            shiftedHoursAnswers={shiftedHoursAnswers}
            otherOfficesList={otherOfficesList}
            preferences={{
              shiftedHours: profile ? profile.shiftedHours : '',
              otherOffices: profile ? profile.otherOffices : '',
              sixMonthInFiveYears: profile ? profile.sixMonthInFiveYears : '',
              problemCompanies: profile ? profile.problemCompanies : '',
              problemCompaniesReason: profile ? profile.problemCompaniesReason : '',
              companiesLikeToTry: profile ? profile.companiesLikeToTry : '',
              companiesApprehensive: profile ? profile.companiesApprehensive : '',
              companiesPreviousDser: profile ? profile.companiesPreviousDser : '',
              placementComment: profile ? profile.placementComment : ''
            }}
            loading={setPlacementLoading}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500 }}>
            Placement Interviews
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Let us know how interviews go
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Coming Soon
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}