const applications = [
    {
        country: 'All',
        team: 'All',
        label: "GMail",
        description: "Email...if you have to",
        icon: <>
                <circle className="fill-gray-400 group-hover:fill-[#4285F4]" cx="113.9" cy="113.9" r="113.9"/>
                <g>
                    <path className="fill-white" d="M57.6,162.7h20.7v-50.3L48.8,90.2v63.6C48.8,158.8,52.7,162.7,57.6,162.7"/>
                    <path className="fill-white group-hover:fill-[#A5A5A5]" d="M149.4,162.7h20.7c4.9,0,8.9-4,8.9-8.9V90.2l-29.6,22.2"/>
                    <path className="fill-white group-hover:fill-[#C6C6C6]" d="M149.4,73.9v38.5L179,90.2V78.4c0-11-12.5-17.2-21.3-10.7"/>
                    <path className="fill-white group-hover:fill-[#EFEFEF]" d="M78.4,112.4V73.9l35.5,26.6l35.5-26.6v38.5l-35.5,26.6"/>
                    <path className="fill-white group-hover:fill-[#C6C6C6]" d="M48.8,78.4v11.8l29.6,22.2V73.9l-8.3-6.2C61.3,61.1,48.8,67.4,48.8,78.4"/>
                </g>
                </>,
        url: "https://mail.google.com"
      },{
        country: 'All',
        team: 'All',
        label: "GDrive",
        description: "It's where you store stuff",
        icon: <>
                <circle className="fill-gray-400 group-hover:fill-[#00AC47]" cx="113.9" cy="113.9" r="113.9"/>
                <g>
                    <path className="fill-white group-hover:fill-[#EFEFEF]" d="M61.3,153.4l5.5,9.4c1.1,2,2.8,3.5,4.7,4.7L91,133.7H52c0,2.2,0.6,4.4,1.7,6.4L61.3,153.4z"/>
                    <path className="fill-white" d="M113.9,94L94.4,60.3c-1.9,1.1-3.5,2.7-4.7,4.7l-36,62.4c-1.1,1.9-1.7,4.1-1.7,6.4h39L113.9,94z"/>
                    <path className="fill-white group-hover:fill-[#C6C6C6]" d="M156.3,167.5c1.9-1.1,3.5-2.7,4.7-4.7l2.3-3.9l10.9-18.8c1.1-2,1.7-4.2,1.7-6.4h-39l8.3,16.3L156.3,167.5z"/>
                    <path className="fill-white group-hover:fill-[#A5A5A5]" d="M113.9,94l19.5-33.8c-1.9-1.1-4.1-1.7-6.4-1.7h-26.2c-2.3,0-4.5,0.6-6.4,1.7L113.9,94z"/>
                    <path className="fill-white" d="M136.8,133.7H91l-19.5,33.8c1.9,1.1,4.1,1.7,6.4,1.7h72.1c2.3,0,4.5-0.6,6.4-1.7L136.8,133.7z"/>
                    <path className="fill-white" d="M156.1,96.1l-18-31.2c-1.1-2-2.8-3.5-4.7-4.7L113.9,94l22.9,39.7h38.9c0-2.2-0.6-4.4-1.7-6.4L156.1,96.1z"/>
                </g>
                </>,
        url: "https://drive.google.com"
      },{
      country: 'uk',
      team: 'All',
      label: "HiBob",
      description: "HR Records System",
      icon: <>
            <circle cx="113.9" cy="113.9" r="113.9" className="fill-gray-400 group-hover:fill-[#F9004C]"/>
            <path fill="#FFFFFF" d="M84.6,71.6c0,6.5,0,13,0,19.6c0,0.6-0.3,1.4,0.3,1.8c0.7,0.5,1.2-0.3,1.7-0.6c31.4-20.3,72.1-0.6,77.5,35.7
              c3.9,26.4-12.2,50.8-38,57.7c-21,5.6-44.3-4.1-55.7-23.3c-4.9-8.2-7.4-17.1-7.4-26.7c0-27.6,0-55.1,0-82.7c0-6.1,2.7-10.1,7.7-11.7
              c6.3-2,13.3,2.4,13.6,9.1c0.4,7.1,0.1,14.2,0.1,21.3C84.5,71.6,84.6,71.6,84.6,71.6z M84.7,135.6c-0.1,16.2,13.1,29.7,29,29.8
              c16,0.1,29.4-13.2,29.5-29.3c0.1-16.5-12.7-29.8-29-30C98.1,105.8,84.7,119.2,84.7,135.6z"/>
            </>,
      url: "https://app.hibob.com"
    },
    {
      country: 'All',
      team: 'All',
      label: "Convo",
      description: "Real-Time Communications",
      icon: <>
            <circle className="fill-gray-400 group-hover:fill-[#07112D]" cx="113.9" cy="113.9" r="113.9"/>
            <path fill="#FFFFFF" d="M198.5,116.6c-0.4,4.6-1.3,9.1-3,13.4c-11.2,28-47.4,36.4-69.6,16c-1.4-1.3-2.7-2.7-3-4.8
                c-0.4-2.8,1-5.4,3.4-6.6c2.5-1.3,5.4-1,7.7,1.2c5,4.7,10.8,7.5,17.6,8.3c15.6,1.9,29.9-8.3,33.4-23.5c3.5-15.1-5.1-30.5-19.8-35.6
                c-12.8-4.4-25.8-0.3-33.7,10.6c-3.9,5.3-6.3,11.3-8,17.5c-2.3,8.2-5.5,16-10.4,23c-11.9,17-33.3,25-52.3,19.3
                c-16.1-4.8-26.1-15.7-30.3-31.9c-0.2-0.6,0-1.3-0.7-1.7c-0.3-4.9-0.8-9.8,0-14.6c1.2-6,3.2-11.7,6.5-16.9c11.8-18.2,35.6-25,55-15.7
                c3.4,1.6,5,4.7,4,7.9c-1.2,4-5.4,5.8-9.4,3.9c-7.6-3.5-15.4-4-23.3-1.2c-14.8,5.2-23.1,20.6-19.5,36c3.5,14.8,17.9,24.8,33.2,23.1
                c14.1-1.7,23.7-9.5,29.9-22c2.6-5.3,4.1-11,6-16.6c3-9,7.4-17.3,14.3-23.9c18.3-17.4,48.6-14.3,63.8,6.7c4.8,6.6,7.7,13.9,8,22.2
                c0,0.2,0.2,0.4,0.2,0.5C198.5,113,198.5,114.8,198.5,116.6z"/>
            </>,
      url: "https://app.convo.com"
    },
    {
      country: 'uk',
      team: 'All',
      label: "FreeAgent",
      description: "Time Tracking & Expenses",
      icon: <>
              <circle className="fill-gray-400 group-hover:fill-[#017CC5]" cx="113.9" cy="113.9" r="113.9"/>
              <g>
                <path fill="#FFFFFF" d="M44.5,55.6c1.3,1.2,2.4,2.3,3.6,3.2c3.6,2.6,7.7,3.9,12.1,4.3c6.1,0.6,12.1-0.4,18.1-1.4
                  C83,61,87.7,60.1,92.5,60c4.6-0.1,9,0.5,12.7,3.6c3.1,2.6,4.6,6,5.7,9.7c0.7,2.5,1.1,5.1,1.7,7.8c0.5-1.3,1-2.6,1.5-3.9
                  c1.8-4.7,3.5-9.5,5.5-14.1c3.8-8.7,10.6-14.1,19.9-16c7.5-1.6,14.7-0.4,21.9,1.7c1.6,0.5,3.2,1.1,4.8,1.6c0.6,0.2,0.8,0.5,0.6,1.2
                  c-2.9,8-5.9,16.1-8.7,24.1c-0.3,0.9-0.6,0.7-1.2,0.4c-3.6-2.1-7.3-2.8-11.4-1.7c-3.4,1-5.9,3.2-7.2,6.5c-5,13-9.9,26.1-14.9,39.1
                  c-6.1,16.2-12.2,32.5-18.3,48.7c-1.5,3.9-2.9,7.8-4.3,11.7c-0.2,0.7-0.5,0.9-1.3,0.9c-8,0-16,0-24.1,0c-1.1,0-1.1-0.3-0.7-1.2
                  c6.4-16.7,12.8-33.5,19.2-50.3c1.9-5.1,4.3-10.1,5.5-15.4c0.6-2.5,1-5,0.7-7.6c-0.5-4.2-2.8-6.7-7-7.4c-4.4-0.7-8.8-0.2-13.1,0.1
                  c-4.7,0.3-9.3,0.8-14-0.4c-3.7-0.9-6.2-3.1-7.3-6.8c-0.2-0.8-0.5-0.9-1.3-1c-4-0.2-8.2-4.2-9.1-8.7c0,0,0-0.1,0-0.1
                  c-0.3-1.3,0-2.7-0.2-3.9c-0.2-1.1-1.8-1.7-2.4-2.8c-1.9-3.5-2.2-7.3-2.2-11.1C43.6,61.9,44,58.9,44.5,55.6z M58.2,70.9
                  c-0.4,3.1,0.7,5.3,3.2,6.5c0.6,0.3,0.4,0.5,0.3,0.9c-0.7,3.7,1.9,6.7,5.5,6.5c0.8,0,0.9,0.4,1.1,0.9c0.7,1.7,1.9,3,3.7,3.7
                  c1.7,0.7,3.4,0.9,5.2,0.8c3.6-0.2,7.1-1,10.6-1.4c5-0.5,9.9-0.6,13.5,3.8c0,0,0.2,0,0.3,0.1c0.3-3.6,0.3-7.1-0.3-10.7
                  c-0.4-2.6-1.2-5-2.9-7c-2.8-3.3-6.7-3.7-10.7-3.6c-5.2,0.2-10.4,1.4-15.6,1.8C67.5,73.7,62.7,73.6,58.2,70.9z"/>
                <path fill="#FFFFFF" d="M144.9,81.7c1.1,2.8,2.3,5.6,3.4,8.4c5.9,14.8,11.7,29.6,17.5,44.4c6,15.1,11.9,30.2,17.9,45.2
                  c0.6,1.6,0.6,1.6-1.1,1.6c-7,0-14,0-21,0c-1,0-1.5-0.3-1.9-1.2c-2.1-5.4-4.4-10.7-6.5-16.1c-0.4-1-0.8-1.3-1.9-1.3
                  c-11.8,0-23.6,0-35.3,0c-1.1,0-1.4-0.2-0.9-1.3c2.4-6.3,4.8-12.6,7.1-18.9c0.4-1.2,0.9-1.6,2.2-1.6c6.6,0.1,13.1,0,19.9,0
                  c-0.5-1.3-0.9-2.5-1.4-3.7c-3.1-7.8-6.2-15.7-9.3-23.5c-0.2-0.6-0.4-1.1-0.1-1.7c3.7-10,7.4-20,11.1-29.9
                  C144.6,81.9,144.6,81.7,144.9,81.7z"/>
              </g>
              </>,
      url: "https://theinformationlab.freeagent.com"
    },
    {
        country: 'All',
        team: 'All',
        label: "Tableau",
        description: "UK Production Server",
        icon: <>
                <circle className="fill-gray-400 group-hover:fill-[#E66F1C]" cx="113.9" cy="113.9" r="113.9"/>
                <g>
                    <polygon fill="#FFFFFF" points="138,109.7 117.9,109.7 117.9,87.9 110,87.9 110,109.7 89.9,109.7 89.9,117.4 110,117.4 110,139.3 
                        117.9,139.3 117.9,117.4 138,117.4 	"/>
                    <polygon fill="#FFFFFF" points="97.6,145.3 79.6,145.3 79.6,125.8 72.8,125.8 72.8,145.3 54.8,145.3 54.8,151.3 72.8,151.3 
                        72.8,170.8 79.6,170.8 79.6,151.3 97.6,151.3 	"/>
                    <polygon fill="#FFFFFF" points="173.1,75.4 155.1,75.4 155.1,55.8 148.3,55.8 148.3,75.4 130.3,75.4 130.3,81.4 148.3,81.4 
                        148.3,100.8 155.1,100.8 155.1,81.4 173.1,81.4 	"/>
                    <polygon fill="#FFFFFF" points="129.2,167 117,167 117,153.4 111.2,153.4 111.2,167 99,167 99,172.3 111.2,172.3 111.2,185.9 
                        117,185.9 117,172.3 129.2,172.3 	"/>
                    <polygon fill="#FFFFFF" points="97.6,75.4 79.4,75.4 79.4,55.8 73,55.8 73,75.4 54.8,75.4 54.8,81.2 73,81.2 73,100.8 79.4,100.8 
                        79.4,81.2 97.6,81.2 	"/>
                    <polygon fill="#FFFFFF" points="186.9,110.9 174.7,110.9 174.7,97.3 168.9,97.3 168.9,110.9 156.7,110.9 156.7,116.2 168.9,116.2 
                        168.9,129.8 174.7,129.8 174.7,116.2 186.9,116.2 	"/>
                    <polygon fill="#FFFFFF" points="173.1,145.3 155.1,145.3 155.1,125.8 148.3,125.8 148.3,145.3 130.3,145.3 130.3,151.3 148.3,151.3 
                        148.3,170.8 155.1,170.8 155.1,151.3 173.1,151.3 	"/>
                    <polygon fill="#FFFFFF" points="128.3,55.2 116.2,55.2 116.2,41.9 111.9,41.9 111.9,55.2 99.8,55.2 99.8,59.3 111.9,59.3 111.9,72.6 
                        116.2,72.6 116.2,59.3 128.3,59.3 	"/>
                    <polygon fill="#FFFFFF" points="69.4,111.5 57.2,111.5 57.2,98.3 53,98.3 53,111.5 40.9,111.5 40.9,115.7 53,115.6 53,128.8 
                        57.2,128.8 57.2,115.6 69.4,115.5 	"/>
                </g>
                </>,
        url: "https://tableauserver.theinformationlab.co.uk"
      },
      {
          country: 'All',
          team: 'All',
          label: "Alteryx",
          description: "UK Alteryx Gallery",
          icon: <>
                <circle className="fill-gray-400 group-hover:fill-[#0392D0]" cx="113.9" cy="113.9" r="113.9"/>
                <path fill="#FFFFFF" d="M126.8,152.5l-0.4,0.3l-0.5,0.3c-8,5.5-20,9.3-29.8,9.3l0,0c-14.4-0.3-19.6-5.4-19.9-19.3
                    c0.3-16,5.7-20.9,23.6-22l24.8-1.2l1.1-0.1l1.1,0L126.8,152.5L126.8,152.5z M172,160.4c-0.8-2.7-3-4.3-5.8-4.3
                    c-0.7,0-1.4,0.1-2.3,0.3c-3.8,0.9-6,1.2-8.9,1.4c-5.6,0.3-6.6-1.9-7-8.2V63.1c-0.2-2-1.2-4-2.7-5.2l-0.6-0.3
                    c-0.2-0.1-0.3-0.3-0.6-0.4c-4.7-2.8-12.5-5-19.1-6.7l-0.5-0.1c-7.9-1.9-16.6-2.9-25.2-2.9c-5.1,0-9.6,0.4-11.4,0.6
                    c-3.8,0.4-14,2.1-17.4,3.2c-1,0.3-4.2,1.9-4.3,4.9c0.1,1.6,1.8,6.6,1.8,6.6c1.2,3.5,2.4,4.4,4.6,4.8c1.8,0.2,5.7-0.6,8.2-1
                    c6.6-1.2,11-1.7,14.1-1.7c4-0.1,9.1-0.1,15,0.7c11.3,1.7,15.6,5.2,15.6,5.2v33.6l-1,0l-24.1,0.6c-32.2,1-47.1,13.8-46.9,39.6
                    c-0.1,11.7,3.6,21.1,10.6,27.2c6.5,5.7,16,8.7,27.4,8.7h0.1c13.1,0,24.5-4.1,35.5-12.8l1-0.8l0.5,1.2c2.6,5.8,7.2,9.6,13.4,11.2
                    c2.8,0.8,5.9,1.2,10,1.2c1,0,2.1-0.1,3-0.2c3.7-0.5,8.6-1.2,14-3.7c3-1.4,5.1-3.2,5-6.3C174,167.4,172,160.4,172,160.4"/>
                </>,
          url: "https://spider.theinformationlab.co.uk/alteryxcloud/"
        },
        {
            country: 'uk',
            team: 'All',
            label: "Notion",
            description: "Internal Wiki",
            icon: <>
                    <circle className="fill-gray-400 group-hover:fill-[#111111]"  cx="113.9" cy="113.9" r="113.9"/>
                    <g>
                        <path fill="#FFFFFF" d="M185.1,121.5c0,16.4,0,32.7,0,49.1c0,0.5,0,1,0,1.5c-0.1,5.9-3.1,9.2-9,9.7c-6.8,0.5-13.6,0.9-20.4,1.3
                            c-13.6,0.8-27.3,1.7-40.9,2.5c-12.2,0.7-24.4,1.4-36.7,2.2c-5.2,0.3-9.3-1.4-12.2-5.8c-0.9-1.4-2.1-2.7-3.1-4
                            c-5-6.4-9.9-12.9-15-19.3c-3.6-4.5-5.3-9.4-5.3-15.3c0.2-28.7,0.1-57.4,0.1-86.1c0-1.1,0-2.2,0.2-3.2c0.8-4.2,3.4-6.8,7.7-7.2
                            c7-0.7,14.1-1.1,21.2-1.6c11.2-0.8,22.5-1.7,33.7-2.5c10.8-0.8,21.6-1.5,32.4-2.4c5.6-0.4,10.5,1.1,15.1,4.5
                            c9.5,6.9,19.2,13.5,28.8,20.2c2.5,1.7,3.5,3.8,3.5,6.9C185.1,88.3,185.1,104.9,185.1,121.5z M72.1,127.7c0,14.1,0,28.3,0,42.4
                            c0,4.5,1.9,6.3,6.4,6.3c0.5,0,1,0,1.5,0c11.6-0.7,23.1-1.3,34.7-2c18.3-1.1,36.5-2.1,54.8-3.2c3.9-0.2,5.6-1.9,5.8-5.8
                            c0-0.5,0-1.1,0-1.6c0-28,0-56.1,0-84.1c0-0.5,0-1,0-1.5c-0.2-3.3-1.7-4.7-5-4.5c-10.1,0.5-20.1,1.1-30.2,1.7
                            c-14.1,0.8-28.2,1.6-42.4,2.5c-7.1,0.4-14.1,0.8-21.2,1.3c-2.7,0.2-3.9,1.3-4.3,4c-0.1,0.7-0.1,1.4-0.1,2.1
                            C72.1,99.5,72.1,113.6,72.1,127.7z M75.7,69.3c5.6-0.3,10.1-0.6,14.6-0.9c14-0.8,27.9-1.7,41.9-2.5c10.5-0.6,21-1.3,31.5-1.9
                            c0.4,0,1,0.1,1.1-0.4c0.1-0.5-0.3-0.8-0.6-1.1c-0.3-0.3-0.6-0.5-0.9-0.7c-4.7-3.4-9.3-6.7-14-10.1c-2.4-1.7-5.1-2.9-8.1-3.1
                            c-4.4-0.3-8.8,0.4-13.2,0.7c-20.3,1.4-40.6,2.9-60.9,4.4c-3.3,0.2-6.5,0.5-9.8,0.8c-0.8,0.1-1.7,0.4-1.9,1.3c-0.3,1,0.5,1.5,1.1,2
                            c3.5,2.8,7.1,5.5,10.6,8.4C70,68.4,73.1,69.6,75.7,69.3z"/>
                        <path fill="#FFFFFF" d="M106.2,112.7c0,0.5,0,1,0,1.4c0,13,0,26.1,0,39.1c0,1.3,0.4,1.8,1.6,2c2.1,0.4,4.2,0.9,6.4,1.4
                            c0.9,0.2,1.2,0.5,0.8,1.4c-0.8,2.2-2.4,3.3-4.5,3.7c-2.4,0.5-4.8,0.3-7.2,0.5c-4.7,0.3-9.3,0.5-14,0.8c-1.5,0.1-1.7-0.6-1.4-1.7
                            c0.3-1.5,1.1-2.6,2.7-2.9c1.2-0.3,2.4-0.7,3.6-1c0.9-0.2,1.1-0.7,1.1-1.6c0-18,0-36,0-54c0-1.1-0.4-1.4-1.3-1.4
                            c-1.6-0.1-3.3-0.3-4.9-0.3c-1,0-1.2-0.5-1.2-1.3c0-2.9,1.9-5.1,4.9-5.3c6.9-0.5,13.8-0.9,20.7-1.5c1-0.1,1.2,0.5,1.6,1.1
                            c7.5,11.4,15.1,22.8,22.6,34.3c1.9,2.9,3.9,5.9,5.8,8.8c0.6-0.2,0.3-0.7,0.3-1c0-12.2,0-24.5,0-36.7c0-1-0.1-1.5-1.3-1.5
                            c-1.7-0.1-3.3-0.5-5-0.5c-1.2-0.1-1.5-0.6-1.3-1.7c0.4-2.3,2.2-4,4.5-4.2c6.4-0.4,12.8-0.8,19.2-1.2c0.7,0,0.9,0.1,1,0.8
                            c0.4,2.7-0.6,4.1-3.2,4.7c-3.9,0.8-3.9,0.8-3.9,4.8c0,19.1,0,38.3,0,57.4c0,1.3-0.4,2-1.5,2.4c-2.3,0.9-4.5,1.9-7,2.2
                            c-3.7,0.5-6.7-0.4-9.1-3.4c-3.4-4.3-6.1-9.1-9.1-13.7c-6.8-10.4-13.4-20.9-20.1-31.4C106.9,113.2,106.8,112.8,106.2,112.7z"/>
                    </g>
                    </>,
            url: "https://www.notion.so/theinformationlab"
          },
          {
              country: 'All',
              team: 'All',
              label: "SpaceIn",
              description: "Video Calls",
              icon: <>
                    <circle className="fill-gray-400 group-hover:fill-[#fedb00]"   cx="113.9" cy="113.9" r="113.9"/>
                    <path id="Path_3188" className="fill-white group-hover:fill-[#272727]" d="M113.6,42.9c-37.2,0-68,28.4-70.7,65.6h57.5L76.7,84.9c-2-2-2-5.4,0.3-7.4c2-2,5.1-2,7.4,0
                        l32.8,32.8c2,2,2,5.4,0,7.4l0,0l-32.8,32.8c-2,2-5.4,2-7.4,0c-2-2-2-5.4,0-7.4l23.7-23.7H43.3c3,38.9,36.9,68.3,76.1,65.3
                        c38.9-3,68.3-36.9,65.3-76.1C181.2,71.3,150.5,42.9,113.6,42.9L113.6,42.9z"/>
                    </>,
              url: "https://app.spacein.co.uk/"
            },
            {
                country: 'uk',
                team: 'All',
                label: "DS Rooms",
                description: "25 Watling Street Room Booking",
                icon: <>
                    <circle className="fill-gray-400 group-hover:fill-[#14142A]" cx="113.9" cy="113.9" r="113.9"/>
                    <g>
                        <path className="fill-white" d="M161.1,41.7v7H158v51c-1.6-0.1-3.3-0.2-5-0.2V51H75.1v48.4c-0.8,0-1.6,0-2.5,0c-0.9,0-1.7,0.1-2.5,0.1V48.7
                            h-3.7v-7L161.1,41.7z"/>
                        <g>
                            <path className="fill-white" d="M118.8,63.6c0-5.6-4.5-10.1-10.1-10.1S98.6,58,98.6,63.6s4.5,10.1,10.1,10.1S118.8,69.1,118.8,63.6"/>
                            <path className="fill-white" d="M117.7,86.8c0-2.9-2.3-5.2-5.2-5.2c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c0,0,0,0,0,0
                                C115.4,92,117.7,89.7,117.7,86.8"/>
                            <path className="fill-white" d="M107.6,80.3c0-2.7-2.2-4.8-4.8-4.8c-2.7,0-4.8,2.2-4.8,4.8c0,2.7,2.2,4.8,4.8,4.8
                                C105.5,85.1,107.6,83,107.6,80.3L107.6,80.3"/>
                            <path className="fill-white" d="M134.2,76.2c0-4.8-3.9-8.6-8.6-8.6s-8.6,3.9-8.6,8.6c0,4.8,3.9,8.6,8.6,8.6C130.3,84.9,134.2,81,134.2,76.2
                                C134.2,76.2,134.2,76.2,134.2,76.2"/>
                        </g>
                        <path className="fill-white" d="M183.9,112.8c-1.8-1.7-10.2-2.1-15.8-2.2h-0.7c-0.2,0-0.5,0-0.7,0c-0.9,0-1.7,0-2.3,0c-4.5,0-6.3,4.7-6.7,6.5
                            c-0.5,2.4-2.1,8.4-3.2,12.4l-0.5-0.7l-0.5-0.7c1.1-3.9,2.5-9.1,2.9-11.3c0.4-2.1,2.6-7.7,8-7.7c0.8,0,1.7,0,2.6,0
                            c0.6-3,0.1-5.3-1.4-6.8c-0.8-0.7-3.3-1.2-7.6-1.5c-1.9-0.1-4.1-0.2-6.7-0.2c-0.5,0-1.1,0-1.7,0c-3.1,0-4.6,3-5,4.6
                            c-0.1,0.2-0.1,0.4-0.1,0.5c-0.3,1.7-1.3,5.1-2.2,7.7l-0.5-0.7l-0.5-0.7c0.7-2.4,1.6-5.2,1.8-6.6c0-0.1,0-0.2,0.1-0.3
                            c0.3-1.3,1.3-3.7,3.3-5c0.9-0.6,1.9-1,3.2-1c0.7,0,1.3,0,2,0c0.4-2.4,0-4.4-1.3-5.6c-1.6-1.5-9.9-2.3-13-2.3c-3,0-4.4,3.1-4.7,4.5
                            c-0.2,1.1-0.7,2.9-1.1,4.4h-2.1c-1-0.4-2.2-0.7-3.5-1v-7c0-3.4-5.5-6.1-12.2-6.1c-6.8,0-12.2,2.7-12.2,6.1v7.1
                            c-0.1,0-0.3,0.1-0.4,0.1c-1.1,0.2-2.1,0.5-3,0.9h-1.9c-0.4-1.5-0.8-3.2-1.1-4.4c-0.2-1.4-1.6-4.5-4.7-4.5c-3.1,0-11.4,0.8-13,2.3
                            c-1.3,1.2-1.8,3.2-1.3,5.6h0.1c0.7,0,1.4,0,1.9,0c1.3,0,2.4,0.4,3.2,1c0.9,0.6,1.6,1.4,2,2.1c0.8,1.2,1.1,2.3,1.2,2.9
                            c0,0.1,0,0.2,0,0.3c0.2,1.4,1.1,4.2,1.8,6.3v0l-0.5,0.7l-0.5,0.7c-0.9-2.8-1.8-5.9-2.1-7.4c0-0.1-0.1-0.3-0.1-0.5
                            c-0.2-0.6-0.5-1.4-1-2.1c-0.7-1.1-2-2.4-4.1-2.4c-0.5,0-1.1,0-1.7,0h-0.1c-1.1,0-2.3,0-3.7,0c-0.9,0-1.7,0.1-2.5,0.1
                            c-5.9,0.3-7.5,1.1-8,1.5c-1.5,1.5-2,3.8-1.4,6.8c1,0,1.9,0,2.6,0c5.4,0,7.6,5.5,8,7.7c0,0.2,0.1,0.4,0.1,0.7
                            c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.6,0.3,1.3,0.5,2c0.1,0.3,0.1,0.5,0.2,0.8c0.2,0.6,0.3,1.1,0.5,1.7c0.1,0.3,0.2,0.6,0.3,1
                            c0.3,1,0.5,2,0.8,2.9c0.1,0.2,0.1,0.5,0.2,0.7l-0.5,0.7l-0.5,0.7c-1-3.6-2.7-9.8-3.2-12.2c-0.3-1.8-2.2-6.5-6.7-6.5
                            c-0.8,0-1.9,0-3.2,0h-0.6c-11.9,0.1-15,1.4-15.7,2.1c-2.3,2.2-2.8,6.1-1.3,10.9c1.9,6.2,7,34.9,7.2,36.2c0,0,2.1,4.6,16.1,5.4h0
                            c0.5,0.3,0.9,0.5,1.5,0.8l0,0h0.1c0.4,0.2,0.9,0.4,1.3,0.6c0.1,0,0.3,0.1,0.4,0.2h0c2,0.8,4.1,1.5,6.3,2.2c0.2,0,0.3,0.1,0.5,0.1
                            v17H152l0-16.5c0.2,0,0.3-0.1,0.5-0.1c1.7-0.4,3.4-1,5.1-1.5l0.3-0.1c1-0.4,2-0.7,2.9-1.1c0.6-0.3,1.2-0.5,1.7-0.8l0,0
                            c0.6-0.3,1.1-0.5,1.6-0.8c12.1-1.2,13.9-5.3,13.9-5.4c0.2-1.1,5.3-29.8,7.2-36C186.7,118.9,186.2,115,183.9,112.8L183.9,112.8z
                                M166,151.1L166,151.1c0,0.1,0,0.1,0,0.1c-0.1,0.5-0.5,1-1,1.5c-0.1,0.1-0.2,0.2-0.3,0.3c-6.4,5.6-29.4,10.3-50.9,10.3
                            c-24.2,0-46.6-6.8-50.9-11.7c-0.4-0.5-0.7-0.9-0.7-1.3l0-0.4c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.2l0.1-0.1c0,0,0-0.1,0.2-0.3l0.9-1.2
                            l8.9-11.6l0,0l3.1-4l9.1-11.9l0,0l3.2-4.2l7.6-9.9c0.3-0.3,0.6-0.7,1-1c0.3-0.3,0.8-0.5,1.2-0.8l0.1-0.1c1.1-0.6,2.5-1,4.1-1.4
                            c3-0.7,7-1.1,11.8-1.1c2.4,0,4.6,0.1,6.6,0.3c4.1,0.4,7.2,1.1,9.3,2.3l0.3,0.1c0.4,0.2,0.8,0.4,1.1,0.7c0.4,0.3,0.7,0.6,1,0.9
                            l7.8,10.2v0l2.1,2.8l0,0l10.2,13.3l2.3,3.1l10.4,13.5l0.2,0.2l0,0c0.2,0.2,0.4,0.4,0.5,0.6C166,150.5,166.1,150.9,166,151.1
                            L166,151.1z"/>
                    </g>
                    </>,
                url: "https://dsrooms.theinformationlab.io/"
              },
              {
                country: 'uk',
                team: 'All',
                label: "The Learning Hub",
                description: "Expand your brain",
                icon: <>
                    <circle className="fill-gray-400 group-hover:fill-[#7FA9BA]" cx="113.9" cy="113.9" r="113.9"/>
                    <path className="fill-white" d="M113.9,42.9L27.1,90.2l31.6,17.2v47.3l55.2,30.1l55.2-30.1v-47.3l15.8-8.6v54.5h15.8V90.2L113.9,42.9z
                            M167.7,90.2l-53.8,29.4L60.1,90.2l53.8-29.4L167.7,90.2z M153.4,145.4l-39.5,21.5l-39.5-21.5V116l39.5,21.5l39.5-21.5
                        C153.4,116,153.4,145.4,153.4,145.4z"/>
                    </>,
                url: "https://learninghub.theinformationlab.io/"
              }
  ]

  export { applications };