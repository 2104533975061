import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const updateMetaData = (profile) => {
  const token = localStorage.getItem('token');
  fetch('/api/auth0/usermeta', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          },
      body: JSON.stringify({
        profile
      })
      },
      )
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
}

export default function Settings({user, settings, updateSettings, updatePreview, revert}) {

  return (
    <Card>
      <CardHeader  title="Settings" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
      </CardHeader>
      <CardContent>
        <TextField 
          id="name" 
          label="Name" 
          variant="outlined" 
          value={settings.name || user.name}
          onChange={(e) => updateSettings('name', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="title" 
          label="Title" 
          variant="outlined" 
          value={settings.title || ''}
          onChange={(e) => updateSettings('title', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="mobile" 
          label="Mobile" 
          variant="outlined" 
          value={settings.mobile || ''}
          onChange={(e) => updateSettings('mobile', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="address" 
          label="Address" 
          variant="outlined" 
          value={settings.address || ''}
          onChange={(e) => updateSettings('address', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="linkedIn" 
          label="LinkedIn Profile Link" 
          variant="outlined" 
          value={settings.linkedin || ''}
          onChange={(e) => updateSettings('linkedin', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="twitter" 
          label="Twitter Profile Link" 
          variant="outlined" 
          value={settings.twitter || ''}
          onChange={(e) => updateSettings('twitter', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <TextField 
          id="tableau" 
          label="Tableau Profile Link" 
          variant="outlined" 
          value={settings.tableau || ''}
          onChange={(e) => updateSettings('tableau', e.target.value)}
          fullWidth
          className="mb-4"
        />
        <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={settings.signaturePic || false}
              onChange={(e) => updateSettings('signaturePic', e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          } label="Show Photo" />
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => {
          updateMetaData(settings);
          updatePreview();
          }}>Update</Button>
        <Button size="small" onClick={() => revert()}>Revert</Button>
      </CardActions>
    </Card>
  );
}
