import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TextField from "../../../../../Shared/EditField/TextField/TextField";

const classes = (theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    minHeight: 40,
  },
  textField: {
    width: "100%",
  },
  lastContacted: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    fontWeight: 400,
  },
});

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3);
  } else {
    return null;
  }
}

export default function EmailDomains(props) {
  const [editDomain, setEditDomain] = useState();

  const hidden = { display: "none" };
  const show = {};

  useEffect(() => {
    setEditDomain(null);
  }, [props.editMode]);

  if (props.domains && props.domains.length > 0) {
    return (
      <List className={classes.root}>
        {props.domains.map((value, idx) => {
          const labelId = `checkbox-list-label-${value}`;
          if (props.editMode && editDomain === idx) {
            return (
              <ListItem
                key={idx}
                role={undefined}
                dense
                className={classes.listItem}
              >
                <TextField
                  value={value.domain}
                  state={"edit"}
                  updateValue={(value) => props.changeDomain(idx, value)}
                  style={{ width: "100%" }}
                />
                <ListItemSecondaryAction style={props.editMode ? show : hidden}>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => props.deleteDomain(idx)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          } else {
            return (
              <ListItem
                key={idx}
                role={undefined}
                dense
                button={props.editMode}
                onClick={() => setEditDomain(idx)}
                className={classes.listItem}
              >
                <ListItemText
                  id={labelId}
                  primary={value.domain}
                  secondary={
                    <span className={classes.lastContacted}>
                      Last contacted:{" "}
                      <Moment format="ddd D MMM YYYY">
                        {formatDate(parseInt(value.lastContacted))}
                      </Moment>
                    </span>
                  }
                />
                <ListItemSecondaryAction style={props.editMode ? show : hidden}>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => props.deleteDomain(idx)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
        })}
      </List>
    );
  } else {
    return <div></div>;
  }
}
