import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

const windowsSVG = <svg className='max-h-full mx-auto' viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>;
const appleSVG = <svg className='max-h-full mx-auto' viewBox="-56.24 0 608.728 608.728" xmlns="http://www.w3.org/2000/svg"><path d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z" fill="#999"/></svg>;

export default function OptIn({open, close}) {

	const [ os, setOs ] = useState('unset');

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Opt in to the Office Locator"}
        </DialogTitle>
        <DialogContent>
			{os === 'unset' ? <>
				<DialogContentText id="alert-dialog-description">
					You can opt in to the office locator by registering your computer's mac addresses.
					Don't worry, no need to Google what a mac address is. Simply download the script which
					matches your computer's operating system.
				</DialogContentText>
				<DialogContentText id="alert-dialog-description" className="mt-4">
					Your web browser will likely warn you about the download. Don't worry, it's simply because
					the file isn't recognised as being widely distributed. Simply choose to 'Keep' the file.
				</DialogContentText>
				<Grid2 container spacing={2} className='my-4'>
					<Grid2 xs={6}>
						<div className='mx-auto h-24 content-center'>
							<a href="/static/downloads/Register WiFi Mac Address.exe" onClick={() => setOs('windows')}>{windowsSVG}</a>
						</div>
					</Grid2>
					<Grid2 xs={6}>
						<div className='mx-auto h-24 content-center'>
							<div onClick={() => setOs('apple')} className='cursor-pointer h-24'>{appleSVG}</div>
						</div>
					</Grid2>
				</Grid2>
			</> : null}
			{os === 'windows' ? <>
				<DialogContentText id="alert-dialog-description">
					Once downloaded simply run the exe file. You'll likely be presented with a 'Windows protected
					your PC' dialog. Simply select 'More info' and then press 'Run anyway'
				</DialogContentText>
				<DialogContentText id="alert-dialog-description" className="mb-4">
					A new browser tab will then open with a prompt inviting you to save your computer's mac 
					addresses to the database.
				</DialogContentText>
			</> : null}
			{os === 'apple' ? <>
				<DialogContentText id="alert-dialog-description">
					To register your mac addresses open your terminal prompt (you can do this by pressing 
					⌘ + Space and typing terminal). Then copy & paste the command below pressing the return key to execute.
				</DialogContentText>
				<DialogContentText id="alert-dialog-description" className="mt-4 bg-slate-100 text-sm">
				{`MAC=$(networksetup -listallhardwareports | sed -e '/Thunder/,+2d' | awk '/Ethernet Address:/{print $3}' | tr '\\n' ',' | rev | cut -c 2- | rev)
&& open -a Google\\ Chrome "https://portal.theinformationlab.co.uk?mac=$MAC"`}
				</DialogContentText>
			</> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}