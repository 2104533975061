import { ApolloClient, InMemoryCache, ApolloLink, ApolloProvider, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { useAuth0 } from '@auth0/auth0-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from "logrocket-react";
import React from 'react';
import { setContext } from "@apollo/client/link/context";

// IF you want to enable/disable dev tools in different enviroments
const devTools = localStorage.getItem('apolloDevTools') || false;

console.log(`[index.js] Hostname ${window.location.hostname}`);

const urlBase = {
  lambda: "https://zb9m07ymta.execute-api.eu-west-2.amazonaws.com/prod",
};

if (window.location.hostname.indexOf("-local") > -1) {
    urlBase.lambda = "https://lambda-local.theinformationlab.co.uk/local";
} else if (window.location.hostname.indexOf("-dev") > -1) {
    LogRocket.init("8p6sna/til-portal-dev");
    urlBase.lambda = "https://54dxys2v6a.execute-api.eu-west-2.amazonaws.com/dev";
} else {
    LogRocket.init("8p6sna/til-portal-prod");
}

const AuthorizedApolloProvider = ({ children }) => {
	const { getAccessTokenSilently, user } = useAuth0();

    setupLogRocketReact(LogRocket);

	console.log('[apolloProvider.js] user', user);

    if (user && window.location.hostname.indexOf("-local") === -1) {
        LogRocket.identify(user.sub, {
            name: user.given_name + " " + user.family_name,
            email: user.email
        });
    }

	const authMiddleware = setContext(async (_, { headers, ...context }) => {
		const accessToken = await getAccessTokenSilently({
            audience: `https://theinformationlab.eu.auth0.com/api/v2/`,
          });

//Optional if the ti
		if (typeof Storage !== 'undefined') {
			localStorage.setItem('token', accessToken);
		}

		return {
			headers: {
				...headers,
				...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
			},
			...context,
		};
	});

	/**
	 * Adding fix to improve logRocket recording
	 * https://docs.logrocket.com/docs/troubleshooting-sessions#apollo-client
	 */


	const client = new ApolloClient({
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors) {
					LogRocket.captureException(graphQLErrors);
					graphQLErrors.forEach(({ message, locations, path }) =>
						console.error(
							`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
						)
					);
				}
				if (networkError) {
					// localStorage.removeItem('token');
					LogRocket.captureException(networkError);
					console.error(`[Network error]:`, networkError);
				}
			}),
			authMiddleware,
			new RetryLink(),
            new HttpLink({ uri: urlBase.lambda + '/graphql', credentials: "include" })
		]),
		cache: new InMemoryCache(),
		connectToDevTools: devTools,
	});

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;