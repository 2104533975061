import React, {useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import Avatars from "./Avatar/Avatars";
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LinearProgress from '@mui/material/LinearProgress';
import SaveMac from "./SaveMac";
import OptOut from "./OptOut";
import OptIn from "./OptIn";
import StatusSelector from './StatusSelector';
import OfficeAnalytics from "./Analytics";

// eslint-disable-next-line no-sequences
const sortObject = obj => Object.keys(obj).sort().reduce((res, key) => (res[key] = obj[key], res), {})

export default function Galileo({userEmail, coreTeam}) {

  const [ locations, setLocations ] = useState({});
  const [ openOptOut , setOpenOptOut] = useState(false);
  const [ openOptIn , setOpenOptIn] = useState(false);
  const [ openAnalytics , setOpenAnalytics] = useState(false);

  const locationQuery = gql`
      query location(
      $email: String) {
        getMacAddressAllocation (today: true) {
          mac
          name
          floor
          site
          avatar
          updated
          status
      }
      getTrackingUserCount (email: $email) {
        count
      }
      myStatus: getMacAddressAllocation (email: $email) {
        status
      }
    }
  `;

  const { loading, data, refetch } = useQuery(locationQuery, {
    variables: {
      email: userEmail,
    },
    fetchPolicy: "cache-and-network",
    pollInterval: (1000 * 60 * 5), // Refresh every 5 mins
  });
  
  useEffect(() => {
    if (data && data.getMacAddressAllocation) {
      let layout = {};
      data.getMacAddressAllocation.forEach((person) => {
        if (layout[person.floor] !== undefined) {
          layout[person.floor] = [...layout[person.floor], person];
        } else {
          layout[person.floor] = [person];
        }
      })
      
      setLocations(sortObject(layout));
    }
  }, [data])

  return (
    <Card>
      <CardHeader  title="Office Locator" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}
      action={!loading && data ?
        <>
          {data.getTrackingUserCount.count === 0 ? 
            <Button variant="outlined" className='text-white border-white hover:border-white' onClick={() => setOpenOptIn(true)}>Register</Button> : 
            <>
              <StatusSelector refresh={refetch} userEmail={userEmail} status={data && data.myStatus && data.myStatus[0] && data.myStatus[0].status ? data.myStatus[0].status : 0} />
              <Button variant="outlined" className='text-white border-white hover:border-white' onClick={() => setOpenOptOut(true)}>Opt-out</Button>
            </>
          }
          <IconButton aria-label="settings" onClick={() => refetch()}>
            <RefreshIcon style={{color: 'white'}}  />
          </IconButton>
          <IconButton aria-label="settings" onClick={() => setOpenAnalytics(true)}>
            <LeaderboardIcon style={{color: 'white'}}  />
          </IconButton>
        </> : null
      }>
        
      </CardHeader>
      {loading ? <LinearProgress /> : null}
      <CardContent>
      {!loading && data && data.getMacAddressAllocation &&  data.getMacAddressAllocation.length > 0 ? (
          <>
          {Object.keys(locations).reverse().map(key => {
              return (
                <div key={key}>
                  <h2 className="text-gray-800 text-xl">Floor {key}</h2>
                  <Grid container spacing={3} className="p-4">
                    <Grid item className="grid grid-cols-1">
                      <Avatars people={locations[key]} />
                    </Grid>
                  </Grid>
                </div>
              )
          })}
          </>
      ) : !loading && data && data.getMacAddressAllocation &&  data.getMacAddressAllocation.length === 0 ? (
        <>No Users Online</>
    ) : <>Loading</>}
      </CardContent>
      <SaveMac userEmail={userEmail} />
      <OptOut userEmail={userEmail} open={openOptOut} close={() => setOpenOptOut(false)} />
      <OptIn open={openOptIn} close={() => setOpenOptIn(false)} />
      <OfficeAnalytics open={openAnalytics} getToken={!coreTeam} close={() => setOpenAnalytics(false)}/>
    </Card>
  );
  
}
