import React from "react";
import useClasses from '../../hooks/useClasses';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: -24,
    marginRight: -12,
    marginLeft: -12,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  text: {
    color: "rgba(0, 0, 0, 0.87);",
  },
  link: {
    textDecoration: "none",
    fontWeight: 500,
    color: "#009be5",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  separator: {
    marginLeft: 5,
  },
  errorIcon: {
    color: "#e54900",
  },
});

function Breadcrumb(props) {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <AppBar position="relative" style={{ backgroundColor: "#FFF" }}>
        <Toolbar className="text-black" style={{ minHeight: 50 }}>
          {props.path.map((link, idx) => {
            console.log("[Breadcrumb.js] link", link);
            if (idx === 0 && link.url) {
              return (
                <span key={link.label}>
                  {" "}
                  <Link to={link.url} className={classes.link}>
                    {link.label}
                  </Link>{" "}
                </span>
              );
            } else if (idx === 0) {
              return <span key={link.label}>{link.label}</span>;
            } else if (link.url) {
              return (
                <span key={link.label} className={classes.separator}>
                  {" "}
                  {"> "}
                  <Link to={link.url} className={classes.link}>
                    {link.label}
                  </Link>
                </span>
              );
            } else {
              return (
                <span key={link.label} className={classes.separator}>
                  {'>'} {link.label}
                </span>
              );
            }
          })}
          <Tooltip
            title={props.error ? props.error.message : ""}
            aria-label="error"
            style={{ display: props.error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{
                display: props.error ? "inherit" : "none",
                marginLeft: 15,
              }}
            />
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Breadcrumb;
