import React, {useState, useEffect} from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";
import Apps from "./Apps/Apps";
import OoO from "./OoO/OoO";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";
import ThePlan from "./ThePlan/ThePlan";
import Galileo from "./Galileo/Galileo";

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

function Content({auth0Profile}) {
  const { user } = useAuth0();

  const [ country, setCountry ] = useState('');
  const [ coreTeam, setCoreTeam ] = useState(false);

  useEffect(() => {
    console.log('[Home.js] user', user )
    if (user) {
      setCountry(user["https://sales.theinformationlab.co.uk/scope"]);
    }
  }, [user])

  useEffect(() => {
    console.log('[Home.js] auth0Profile', auth0Profile )
    if (auth0Profile && auth0Profile.groups && auth0Profile.groups.indexOf("UK Core Team") > -1) {
      setCoreTeam(true);
    }
  }, [auth0Profile])

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <OoO/>
          </Grid>
          {coreTeam ? <Grid item md={6} xs={12}>
            <ThePlan/>
          </Grid> : null}
          {country === 'uk' ? <Grid item md={6} xs={12}>
            <Galileo userEmail={user.email} coreTeam={coreTeam} />
          </Grid> : null}
          <Grid item md={6} xs={12}>
            <Apps country={country}/>
          </Grid>
        </Grid>
      </div>
    )
  }

export default withAuthenticationRequired(Content, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});
