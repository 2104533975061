import React from "react";
import Icon from "react-icon-base";

const tableauIcon = (props) => {
  return (
    <Icon viewBox="0 0 500 500" {...props}>
      <title>{props.title || "Tableau"}</title>
      <g>
        <polygon
          points="332.5,235.7 263.7,235.7 263.7,160.9 236.7,160.9 236.7,235.7 168,235.7 168,262.2 236.7,262.2 
			236.7,336.9 263.7,336.9 263.7,262.2 332.5,262.2 	"
        />
        <polygon
          points="194.3,357.7 132.7,357.7 132.7,290.7 109.4,290.7 109.4,357.7 47.8,357.7 47.8,378 109.4,378 
			109.4,445 132.7,445 132.7,378 194.3,378 	"
        />
        <polygon
          points="452.7,118.3 391.1,118.3 391.1,51.1 367.8,51.1 367.8,118.3 306.2,118.3 306.2,138.7 367.8,138.7 
			367.8,205.3 391.1,205.3 391.1,138.7 452.7,138.7 	"
        />
        <polygon
          points="302.4,431.9 260.6,431.9 260.6,385.2 240.7,385.2 240.7,431.9 198.9,431.9 198.9,449.9 240.7,449.9 
			240.7,496.6 260.6,496.6 260.6,449.9 302.4,449.9 	"
        />
        <polygon
          points="194.3,118.3 132,118.3 132,51.1 110.1,51.1 110.1,118.3 47.8,118.3 47.8,138.1 110.1,138.1 
			110.1,205.3 132,205.3 132,138.1 194.3,138.1 	"
        />
        <polygon
          points="500,239.9 458.2,239.9 458.2,193.3 438.3,193.3 438.3,239.9 396.5,239.9 396.5,257.9 438.3,257.9 
			438.3,304.6 458.2,304.6 458.2,257.9 500,257.9 	"
        />
        <polygon
          points="452.7,357.7 391.1,357.7 391.1,290.7 367.8,290.7 367.8,357.7 306.2,357.7 306.2,378 367.8,378 
			367.8,445 391.1,445 391.1,378 452.7,378 	"
        />
        <polygon
          points="299.4,49.2 258.1,49.2 258.1,3.4 243.2,3.4 243.2,49.2 201.9,49.2 201.9,63 243.2,63 243.2,108.8 
			258.1,108.8 258.1,63 299.4,63 	"
        />
        <polygon
          points="97.6,241.7 56.1,241.7 56.1,196.8 41.5,196.8 41.5,241.7 0,241.7 0,256.2 41.5,255.9 41.5,301 
			56.1,301 56.1,255.8 97.6,255.6 	"
        />
      </g>
    </Icon>
  );
};

export default tableauIcon;