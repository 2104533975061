import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import SalesRow from "./SalesRow/SalesRow";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { saleSignStatus } from "../Shared/saleSignStatus";

export default function TableBodySales(props) {
  const {
    rowsPerPage,
    page,
    rowStates,
    getRowStatus,
    changeRowState,
    rowClick,
    updateClient,
    updateRowCount,
    resetClient,
    saveToDB,
    changeLoading,
    order,
    orderBy,
    headCells,
  } = props;
  const [pageCached, setPageCached] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const salesQuery = gql`
    query Sales(
      $id: ID
      $date: String
      $vendorOrderNumber: String
      $user: [String]
      $clientName: String
      $faInvoiceNumber: String
      $faInvoiceUrl: String
      $selectPaymentGroup: [String]
      $searchPaymentGroup: String
      $sam: [String]
      $poNumber: String
      $payee: [String]
      $status: String
      $region: [String]
      $renewal: Boolean
      $regionalInvoiceNumber: String
      $online: Boolean
      $conversion: Boolean
      $vendorInvoice: String
      $limit: Int
      $offset: Int
      $order: String
      $orderBy: String
      $signed: Boolean
      $globalSearch: String
    ) {
      getSales(
        id: $id
        date: $date
        vendorOrderNumber: $vendorOrderNumber
        user: $user
        clientName: $clientName
        faInvoiceNumber: $faInvoiceNumber
        faInvoiceUrl: $faInvoiceUrl
        selectPaymentGroup: $selectPaymentGroup
        searchPaymentGroup: $searchPaymentGroup
        sam: $sam
        poNumber: $poNumber
        payee: $payee
        status: $status
        region: $region
        renewal: $renewal
        regionalInvoiceNumber: $regionalInvoiceNumber
        online: $online
        conversion: $conversion
        vendorInvoice: $vendorInvoice
        limit: $limit
        offset: $offset
        order: $order
        orderBy: $orderBy
        signed: $signed
        globalSearch: $globalSearch
      ) {
        id
        date
        vendorOrderNumber
        user
        amountDollars
        amountGbp
        amountEuro
        clientName
        payee
        signed
        region
        faInvoiceNumber
        paymentGroup
      }
      countSales(
        id: $id
        date: $date
        vendorOrderNumber: $vendorOrderNumber
        user: $user
        clientName: $clientName
        faInvoiceNumber: $faInvoiceNumber
        selectPaymentGroup: $selectPaymentGroup
        searchPaymentGroup: $searchPaymentGroup
        sam: $sam
        poNumber: $poNumber
        payee: $payee
        status: $status
        region: $region
        renewal: $renewal
        regionalInvoiceNumber: $regionalInvoiceNumber
        online: $online
        conversion: $conversion
        vendorInvoice: $vendorInvoice
        signed: $signed
        globalSearch: $globalSearch
      )
    }
  `;

  const { loading, data, client } = useQuery(salesQuery, {
    variables: {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      order,
      orderBy,
      id: filterValues.id,
      vendorOrderNumber: filterValues.vendorOrderNumber,
      clientName: filterValues.clientName,
      region: filterValues.region,
      payee: filterValues.payee,
      user: filterValues.user,
      sam: filterValues.tableauAccountManager || filterValues.alteryxAccountManager,
      signed: filterValues.signed ? null : undefined,
      selectPaymentGroup: filterValues.selectPaymentGroup,
      searchPaymentGroup: filterValues.searchPaymentGroup,
      globalSearch: props.globalSearch
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 120000,
  });

  useEffect(() => {
    console.log("[TableBodyClients.js] Filters changed", props.filters);
    if (props.filters) {
      const newFilters = {};
      props.filters.forEach((filter, idx) => {
        console.log(
          "[TableBodyClients.js] Checking filter",
          filter,
          newFilters,
          newFilters[filter.id]
        );
        if (Array.isArray(filter.value)) {
          const valueArray = filter.value.map((val, idx) => {
            if (filter.context && filter.context.type === "int") {
              return parseInt(val.value);
            } else {
              return val.value;
            }
          });
          newFilters[filter.id] = valueArray;
        } else {
          newFilters[filter.id] = filter.value;
        }
      });
      setFilterValues(newFilters);
    } /* eslint-disable */
  }, [props.filters]);

  useEffect(() => {
    changeLoading(loading);
  }, [loading, changeLoading]);

  useEffect(() => {
    if (data && data.countSales) {
      updateRowCount(data.countSales);
    }
  }, [data, updateRowCount]);

  useEffect(() => {
    if (page >= pageCached) {
      console.log("[TableBodySales.js] Caching next page");
      client.query({
        query: salesQuery,
        variables: {
          limit: rowsPerPage,
          offset: rowsPerPage * (page + 1),
          order,
          orderBy,
        },
      });
      setPageCached(page + 1);
    } /* eslint-disable */
  }, [page]);

  if (data && data.getSales) {
    return (
      <TableBody>
        {data.getSales.map((row, index) => {
          const signatureStatus = saleSignStatus(row);
          return (
            <SalesRow
              key={row.id}
              selectedCount={rowStates.length}
              sale={row}
              signatureStatus={signatureStatus}
              getRowStatus={() => getRowStatus(row.id)}
              changeRowState={(state) => changeRowState(row.id, state)}
              handleClick={rowClick}
              editClient={(name, value) => updateClient(row, name, value)}
              resetClient={resetClient}
              save={saveToDB}
              headCells={headCells}
            />
          );
        })}
      </TableBody>
    );
  }

  return <TableBody />;
}
