import React from "react";
import { styled } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Moment from "react-moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={10000} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #9ca3af',
  },
}));

export default function Avatars({people}) {
  return (
    <HtmlTooltip
        title={
          <React.Fragment>
            <div className="grid grid-cols-1 gap-2 p-2">
              {people.map((person) => (
                <div className="flex flex-row" key={person.email}>
                  <div>
                    <Avatar alt={person.name} src={person.avatar} />
                  </div>
                  <div className="grid grid-cols-1 gap-0 ml-3">
                    <div>{person.name}</div>
                    <div className="font-normal">
                      {(person.startDate === person.endDate) ? 
                        <Moment format="ddd D MMM" date={new Date(parseInt(person.startDate))} />
                       : <><Moment format="ddd D MMM" date={new Date(parseInt(person.startDate))} /> - <Moment format="ddd D MMM" date={new Date(parseInt(person.endDate))} /></>
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        }
      >
      <AvatarGroup max={6}>
        {people.map((person) => (
          <Avatar alt={person.name} src={person.avatar} key={person.email} />
        ))}
      </AvatarGroup>
    </HtmlTooltip>
  );
}
