import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import useClasses from '../../../../../hooks/useClasses';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  LinearProgress,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import EditField from "../../../../Shared/EditField/EditField";
import ClientsContext from "../../../../../context/clients-context";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { saleSignStatus } from "../../Shared/saleSignStatus";

const saleMutation = gql`
  mutation Sale(
    $id: ID
    $date: String
    $vendorOrderNumber: String
    $user: String
    $amountDollars: Float
    $amountGbp: Float
    $amountEuro: Float
    $clientName: String
    $faInvoiceNumber: String
    $paymentGroup: String
    $sam: String
    $poNumber: String
    $payee: String
    $status: String
    $region: String
    $renewal: Boolean
  ) {
    updateSales(
      input: {
        id: $id
        date: $date
        vendorOrderNumber: $vendorOrderNumber
        user: $user
        amountDollars: $amountDollars
        amountGbp: $amountGbp
        amountEuro: $amountEuro
        clientName: $clientName
        faInvoiceNumber: $faInvoiceNumber
        paymentGroup: $paymentGroup
        sam: $sam
        poNumber: $poNumber
        payee: $payee
        status: $status
        region: $region
        renewal: $renewal
      }
    ) {
      id
      date
      vendorOrderNumber
      user
      amountDollars
      amountGbp
      amountEuro
      clientName
      faInvoiceNumber
      paymentGroup
      sam
      poNumber
      payee
      status
      region
      renewal
    }
  }
`;

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3);
  } else {
    return null;
  }
}

const styles = theme => ({
  card: {
    marginTop: 10,
    cursor: "pointer",
    width: "calc(100% - 15px)",
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

const headerStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.75rem",
  lineHeight: "1.3125rem",
  fontWeight: 500,
};

const onHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(250, 250, 250)",
};

const offHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(255, 255, 255)",
};

const General = (props) => {
  const classes = useClasses(styles);

  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sale, setSale] = useState({
    id: 0,
    clientName: "Acme",
  });
  const clientsContext = useContext(ClientsContext);
  const [loadedSale, setLoadedSale] = useState(props.saleRecord);
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState();

  const [
    saveSale,
    { loading: mutationLoading },
  ] = useMutation(saleMutation, {
    update: (store, { data: { updateSale }, error }) => {
      console.log("[General.js] graphql update", updateSale);
      setSale({
        ...sale,
        updateSale,
      });
      setIsEditing(!isEditing);
    },
    onError: (error) => {
      console.error("[General.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    const saleObj = props.saleRecord;
    const freeagentReplacements = {
      UK: "theinformationlab.freeagent.com",
      NL: "theinformationlabnetherlands.freeagent.com"
    }
    if (freeagentReplacements[saleObj.region]) {
      saleObj.faInvoiceUrl = `https://${freeagentReplacements[saleObj.region]}/search?query=${saleObj.faInvoiceNumber}#search-results-invoices`
    }
    setSale({ ...saleObj });
    setLoadedSale({ ...saleObj });
    console.log("[General.js] Set Sale Value", saleObj);
  }, [props]);

  useEffect(() => {
    const r = [];
    if (clientsContext.countries) {
      clientsContext.countries.forEach((c, idx) => {
        r.push({
          value: c.country,
          label: c.country,
        });
      });
      setRegions(r);
    }
  }, [clientsContext]);

  function handleUpdateSale(name, value) {
    console.log("[General.js] handleUpdateSale", name, value);
    const curSale = { ...sale };
    curSale[name] = value;
    setSale(curSale);
  }

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setSale({ ...loadedSale });
  }

  function saleSignStatusIcon() {
    const status = saleSignStatus(sale);
    if (status === "signed") {
      // Signed
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Sale Info{" "}
          <span
            style={{
              color: "#488AC9",
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <CheckIcon /> Signed
          </span>
        </div>
      );
    } else if (status === "euReady" || status === "ukReady") {
      // Ready for signature
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Sale Info{" "}
          <span
            style={{
              color: "#E97D33",
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <LabelImportantIcon /> Ready for signature
          </span>
        </div>
      );
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>Sale Info</div>
    );
  }

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={saleSignStatusIcon()}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Client Name
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.clientName}
                  updateValue={(val) => handleUpdateSale("clientName", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Sales Entry Date
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"date"}
                  value={sale.date}
                  updateValue={(val) => handleUpdateSale("date", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Region
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"select"}
                  value={sale.region}
                  list={regions}
                  updateValue={(val) => handleUpdateSale("region", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Status
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"select"}
                  value={sale.status}
                  list={[
                    { value: "COM", label: "Commercial" },
                    { value: "ENT", label: "Enterprise" },
                    { value: "EDU", label: "Education" },
                    { value: "NFP", label: "Not for Profit" },
                  ]}
                  updateValue={(val) => handleUpdateSale("status", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                TIL SAM
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.user}
                  updateValue={(val) => handleUpdateSale("user", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Vendor SAM
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.sam}
                  updateValue={(val) => handleUpdateSale("sam", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Vendor Order Number
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.vendorOrderNumber}
                  updateValue={(val) =>
                    handleUpdateSale("vendorOrderNumber", val)
                  }
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                FreeAgent Invoice Number
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"text"}
                  value={sale.faInvoiceNumber}
                  updateValue={(val) =>
                    handleUpdateSale("faInvoiceNumber", val)
                  }
                  edit={isEditing}
                  naked={false}
                  href={sale.faInvoiceUrl}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Renewal
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={sale.renewal || false}
                      onChange={() =>
                        handleUpdateSale("renewal", !sale.renewal)
                      }
                      name="Renewal"
                      color="primary"
                    />
                  }
                  label="Renewal"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Amount $
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"number"}
                  currency={"$"}
                  value={sale.amountDollars}
                  updateValue={(val) => handleUpdateSale("amountDollars", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Amount £
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"number"}
                  currency={"£"}
                  value={sale.amountGbp}
                  updateValue={(val) => handleUpdateSale("amountGbp", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Amount €
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type={"number"}
                  currency={"€"}
                  value={sale.amountEuro}
                  updateValue={(val) => handleUpdateSale("amountEuro", val)}
                  edit={isEditing}
                  naked={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: mutationLoading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              saveSale({
                variables: {
                  id: sale.id,
                  clientName: sale.clientName,
                  date: sale.date ? formatDate(parseInt(sale.date)) : "",
                  region: sale.region,
                  status: sale.status,
                  user: sale.user,
                  sam: sale.sam,
                  vendorOrderNumber: sale.vendorOrderNumber,
                  faInvoiceNumber: sale.faInvoiceNumber,
                  renewal: sale.renewal,
                  amountDollars: sale.amountDollars
                    ? parseFloat(sale.amountDollars)
                    : null,
                  amountGbp: sale.amountGbp ? parseFloat(sale.amountGbp) : null,
                  amountEuro: sale.amountEuro
                    ? parseFloat(sale.amountEuro)
                    : null,
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
};

General.propTypes = {
  sale: PropTypes.shape({
    clientName: PropTypes.string,
  }),
};

General.defaultProps = {
  sale: {
    clientName: "ACME Inc",
  },
};

export default General;
