import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import EditField from "../../../../../Shared/EditField/EditField";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "date", numeric: false, disablePadding: true, label: "Date" },
  { id: "payee", numeric: false, disablePadding: true, label: "Payee" },
  {
    id: "faInvoiceNumber",
    numeric: false,
    disablePadding: true,
    label: "FA Invoice",
  },
  {
    id: "amountDollars",
    numeric: false,
    disablePadding: true,
    label: "Amount ($)",
  },
  {
    id: "amountEuro",
    numeric: false,
    disablePadding: true,
    label: "Amount (‎€)",
  },
  {
    id: "amountGbp",
    numeric: false,
    disablePadding: true,
    label: "Amount (£)",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              display: !editMode && headCell.editOnly ? "none" : "table-cell",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={!props.addingNew ? createSortHandler(headCell.id) : null}
              style={{ whiteSpace: "nowrap" }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const classes = (theme) => ({
  root: {
    padding: 0,
    marginLeft: -20,
    marginTop: -20,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  comment: {
    maxWidth: 340,
  },
  tableCell: {
    paddingLeft: 0,
  },
});

export default function CheckInsTable(props) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(props.sales);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (props.sales) {
      setRows([...props.sales]);
    }
  }, [props.sales]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            editMode={props.isEditing}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                let faUrl = row.faInvoiceUrl;
                if (faUrl) {
                  faUrl = faUrl.replace(
                    "api.freeagent.com/v2",
                    "theinformationlab.freeagent.com"
                  );
                }
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.id}
                        href={`https://portal.theinformationlab.co.uk/sales?q=${row.id}`}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ minWidth: 100 }}
                    >
                      <EditField type={"date"} value={row.date} />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField type={"text"} value={row.payee} />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.faInvoiceNumber}
                        href={faUrl ? faUrl : "#"}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.amountDollars}
                        currency="$"
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.amountEuro}
                        currency="€"
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.amountGbp}
                        currency="£"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
    </div>
  );
}
