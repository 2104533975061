import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useClasses from '../../hooks/useClasses';
import Profile from "./Profile/Profile";

const lightColor = "rgba(255, 255, 255, 0.7)";

const drawerWidth = 240;

const styles = theme => ({
  primaryBar: {
    paddingTop: 10,
    paddingBottom: 7,
    backgroundColor: "#14142A",
  },
  secondaryBar: {
    zIndex: 0,
    backgroundColor: "#FFF",
    height: 50,
  },
  appBar: {
    backgroundColor: "#14142A",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: '-6px',
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const classes = useClasses(styles);
  const { onDrawerToggle } = props;

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar style={{ paddingLeft: 16 }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
                size="large">
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {!props.open ? props.pageTitle : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Profile profile={props.profile || ""} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
