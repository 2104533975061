import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const GeneralMessage = ({type, message}) => (
  <Box
      sx={{
        '& > :not(style)': {
          m: 1,
          width: 200,
          height: 200,
          marginBottom: 30,
        },
      }}
      className="grid h-screen mx-auto place-items-center"
    >
      <Paper elevation={1} className="grid h-max place-items-center">
        <div className='grid grid-cols-1 text-center place-items-center'>
          {type === "loading" ? <CircularProgress className='m-2'/> : null}
          {type === "error" ? <ErrorOutlineIcon className='m-2 h-6 w-6' fontSize="large" color="error"/> : null}
          {message} 
        </div>
      </Paper>
    </Box>
);

export default GeneralMessage;
