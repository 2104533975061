import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useClasses from '../../../../../hooks/useClasses';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  LinearProgress,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import EditField from "../../../../Shared/EditField/EditField";
import TableauIcon from "../../../../../icons/tableau.js";
import AlteryxIcon from "../../../../../icons/alteryx.js";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const saleMutation = gql`
  mutation Sale($id: ID, $online: Boolean, $products: [UpdateProduct]) {
    updateSales(input: { id: $id, online: $online, products: $products }) {
      id
      online
      products {
        vendor
        name
        count
      }
    }
  }
`;

const styles = theme => ({
  card: {
    marginTop: 10,
    cursor: "pointer",
    width: "calc(100% - 15px)",
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
  button: {
    margin: 0,
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const headerStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.75rem",
  lineHeight: "1.3125rem",
  fontWeight: 500,
};

const onHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(250, 250, 250)",
};

const offHoverStyle = {
  transition: "background-color 0.2s ease",
  backgroundColor: "rgb(255, 255, 255)",
};

const Licenses = (props) => {
  const classes = useClasses(styles);

  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sale, setSale] = useState({
    id: 0,
    clientName: "Acme",
  });
  const [loadedSale, setLoadedSale] = useState(props.saleRecord);
  const [error, setError] = useState();

  const [
    saveSale,
    { loading: mutationLoading },
  ] = useMutation(saleMutation, {
    update: (store, { data: { updateSale }, error }) => {
      console.log("[General.js] graphql update", updateSale);
      setSale({
        ...sale,
        updateSale,
      });
      setIsEditing(!isEditing);
    },
    onError: (error) => {
      console.error("[General.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    setSale({ ...props.saleRecord });
    setLoadedSale({ ...props.saleRecord });
    console.log("[General.js] Set Sale Value", props.saleRecord);
  }, [props]);

  function handleUpdateSale(name, value) {
    console.log("[General.js] handleUpdateSale", name, value);
    const curSale = { ...sale };
    curSale[name] = value;
    setSale(curSale);
  }

  function handleUpdateProduct(name, idx, value) {
    console.log("[General.js] handleUpdateProduct", name, idx, value);
    const curSale = { ...sale };
    curSale.products[idx][name] = value;
    setSale(curSale);
  }

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setSale({ ...loadedSale });
  }

  function vendorHeader(vendor) {
    if (vendor === "Tableau") {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <TableauIcon style={{ marginRight: 10, height: 25, width: 25 }} />{" "}
          Tableau
        </span>
      );
    } else if (vendor === "Alteryx") {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <AlteryxIcon style={{ marginRight: 10, height: 25, width: 25 }} />{" "}
          Alteryx
        </span>
      );
    }
  }

  const productList = {
    Tableau: [
      {
        label: "Creator Subscription",
        value: "Creator Subscription",
      },
      {
        label: "Explorer Subscription",
        value: "Explorer Subscription",
      },
      {
        label: "Viewer Subscription",
        value: "Viewer Subscription",
      },
      {
        label: "Data Management Add-on",
        value: "Data Management Add-on",
      },
      {
        label: "Server Management Add-on",
        value: "Server Management Add-on",
      },
      {
        label: "eLearning",
        value: "eLearning",
      },
      {
        label: "Core Subcription",
        value: "Core Subcription",
      },
      {
        label: "Desktop Pro Subscription",
        value: "Desktop Pro Subscription",
      },
      {
        label: "Desktop Personal Subscription",
        value: "Desktop Personal Subscription",
      },
      {
        label: "Interactor Subscription",
        value: "Interactor Subscription",
      },
      {
        label: "Core",
        value: "Core",
      },
      {
        label: "Online",
        value: "Online",
      },
      {
        label: "Desktop",
        value: "Desktop",
      },
      {
        label: "Interactor",
        value: "Interactor",
      },
      {
        label: "Desktop Personal",
        value: "Desktop Personal",
      },
    ],
    Alteryx: [
      {
        label: "Designer",
        value: "Designer",
      },
      {
        label: "Desktop Scheduler",
        value: "Desktop Scheduler",
      },
      {
        label: "Server",
        value: "Server",
      },
      {
        label: "Compute Server",
        value: "Compute Server",
      },
      {
        label: "Spatial Add-on",
        value: "Spatial Add-on",
      },
      {
        label: "Adoption",
        value: "Adoption",
      },
      {
        label: "ELA",
        value: "ELA",
      },
    ],
    Unknown: [],
  };

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={
          sale.products && sale.products.length > 0
            ? vendorHeader(sale.products[0].vendor)
            : "Products"
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1} style={{ ...headerStyle, textAlign: "center" }}>
                Qty
              </Grid>
              <Grid item xs={10} style={{ ...headerStyle, marginLeft: 20 }}>
                Product
              </Grid>
            </Grid>
          </Grid>
          {sale.products
            ? sale.products.map((product, idx) => {
                const vendor =
                  sale.products && sale.products.length > 0
                    ? sale.products[0].vendor
                    : "Unknown";
                return (
                  <Grid item xs={12} key={idx.toString() + "-" + product.name}>
                    <Grid container>
                      <Grid item xs={1} style={{ textAlign: "center" }}>
                        <EditField
                          type={"number"}
                          value={product.count}
                          updateValue={(val) =>
                            handleUpdateProduct("count", idx, val)
                          }
                          edit={isEditing}
                          naked={false}
                        />
                      </Grid>
                      <Grid item xs={10} style={{ marginLeft: 20 }}>
                        <EditField
                          type={"select"}
                          value={product.name}
                          updateValue={(val) =>
                            handleUpdateProduct("name", idx, val)
                          }
                          edit={isEditing}
                          naked={false}
                          list={productList[vendor]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {isEditing && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={() =>
                      setSale({
                        ...sale,
                        products: [
                          ...sale.products,
                          { vendor: sale.products[0].vendor },
                        ],
                      })
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {sale.products &&
            sale.products.length > 0 &&
            sale.products[0].vendor === "Tableau" && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sale.online || false}
                          onChange={() =>
                            handleUpdateSale("online", !sale.online)
                          }
                          name="Renewal"
                          color="primary"
                        />
                      }
                      label="Tableau Online"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: mutationLoading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              const products = sale.products.map((product, idx) => {
                return {
                  vendor: product.vendor,
                  name: product.name,
                  count: parseInt(product.count),
                };
              });
              saveSale({
                variables: {
                  id: sale.id,
                  online: sale.online,
                  products: products,
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
};

Licenses.propTypes = {
  sale: PropTypes.shape({
    clientName: PropTypes.string,
  }),
};

Licenses.defaultProps = {
  sale: {
    clientName: "ACME Inc",
  },
};

export default Licenses;
