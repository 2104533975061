import React, { useState } from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import useClasses from '../../../hooks/useClasses';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import TextFilter from "./TextFilter/TextFilter";
import SelectFilter from "./SelectFilter/SelectFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUrlFilters } from "../UrlFiltering";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      variant="standard"
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      disabled={suggestion.inUse}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    Tag: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "fixed",
    zIndex: 1,
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default function FilterBar(props) {
  const classes = useClasses(styles);
  const [inputValue, setInputValue] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [currentFilter, setCurrentFilter] = useState();
  // const [filters, setFilters] = useState(props.filters);
  const query = new URLSearchParams(useLocation().search);
  const history = useNavigate();

  function getSuggestions(value, { showEmpty = true } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 && !showEmpty ? [] : props.filterable;
  }

  function handleClick(event) {
    console.log("[FilterBar.js] handleClick event", event);
    setShowFilterMenu(true);
  }

  function handleKeyDown(event) {
    console.log("[FilterBar.js] handleKeyDown event", event);
    const trimInputValue = inputValue.trim();
    if (props.filterable.length && !inputValue.length && event.key === "Backspace") {
      // setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    } else if (
      trimInputValue.length > 0 &&
      (event.key === "Tab" || event.key === ",")
    ) {
      event.preventDefault();
      handleChange(trimInputValue);
    }
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleApplyFilter(filter, value) {
    console.log("[FilterBar.js] handleApplyFilter", filter, value);
    const filterNew = { ...filter };
    filterNew.value = value;
    const currentFilters = [...props.filters];
    currentFilters.push(filterNew);
    // setFilters(currentFilters);
    updateUrlFilters(query, history, currentFilters);
    props.changeFilters(currentFilters);
    setCurrentFilter(null);
    const filterIdx = props.filterable.findIndex((ftr, idx) => {
      return ftr.id === filter.id;
    });
    const filterInUse = props.filterable[filterIdx];
    filterInUse.inUse = true;
    const newFilterable = [...props.filterable];
    newFilterable[filterIdx] = filterInUse;
    props.setFilterable(newFilterable);
  }

  function handleChange(item) {
    console.log("[FilterBar.js] handleChange item", item);
    const filterIdx = props.filterable.findIndex((filter, idx) => {
      return filter.label === item;
    });
    console.log("[FilterBar.js] activate filter", props.filterable[filterIdx]);
    setShowFilterMenu(false);
    setCurrentFilter(props.filterable[filterIdx]);
    if (props.filterable[filterIdx] && props.filterable[filterIdx].type === "boolean") {
      handleApplyFilter(props.filterable[filterIdx], true);
    }
  }

  const handleDelete = (item) => () => {
    console.log("[FilterBar.js] handleDelete item", item);
    const currentFilters = [...props.filters];
    const filterIdx = currentFilters.findIndex(
      (filter) => filter.id === item.id
    );
    currentFilters.splice(filterIdx, 1);
    updateUrlFilters(query, history, currentFilters);
    props.changeFilters(currentFilters);
    const newFilterable = [...props.filterable];
    const filterableIdx = props.filterable.findIndex(
      (filter) => filter.id === item.id
    );
    newFilterable[filterableIdx].inUse = false;
    props.setFilterable(newFilterable);
  };

  

  // useEffect(() => {
  //   useUpdateUrlFilters(filters);
  // }, [filters])

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={props.filters}
      variant="standard"
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          onClick: handleClick,
          placeholder: "Add a filter",
        });
        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: {
                startAdornment: props.filters.map((item) => {
                  console.log("[FilterBar.js] chip item", item);
                  let label = item.label + ": ";
                  if (Array.isArray(item.value)) {
                    item.value.forEach((filter, idx) => {
                      if (idx > 0) {
                        label = label + " OR " + filter.label;
                      } else {
                        label = label + filter.label;
                      }
                    });
                  } else {
                    label += item.value;
                  }
                  return (
                    <Chip
                      key={item.id}
                      tabIndex={-1}
                      label={label}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  );
                }),
                onBlur,
                onChange: (event) => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus,
              },
              inputProps,
            })}

            {showFilterMenu ? (
              <Paper className={classes.paper} square style={{ float: "left" }}>
                {getSuggestions(inputValue2).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion.label }),
                    highlightedIndex,
                    selectedItem: selectedItem2,
                  })
                )}
              </Paper>
            ) : null}

            {currentFilter && currentFilter.type === "text" ? (
              <TextFilter
                label={currentFilter.label}
                close={() => setCurrentFilter(null)}
                apply={(val) => handleApplyFilter(currentFilter, val)}
              />
            ) : null}

            {currentFilter && currentFilter.type === "select" ? (
              <SelectFilter
                label={currentFilter.label}
                context={currentFilter.context}
                close={() => setCurrentFilter(null)}
                apply={(val) => handleApplyFilter(currentFilter, val)}
              />
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
}
