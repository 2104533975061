import React, {useState, useEffect} from 'react';
import useClasses from '../../../../../hooks/useClasses';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';

const styles = theme => ({
  root: {
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginTop: -5,
    '&.Mui-focused' : { 
      marginTop: 0
    },
    '&.MuiInputLabel-shrink' : { 
      marginTop: 0,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 4,
      marginLeft: -5
    }
  },
  typography: {
    // padding: theme.spacing(2),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  outlinedInput: {
    padding: 0,
    paddingLeft: 4,
    '& > *' : {
      padding: 10,
    },
    width: 300,
  },
});

const ClientGlobalSearch = ({updateGlobalSearch, clearQS}) => {
  const classes = useClasses(styles);
  const [ queryValue, setQueryValue ] = useState();

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      updateGlobalSearch(queryValue);
    }, 500);
    return () => clearTimeout(timeOutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue])

  useEffect(() => {
    if (clearQS) {
      setQueryValue(null);
    }
  },[clearQS])

  return (
    <div className="mb-1">
        <FormControl sx={{marginTop: '4px'}}>
            <InputLabel 
              htmlFor="globalSearch"
              className={classes.root}
            >Quick Search</InputLabel>
            <OutlinedInput
              id="globalSearch"
              aria-describedby="globalSearch"
              sx={{ backgroundColor: '#FFF'}}
              // className={classes.outlinedInput}
              type={'text'}
              value={queryValue || ""}
              size="small"
              className="p-0 pl-1 w-[200px] md:w-[300px]"
              label="Quick Search"
              onChange={(e) => {
                setQueryValue(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={() => setQueryValue(null)}
                    size="large">
                      <ClearIcon/>
                  </IconButton>
                </InputAdornment>
              }
              
            />
        </FormControl>
    </div>
  );
};

export default ClientGlobalSearch;
