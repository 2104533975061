import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import useClasses from '../../../../hooks/useClasses';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ClientGlobalSearch from "./ClientGlobalSearch";

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#7ea9ba',
    color: '#FFF',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: "0 0 auto",
  },
});

export default function TableActionsRow(props) {
  const classes = useClasses(styles);
  const {
    numSelected,
    changeDisplayFilters,
    archiveClients,
    showMultiEdit,
    deselectAll,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
        {numSelected > 0 ? (
          <>
            <Typography
              color="inherit"
              variant="subtitle1"
              style={{ paddingTop: 7 }}
              sx={{ flex: '1 1 100%' }}
            >
              {numSelected} selected
            </Typography>
            <Tooltip title="Deselect All">
              <IconButton
                className={classes.button}
                color="primary"
                aria-label="deselectAll"
                onClick={deselectAll}
                size="large">
                <IndeterminateCheckBoxIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Typography variant="h6" id="tableTitle" sx={{ flex: '1 1 100%' }}>
            Clients
          </Typography>
        )}
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <span style={{ display: "none" }}>
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={showMultiEdit} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Archive">
              <IconButton aria-label="archive" onClick={archiveClients} size="large">
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          </span>
        ) : (
          <div style={{ display: "flex"}}>
            <ClientGlobalSearch 
              updateGlobalSearch={(q) => props.updateGlobalSearch(q)}  
            />
            <div className="hidden md:flex">
              <Tooltip title="Filter list">
                <IconButton aria-label="filter list" onClick={changeDisplayFilters} size="large" sx={{ color: '#FFF'}}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </Toolbar>
  );
}

TableActionsRow.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
