import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();

const SelectAccountManager = (props) => {

  return (
    // <Autocomplete
    //   options={props.options}
    //   getOptionLabel={(option) => option.name}
    //   filterOptions={(options, params) => {
    //     const filtered = filter(options, params);
    //     if (params.inputValue !== '') {
    //       filtered.push({
    //         name: `Add "${params.inputValue}"`,
    //         new: params.inputValue
    //       });
    //     }
    //     return filtered;
    //   }}
    //   value={props.value}
    //   onChange={(event, newValue) => {
    //     console.log(
    //       "[SelectAccountManager.js] Selected AM",
    //       newValue
    //     );
    //     if (newValue && newValue.new) {
    //       console.log("[AccountManagers.js] Add New AM");
    //       props.addNew(newValue.new)
    //     } else {
    //       props.updateClient(newValue);
    //     }
    //   }}
    //   selectOnFocus
    //   clearOnBlur
    //   handleHomeEndKeys
    //   renderOption={(option) => option.name}
    //   freeSolo
    //   renderInput={(params) => (
    //     <TextField
    //       variant="standard"
    //       {...params}
    //       label="Select Account Manager"
    //       margin="normal"
    //       fullWidth />
    //   )}
    // />
    <Autocomplete
      value={props.value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.addNew(newValue);
        } else if (newValue && newValue.new) {
          // Create a new value from the user input
          props.addNew(newValue.new);
        } else {
          props.updateClient(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          label="Select Account Manager"
          margin="normal"
          fullWidth />
      )}
    />
  );
};

SelectAccountManager.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  addNew: PropTypes.func,
  updateClient: PropTypes.func
};

export default SelectAccountManager;
