import React, {useState, useContext} from 'react';
import useClasses from '../../../../../hooks/useClasses';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Divider from '@mui/material/Divider';
import Tooltip from "@mui/material/Tooltip";
import ClientsContext from '../../../../../context/clients-context';
// import { useQuery, useMutation } from "@apollo/client";
// import { gql } from "@apollo/client";
import SaveQuickFilter from './SaveQuickFilter';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  filterBtn: {
    height: 55,
    width: 55,
    color: '#FFF'
  },
  menuItemIcon: {
    marginRight: 10
  }
});

export default function QuickFilters(props) {
  const {
    filterable,
    changeFilters,
    clearQuickSearch,
    filters
  } = props;
  const clientsContext = useContext(ClientsContext);
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickFilters, setQuickFilters] = useState([]);
  const [showSaveQF, setShowSaveQF] = useState(false);
  const [deleteEnabled, setDeleteEnabled] = useState(false);

//   const quickFiltersQuery = gql`
//     query QuickFilters  {
//         getUserProfile {
//             config {
//                 salesPortal {
//                     salesSearches {
//                         name
//                         filters {
//                             id
//                             value
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;

//   const { data } = useQuery(quickFiltersQuery, {
//     fetchPolicy: 'no-cache'
//   });

  // useEffect(() => {
  //   if (data &&
  //       data.getUserProfile &&
  //       data.getUserProfile.config &&
  //       data.getUserProfile.config.salesPortal &&
  //       data.getUserProfile.config.salesPortal.salesSearches )
  //   {
  //       setQuickFilters(data.getUserProfile.config.salesPortal.salesSearches);
  //   }
  // }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteEnabled(false);
  };

  const applyFilters = (filters) => {
    const newFilters = [];
    for(var filter of filters) {
      // eslint-disable-next-line no-loop-func
      const filterIdx = filterable.findIndex((f) => {
        return f.id === filter.id;
      });
      const filterObj = {...filterable[filterIdx]};
      if (filterObj.type === "select") {
        const values = filter.value.split(",");
        const selectedValues = clientsContext[filterObj.context.array].filter((a) => {
          return values.indexOf(a[filterObj.context.value]) > -1;
        });
        const filterValues = selectedValues.map((val) => {
          return {
            value : val[filterObj.context.value],
            label : val.name || val[filterObj.id] || val[filterObj.context.value]
          }
        })
        filterObj.value = filterValues;
      } else {
        // console.log('[setUrlFilters] query.get', query.get(key));
        let queryValue;
        if (filter.value === "true") {
          queryValue = true;
        } else if (filter.value === "false") {
          queryValue = null;
        } else {
          queryValue = filter.value;
        }
        filterObj.value = queryValue;
      }
      filterObj.inUse = true;
      newFilters.push(filterObj);
    }
    changeFilters(newFilters);
    handleClose();
  }

  const handleClearFilters = () => {
      changeFilters([]);
      clearQuickSearch();
      handleClose();
  }

  const handleShowSaveQF = () => {
      handleClose();
      setShowSaveQF(true);
  }

//   const userMutation = gql`
//   mutation User(
//     $salesSearches: [UpdateSalesSearch]
//   ) {
//     updateUser(
//       input: {
//         config : {
//           salesPortal : {
//             salesSearches: $salesSearches
//           }
//         }
//       }
//     ) {
//       config {
//             salesPortal {
//                 salesSearches {
//                     name
//                     filters {
//                         id
//                         value
//                     }
//                 }
//             }
//         }
//     }
//   }
// `;

// const [
//   saveQuickFilters
//   ] = useMutation(userMutation, {
//     update: (store, { data: { updateUser }, error }) => {
//       console.log("[QuickFilters.js] graphql update", updateUser);
//       setQuickFilters(updateUser.config.salesPortal.salesSearches);
//     },
//     onError: (error) => {
//       console.error("[QuickFilters.js] GraphQL Error", error);
//     },
//   });

  // const handleDeleteQF = (idx) => {
  //   const newQuickFilters = [...quickFilters];
  //   newQuickFilters.splice(idx, 1);
  //   setQuickFilters(newQuickFilters);
  //   saveQuickFilters({
  //       variables: {
  //         salesSearches: newQuickFilters
  //       },
  //     });
  //   console.log("[QuickFilters.js] Deleted QF. New quick filters", newQuickFilters);
  // }

  return (
    <div className="hidden md:block">
      <Tooltip title="Quick Filters">
        <IconButton
          aria-controls="quick-filters"
          aria-haspopup="true"
          onClick={handleClick}
          aria-label="upload picture"
          component="span"
          className={classes.filterBtn}
          size="large">
            <MenuOpenIcon  />
        </IconButton>
      </Tooltip>
      <Menu
        id="quick-filters"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
          {quickFilters && quickFilters.length > 0 ? quickFilters.map((quickFilter, idx) => {
              if (quickFilter && !deleteEnabled) {
                return (
                  <MenuItem key={quickFilter.name} onClick={() => applyFilters(quickFilter.filters)}>{quickFilter.name}</MenuItem>
                )
              }
              // if (quickFilter && deleteEnabled) {
              //   return (
              //     <MenuItem key={quickFilter.name} onClick={() => handleDeleteQF(idx)}><DeleteForeverIcon className={classes.menuItemIcon}/> {quickFilter.name}</MenuItem>
              //   )
              // }
              return null;
          }): null}
        {quickFilters && quickFilters.length > 0 ? <Divider light style={{marginTop: 5, marginBottom: 5}}/> : null }
        <MenuItem onClick={handleClearFilters}><ClearAllIcon className={classes.menuItemIcon}/> Clear Current Filter</MenuItem>
        {/* <MenuItem onClick={handleShowSaveQF}><PlaylistAddIcon className={classes.menuItemIcon}/> Save Current Filter</MenuItem> */}
        {quickFilters && quickFilters.length > 0 ? <MenuItem onClick={() => setDeleteEnabled(!deleteEnabled)}><DeleteOutlineIcon className={classes.menuItemIcon}/> {deleteEnabled ? "Done" : "Delete Saved Filters"}</MenuItem> : null }
      </Menu>
      <SaveQuickFilter 
        show={showSaveQF}
        quickFilters={quickFilters}
        filters={filters}
        updateQuickFilters={(qf) => setQuickFilters(qf)}
        close={() => setShowSaveQF(false)}
      />
    </div>
  );
}
