import React, { useState, useEffect } from 'react';
import Input from "@mui/material/Input";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "6px 0px 10px 0px",
    },
  };


export default function MultiSelectField({list, value, label, updateValue}) {

	const [ selected, setSelected ] = useState([]);

	useEffect(() => {
		let labelArr = [];
		if (value && value.length > 0 && list && list.length > 0) {
			const filtered = list.filter(
				function(e) {
					return this.indexOf(e.value) > -1;
				  },
				value)
			labelArr = filtered.map((e) => e.label)
		}
		setSelected(labelArr)
	}, [value, list])

	const handleChange = (event) => {
		const {
			target: { value },
		  } = event;
		const filtered = list.filter(
			function(e) {
				return this.indexOf(e.label) > -1;
			  },
			value)
		const valueArr = filtered.map((e) => e.value)
		updateValue(valueArr)
	};

	return (
		<div>
			<FormControl sx={{ width: 200 }}>
				<Select
				labelId={label + '-label'}
				id={label}
				multiple
				value={selected}
				onChange={handleChange}
				input={<Input label={label} multiline={true} sx={{fontSize: '14px'}}/>}
				renderValue={(selected) => selected.join(', ')}
				MenuProps={MenuProps}
				variant="standard"
				>
				{list.map((item) => (
					<MenuItem key={item.value} value={item.label} dense={true}>
					<Checkbox checked={value.indexOf(item.value) > -1} />
					<ListItemText primary={item.label} sx={{'& .MuiListItemText-primary' : {fontSize: '14px'}}}/>
					</MenuItem>
				))}
				</Select>
			</FormControl>
		</div>
	);
}