import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import useClasses from '../../../../hooks/useClasses';
import ClientsContext from "../../../../context/clients-context";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  dialog: {
    minWidth: 400,
  },
  formControl: {
    minWidth: 400,
  },
});

export default function MultiEdit(props) {
  const classes = useClasses(styles);

  const [reportingName, setReportingName] = useState("");
  const [sector, setSector] = useState("");
  const [accountType, setAccountType] = useState("");
  const [country, setCountry] = useState("");

  const clientsContext = useContext(ClientsContext);

  function saveChanges() {
    props.save({
      reportingName,
      sector,
      accountType,
      country,
    });
    if (
      clientsContext.sectors.findIndex((s) => {
        return s.sector === sector;
      }) < 0
    ) {
      props.addSector(sector);
    }
  }

  useEffect(() => {
    setReportingName("");
    setSector("");
    setAccountType("");
    setCountry("");
  }, [props.open]);

  if (clientsContext && clientsContext.sectors) {
    return (
      <div>
        <Dialog
          open={props.open || false}
          onClose={() => props.close()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Multi Edit</DialogTitle>
          <DialogContent className={classes.dialog}>
            <DialogContentText>
              Update all selected clients to:
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id={"reportingName"}
                  margin="normal"
                  label="Reporting Name"
                  value={reportingName}
                  onChange={(e) => setReportingName(e.target.value)}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="sector-simple"
                    style={{
                      display:
                        sector &&
                        (sector === "Other" ||
                          clientsContext.sectors.findIndex((s) => {
                            return s.sector === sector;
                          }) < 0) &&
                        sector !== ""
                          ? "none"
                          : "unset",
                    }}
                  >
                    Sector
                  </InputLabel>
                  <Select
                    className={classes.input}
                    value={sector}
                    label="Sector"
                    onChange={(e) => {
                      console.log("[MultiEdit.js] Sector", e.target.value);
                      setSector(e.target.value);
                    }}
                    style={{
                      display:
                        sector &&
                        (sector === "Other" ||
                          clientsContext.sectors.findIndex((s) => {
                            return s.sector === sector;
                          }) < 0) &&
                        sector !== ""
                          ? "none"
                          : "unset",
                    }}
                    inputProps={{
                      name: "sector",
                      id: "sector-simple",
                    }}
                  >
                    {clientsContext.sectors.map((item) => {
                      return (
                        <MenuItem key={item.sector} value={item.sector}>
                          {item.sector}
                        </MenuItem>
                      );
                    })}
                    <MenuItem key={"localOther"} value={"Other"}>
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="sectorName"
                    style={{
                      display:
                        sector &&
                        (sector === "Other" ||
                          clientsContext.sectors.findIndex((s) => {
                            return s.sector === sector;
                          }) < 0) &&
                        sector !== ""
                          ? "unset"
                          : "none",
                    }}
                    className={"Mui-focused MuiInputLabel-shrink"}
                  >
                    Sector
                  </InputLabel>
                  <TextField
                    id={"sectorName"}
                    style={{
                      display:
                        sector &&
                        (sector === "Other" ||
                          clientsContext.sectors.findIndex((s) => {
                            return s.sector === sector;
                          }) < 0) &&
                        sector !== ""
                          ? "unset"
                          : "none",
                    }}
                    className={classes.input}
                    value={sector || ""}
                    autoFocus={true}
                    onChange={(e) => {
                      setSector(e.target.value);
                    }}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="sector-simple">Account Type</InputLabel>
                  <Select
                    className={classes.input}
                    value={accountType}
                    label="Account Type"
                    onChange={(e) => {
                      setAccountType(e.target.value);
                    }}
                    inputProps={{
                      name: "accountType",
                      id: "accountType-simple",
                    }}
                  >
                    <MenuItem value="Commercial">Commercial</MenuItem>
                    <MenuItem value="Enterprise">Enterprise</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="sector-simple">Country</InputLabel>
                  <Select
                    className={classes.input}
                    value={country}
                    label="Country"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    inputProps={{
                      name: "country",
                      id: "country-simple",
                    }}
                  >
                    {clientsContext.countries.map((item) => {
                      return (
                        <MenuItem key={item.country} value={item.country}>
                          {item.country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.close()} color="primary">
              Cancel
            </Button>
            <Button onClick={saveChanges} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return null;
  }
}
