import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from 'prop-types';
import ClientsContext from "../../../../../../context/clients-context";

const insertAccountManagerMutation = gql`
  mutation insertSupplierAccountManager(
    $supplier: String
    $name: String
    $email: String
    $manager: String
    $director: String
  ) {
    insertSupplierAccountManager(
      input: {
        supplier: $supplier,
        name: $name,
        email: $email,
        manager: $manager,
        director: $director
      }
    ) {
      id
      supplier
      name
      email
      manager
      director
    }
  }
`;

const classes = (theme) => ({
  errorIcon: {
    color: "#e54900",
  },
});

const supplierDictionary = {
  tableauAM: {
    name: "Tableau",
    context: "tableauAccountManagers"
  },
  alteryxAM: {
    name: "Alteryx",
    context: "alteryxAccountManagers"
  },
  salesforceAM: {
    name: "Salesforce",
    context: "salesforceAccountManagers"
  },
}

const AddAccountManager = (props) => {
  const [error, setError] = useState(null);

  const clientsContext = useContext(ClientsContext);
  
  const [
    insertAccountManager,
    { loading: mutationLoading },
  ] = useMutation(insertAccountManagerMutation, {
    onError: (error) => {
      console.error("[AddAccountManager.js] GraphQL Error", error);
      setError(error.message);
    },
    update: (store, { data: { insertSupplierAccountManager }, error }) => {
      if (!error) {
        console.log("[AddAccountManager.js] graphql update", insertSupplierAccountManager[0]);
        props.updateClient(insertSupplierAccountManager[0]);
        clientsContext.updateContext(supplierDictionary[props.newAM.supplier].context, insertSupplierAccountManager[0]);
        props.closeDialog();
      }
    },
  });
  
  return (
    <div className="AddAccountManagerWrapper">
      <Dialog open={props.showDialog} onClose={props.closeDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add a new {supplierDictionary[props.newAM.supplier] ? supplierDictionary[props.newAM.supplier].name : ''} Account Manager</DialogTitle>
          <DialogContent style={{overflowY: 'unset'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  autoFocus
                  margin="dense"
                  id="name"
                  value={props.newAM && props.newAM.name ? props.newAM.name : ''}
                  onChange={(event) => props.updateNewAM({ ...props.newAM, name: event.target.value })}
                  label="Name"
                  type="text"
                  fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  margin="dense"
                  id="email"
                  value={props.newAM && props.newAM.email ? props.newAM.email : ''}
                  onChange={(event) => props.updateNewAM({ ...props.newAM, email: event.target.value })}
                  label="Email"
                  type="email"
                  fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  margin="dense"
                  id="manager"
                  value={props.newAM && props.newAM.manager ? props.newAM.manager : ''}
                  onChange={(event) => props.updateNewAM({ ...props.newAM, manager: event.target.value })}
                  label="Manager"
                  type="text"
                  fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  margin="dense"
                  id="director"
                  value={props.newAM && props.newAM.director ? props.newAM.director : ''}
                  onChange={(event) => props.updateNewAM({ ...props.newAM, director: event.target.value })}
                  label="Director"
                  type="director"
                  fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <LinearProgress
              style={{
                display: mutationLoading ? "inherit" : "none",
              }}
            />
          <DialogActions>
            <Button onClick={props.closeDialog} color="primary">
              Cancel
            </Button>
            <Button 
              type="submit"
              color="primary" 
              onClick={() => {
                insertAccountManager({
                  variables: {
                    supplier: supplierDictionary[props.newAM.supplier].name,
                    name: props.newAM.name,
                    email: props.newAM.email,
                    manager: props.newAM.manager,
                    director: props.newAM.director
                  },
                });
              }}
            >
              Add
            </Button>
            <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
          </DialogActions>
        </Dialog>
    </div>
  );};

AddAccountManager.propTypes = {
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  updateNewAM: PropTypes.func,
  newAM: PropTypes.object,
  saveAM: PropTypes.func
};

export default AddAccountManager;
