import React from "react";
import useClasses from '../../../../../hooks/useClasses';
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditField from "../../../../Shared/EditField/EditField";
import CheckIcon from "@mui/icons-material/Check";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    minWidth: 200,
  },
  country: {
    width: 70,
  },
  clearIcon: {
    color: "grey",
  },
  cellPadding: {
    paddingRight: 5,
  },
  tooltipCell: {
    paddingRight: 5,
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "rgb(55 150 211 / 0.08)",
    },
  },
});

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3);
  } else {
    return null;
  }
}

export default function SalesRow(props) {
  const classes = useClasses(styles);
  const rowStatus = props.getRowStatus();
  const labelId = `enhanced-table-checkbox-${props.sale.id}`;
  const ref = React.createRef();
  let valuePrefix = "£";
  if (props.sale.amountDollars) {
    valuePrefix = "$";
  } else if (props.sale.amountEuro) {
    valuePrefix = "€";
  }

  function saleSignStatusIcon() {
    if (props.signatureStatus === "signed") {
      // Signed
      return (
        <span
            style={{
              color: "#488AC9"
            }}
          >
            <CheckIcon />
          </span>
      );
    } else if (props.signatureStatus === "euReady" || props.signatureStatus === "ukReady") {
      // Ready for signature
      return (
        <span
            style={{
              color: "#E97D33"
            }}
          ><LabelImportantIcon /></span>
      );
    }
    return (
      <span></span>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => {
          if (rowStatus.state === "read") {
            props.handleClick(event, props.sale.id, ref);
          }
        }}
        role="checkbox"
        aria-checked={rowStatus.checked}
        tabIndex={-1}
        selected={rowStatus.checked}
        ref={ref}
        className={classes.tableRow}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={rowStatus.checked}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        {props.headCells.map((cell) => {
          if (cell.visible) {
            if (cell.id === "status") {
              return (
                <TableCell align="left" padding="none" className={classes.cellPadding}>
                  {saleSignStatusIcon()}
                </TableCell>
              )
            }
            if (cell.date) {
              return (
                <TableCell align="left" padding="none" className={classes.cellPadding}>
                  <EditField value={formatDate(parseInt(props.sale[cell.id]))} />
                </TableCell>
              )
            }
            if (cell.id === "amountDollars" || cell.id === "amountEuro" || cell.id === "amountGbp") {
              return (
                <TableCell align="left" padding="none" className={classes.cellPadding}>
                  <EditField
                    value={
                      props.sale.amountDollars ||
                      props.sale.amountEuro ||
                      props.sale.amountGbp
                    }
                    type={"number"}
                    currency={valuePrefix}
                  />
                </TableCell>
              )
            }
            return (
              <TableCell align="left" padding="none" className={classes.cellPadding}>
                <EditField value={props.sale[cell.id]} />
              </TableCell>
            )
          }
          return (null)
        })}
        
      </TableRow>
    </React.Fragment>
  );
}
