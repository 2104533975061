import React, { useState, useContext, useEffect } from "react";
import useClasses from '../../../../hooks/useClasses';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ClientsContext from "../../../../context/clients-context";

const styles = theme => ({
  card: {
    width: 275,
    position: "fixed",
    zIndex: 2,
    marginTop: -45,
  },
  title: {
    backgroundColor: "#009be5",
    padding: 10,
    paddingLeft: 17,
  },
  apply: {
    marginLeft: "auto",
  },
  root: {
    display: "flex",
  },
  formControl: {},
  cardContent: {
    maxHeight: 300,
    flexWrap: "nowrap",
    overflowY: "auto",
  },
});

export default function TextFilter(props) {
  const classes = useClasses(styles);
  const clientsContext = useContext(ClientsContext);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (
      props.context &&
      props.context.array &&
      clientsContext[props.context.array]
    ) {
      console.log(
        "[SelectFilter.js] setOptions",
        clientsContext[props.context.array]
      );
      const optionsArr = clientsContext[props.context.array].map((option) => {
        return {
          value: option[props.context.value],
          label: option[props.context.label],
          type: props.context.type,
        };
      });
      setOptions(optionsArr);
    }
  }, [props.context, clientsContext]);

  const handleChange = (option) => (event) => {
    if (event.target.checked) {
      const newSelected = [...selected, event.target.value];
      const newValue = [...value, option];
      setSelected(newSelected);
      setValue(newValue);
    }
    if (!event.target.checked) {
      const newSelected = [...selected];
      const selectedIdx = newSelected.indexOf(event.target.value);
      newSelected.splice(selectedIdx, 1);
      setSelected(newSelected);
      const newValue = [...value];
      setValue(newValue.splice(selectedIdx, 1));
    }
  };

  return (
    <Card className={classes.card} square={true} variant="outlined">
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            style={{ color: "#FFF" }}
            onClick={props.close}
            size="large">
            <CloseIcon style={{ paddingTop: 5 }} />
          </IconButton>
        }
        title={props.label}
        titleTypographyProps={{ style: { color: "#FFF", fontSize: 15 } }}
        className={classes.title}
      />
      <CardContent className={classes.cardContent}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={selected.indexOf(parseInt(option.value)) > -1 || selected.indexOf(option.value + "") > -1}
                    onChange={handleChange(option)}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          className={classes.apply}
          disabled={selected.length === 0}
          onClick={() => props.apply(value)}
        >
          Apply
        </Button>
      </CardActions>
    </Card>
  );
}
