import React, { useEffect, useState } from "react";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import EditField from "../../../../Shared/EditField/EditField";

const experienceMutation = gql`
  mutation Experience(
    $companiesApprehensive: String
	$companiesLikeToTry: String
	$companiesPreviousDser: String
	$otherOffices: [String]
	$placementComment: String
	$problemCompanies: String
	$problemCompaniesReason: String
	$shiftedHours: String
	$sixMonthInFiveYears: String
  ) {
    updateMyBobEmployeeProfile(
      input: {
        companiesApprehensive: $companiesApprehensive
		companiesLikeToTry: $companiesLikeToTry
		companiesPreviousDser: $companiesPreviousDser
		otherOffices: $otherOffices
		placementComment: $placementComment
		problemCompanies: $problemCompanies
		problemCompaniesReason: $problemCompaniesReason
		shiftedHours: $shiftedHours
		sixMonthInFiveYears: $sixMonthInFiveYears
      }
    ) {
		companiesApprehensive
		companiesLikeToTry
		companiesPreviousDser
		otherOffices
		placementComment
		problemCompanies
		problemCompaniesReason
		shiftedHours
		sixMonthInFiveYears
	}
  }
`;

export default function PlacementPreferences({
		shiftedHoursAnswers,
		otherOfficesList,
		preferences,
		loading
	}) {

	const [ edit, setEdit ] = useState(false);
	const [ placementPref, setPlacementPref ] = useState(preferences);
	const [ shiftedHours, setShiftedHours ] = useState([]);
	const [ otherOffices, setOtherOffices ] = useState([]);

	const [
		saveExperience,
		{ loading: mutationLoading },
	  ] = useMutation(experienceMutation, {
		update: (store, { data, error }) => {
		  console.log("[PlacementPreferences.js] graphql update", data.updateMyBobEmployeeProfile);
		  if (data && data.updateMyBobEmployeeProfile) {
			setPlacementPref({
				...placementPref,
				companiesApprehensive: data.updateMyBobEmployeeProfile.companiesApprehensive,
				companiesLikeToTry: data.updateMyBobEmployeeProfile.companiesLikeToTry,
				companiesPreviousDser: data.updateMyBobEmployeeProfile.companiesPreviousDser,
				otherOffices: data.updateMyBobEmployeeProfile.otherOffices,
				placementComment: data.updateMyBobEmployeeProfile.placementComment,
				problemCompanies: data.updateMyBobEmployeeProfile.problemCompanies,
				problemCompaniesReason: data.updateMyBobEmployeeProfile.problemCompaniesReason,
				shiftedHours: data.updateMyBobEmployeeProfile.shiftedHours,
				sixMonthInFiveYears: data.updateMyBobEmployeeProfile.sixMonthInFiveYears,
			})
		  }
		},
		onError: (error) => {
		  console.error("[PlacementPreferences.js] GraphQL Error", error);
		},
	  });	

	useEffect(() => {
		loading(mutationLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mutationLoading])

	useEffect(() => {
		if (shiftedHoursAnswers && shiftedHoursAnswers.length > 0) {
			const newList = shiftedHoursAnswers.map((i) => {
				return {
					label: i.value,
					value: i.id}
			})
			setShiftedHours(newList);
		}
	}, [shiftedHoursAnswers])

	useEffect(() => {
		if (otherOfficesList && otherOfficesList.length > 0) {
			const newList = otherOfficesList.map((i) => {
				return {
					label: i.value,
					value: i.id}
			})
			setOtherOffices(newList);
		}
	}, [otherOfficesList])

	const handleEdit = (name, value) => {
		console.log('[PlacementPreferences.js] Update', name, value);
		const updateRecord = {...placementPref};
		updateRecord[name] = value;
		setEdit(true);
		setPlacementPref(updateRecord);
	}

	const handleSave = () => {
		console.log('[PlacementPreferences.js] Save', placementPref);
		saveExperience({
			variables: {
				companiesApprehensive: placementPref.companiesApprehensive,
				companiesLikeToTry: placementPref.companiesLikeToTry,
				companiesPreviousDser: placementPref.companiesPreviousDser,
				otherOffices: placementPref.otherOffices,
				placementComment: placementPref.placementComment,
				problemCompanies: placementPref.problemCompanies,
				problemCompaniesReason: placementPref.problemCompaniesReason,
				shiftedHours: placementPref.shiftedHours,
				sixMonthInFiveYears: placementPref.sixMonthInFiveYears,
			},
			});
		setEdit(false);
	}

  return (
  	<>
		{edit ? <Fab 
			size="small" 
			color="primary" 
			aria-label="add"
			className="absolute right-4 -mt-2 bg-[#1976d2]"
			onClick={() => handleSave()}
		>
			<SaveIcon />
		</Fab>
		: null}
		<Grid container className="p-4">
			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{color: "#6f6f6f", marginRight: 20, height: 40,}}>
					Location
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Would you be willing to work shifted hours for clients in other countries?</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="select"
							value={(placementPref && placementPref.shiftedHours) || '0'}
							list={shiftedHours}
							edit={true}
							updateValue={(val) => handleEdit('shiftedHours', val)}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">If the opportunity arose, would you be interested in placements for TIL offices other than your own?</div>
						<div className="text-sm">Note: these would most likely be fully remote</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="multiselect"
							value={(placementPref && placementPref.otherOffices) || ''}
							list={otherOffices}
							edit={true}
							updateValue={(val) => handleEdit('otherOffices', val)}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Have you lived outside of your 'home' country for a period of more than 6 months within the last 5 years?</div>
						<div className="text-sm">Your 'home' country being the DS you trained with</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="select"
							value={(placementPref && placementPref.sixMonthInFiveYears) || '0'}
							list={[
								{
									label: 'No',
									value : '254981732'
								},
								{
									label: 'Yes',
									value: '254981733'
								}
							]}
							edit={true}
							updateValue={(val) => handleEdit('sixMonthInFiveYears', val)}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Would you be comfortable working for gambling, drinks, tobacco, defence companies etc.</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="select"
							value={(placementPref && placementPref.problemCompanies) || '0'}
							list={[
								{
									label: 'No',
									value : '254981735'
								},
								{
									label: 'Yes',
									value: '254981736'
								}
							]}
							edit={true}
							updateValue={(val) => handleEdit('problemCompanies', val)}
						/>
					</Grid>
				</Grid>
				{placementPref && placementPref.problemCompanies && placementPref.problemCompanies === '254981735' && 
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">If no please specify</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="text"
							value={(placementPref && placementPref.problemCompaniesReason) || ''}
							edit={true}
							updateValue={(val) => handleEdit('problemCompaniesReason', val)}
							fullWidth={true}
							maxRows={4}
						/>
					</Grid>
				</Grid>}
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Any particular preferences on companies/industries/types of project you'd like the opportunity to try?</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="text"
							value={(placementPref && placementPref.companiesLikeToTry) || ''}
							edit={true}
							updateValue={(val) => handleEdit('companiesLikeToTry', val)}
							fullWidth={true}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Any particular companies/industries/types of project you're apprehensive about being allocated to?</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="text"
							value={(placementPref && placementPref.companiesApprehensive) || ''}
							edit={true}
							updateValue={(val) => handleEdit('companiesApprehensive', val)}
							fullWidth={true}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Are there any companies you can see previous DSers have worked with that would particularly appeal if the 
						opportunity came up and why have they piqued your interest?</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="text"
							value={(placementPref && placementPref.companiesPreviousDser) || ''}
							edit={true}
							updateValue={(val) => handleEdit('companiesPreviousDser', val)}
							fullWidth={true}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<div className="pt-2">Is there anything else you'd like us to be aware of in relation to placement selection?</div>
					</Grid>
					<Grid item xs={12}>
						<EditField
							type="text"
							value={(placementPref && placementPref.placementComment) || ''}
							edit={true}
							updateValue={(val) => handleEdit('placementComment', val)}
							fullWidth={true}
							maxRows={4}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</>
  );
}
