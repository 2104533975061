import React, { useState } from "react";
import useClasses from '../../../../hooks/useClasses';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function MySelectField(props) {
  const [value, setValue] = useState(props.value || "");

  const styles = theme => ({
    root: {
      width: props.style && props.style.width ? props.style.width : "100%",
    },
    select: {
      fontSize: "0.875rem",
    },
  });

  const classes = useClasses(styles);

  const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "0px 0px 3px 0px",
      width: props.style && props.style.width ? props.style.width : "100%",
      maxWidth:
        props.style && props.style.maxWidth ? props.style.maxWidth : "100%",
      textAlign:
        props.style && props.style.textAlign
          ? props.style.textAlign
          : "inherit",
    },
  };

  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{ width: "100%" }}
    >
      <Select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.updateValue(e.target.value);
        }}
        inputProps={inputProps}
        classes={classes}
        autoFocus={props.focus ? true : false}
        variant="standard"
      >
        <MenuItem disabled value={"0"} key={"0"} sx={{'& .MuiMenuItem-root' : {fontSize: '14px'}}}>
          {props.initialValue}
        </MenuItem>
        {props.list.map((val, idx) => {
          return (
            <MenuItem value={val.value} key={val.value} sx={{'& .MuiMenuItem-root' : {fontSize: '14px'}}}>
              {val.label}
            </MenuItem>
          );
        })}
      </Select>
    </span>
  );
}
