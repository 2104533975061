import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import { NumericFormat } from 'react-number-format';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const connectedToFA = (profile) => {
	const identities = profile?.identities;
	let faIdx = -1;
	if (identities && identities.length > 0) {
		faIdx = identities.findIndex((i) => {
			return i.connection === "FreeAgent";
		})
	}
	return faIdx > -1;
}

const faCredentials = (profile) => {
	const identities = profile?.identities;
	let faIdx = -1;
	if (identities && identities.length > 0) {
		faIdx = identities.findIndex((i) => {
			return i.connection === "FreeAgent";
		})
	}
	return identities[faIdx];
}

const handleFALogin = (refreshFA) => {
	const loginWindow = window.open(
		'https://theinformationlab.eu.auth0.com/authorize?response_type=code&client_id=J1i2FSnhUHJsguHPzpSMrYM4TB7xm75p&redirect_uri=https://portal-local.theinformationlab.co.uk/callback&scope=openid%20profile%20email&connection=FreeAgent',
		'FALogin',
		'height=600,width=450,menubar=0'
	);
	var timer = setInterval(function() { 
		if(loginWindow.closed) {
			clearInterval(timer);
			console.log('[CreateEstimate.js] Update Auth0 Profile');
			refreshFA();
		}
	}, 1000);
}

const setWithExpiry = (key, value, ttl) => {
	const now = new Date()

	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

const refreshFaToken = (token) => new Promise ((resolve, reject) => {

	const currentToken = getWithExpiry('faAccessToken');
	if (currentToken) { 
		resolve(currentToken) 
	} else {

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		var raw = JSON.stringify({
			"refreshToken": token
		});
	
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};
	
		fetch("/api/freeagent/refreshToken", requestOptions)
			.then(response => response.json())
			.then(result => {
				setWithExpiry('faAccessToken', result["access_token"], 3600000);
				resolve(result["access_token"]);
			})
			.catch(error => console.log('error', error));
	}
	
})

const getLatestEstimateRef = (token) => new Promise((resolve, reject) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		"auth": `Bearer ${token}`
	});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	fetch("/api/freeagent/estimateRef", requestOptions)
		.then(response => response.json())
		.then(result => resolve(result))
		.catch(error => reject(error));
})

const createEstimate = (items, clientId, ref) => new Promise((resolve, reject) => {
	const token = getWithExpiry('faAccessToken');

	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		"auth": `Bearer ${token}`,
		"items": items,
		"clientId": clientId,
		"ref": ref.nextRef,
		"notes": `Any Tableau licence pricing on this quote is valid for 7 calendar days from the date stated at the top of the document. This is due to ongoing exchange rate fluctuation to the US Dollar.

		Reasonable expenses will be charged in addition to the costs detailed on this quote.
		
		This quote is subject to our standard Terms and Conditions, a copy of which is available on request.`,
	});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	fetch("/api/freeagent/createEstimate", requestOptions)
		.then(response => response.json())
		.then(result => resolve(result))
		.catch(error => reject(error));
});

const AuthorizeFA = ({refreshAuth0}) => {
	return (
		<>
			<div className='mt-6'>
				You need to authorize the TIL Portal to access your FreeAgent account
			</div>
			<Button 
				variant="contained" 
				className='mt-6 bg-[#3f8528]'
				onClick={() => handleFALogin(refreshAuth0)}
			>
				Login with FreeAgent
			</Button>
		</>
	)
}

const handleCreateEstimate = (items, clientId, faToken, setLoading, setStatusMsg) => {
	setLoading(true);
	setStatusMsg('Refreshing FreeAgent Access Token');
	refreshFaToken(faToken)
		.then((token) => {
			console.log('[CreateEstimate.js] refreshFaToken token:', token);
			setStatusMsg('Getting a new estimate reference number');
			return getLatestEstimateRef(token);
		})
		.then((ref) => {
			console.log('[CreateEstimate.js] getLatestEstimate ref:', ref);
			setStatusMsg('Creating a new estimate');
			return createEstimate(items, clientId, ref);
		})
		.then((res) => {
			console.log('[CreateEstimate.js] createEstimate res:', res);
			if (res.status === "success") {
				setStatusMsg('Sending you to FreeAgent');
				const apiRef = res.estimate.url;
				const faUrl = apiRef.replace('https://api.freeagent.com/v2/estimates/', 'https://theinformationlab.freeagent.com/estimates/');
				setTimeout(() => {
					window.open(faUrl);
				}, 1000)
			} else {
				setStatusMsg('Error!');
			}
		})
		.catch((err) => {
			console.error('[CreateEstimate.js] handleCreateEstimate', err);
			setStatusMsg('Error!');
		})
		.finally(() => {
			setTimeout(() => {
				setStatusMsg('');
				setLoading(false);
			}, 3000)
		})
}

const EstimateItem = ({item, sector}) => {
	const price = (sector === "Education" || sector === "Charity") && item.eduPriceList !== null  ? item.eduPriceList[0].price : item.stdPriceList[0].price;
	const total = price * item.qty;
	return (
		<TableRow className='w-full'>
			<TableCell>{item.qty}</TableCell>
			<TableCell component="th" scope="row">{item.softwareProduct}</TableCell>
			<TableCell>{price}</TableCell>
			<TableCell>
				<NumericFormat
					value={total}
					fixedDecimalScale={true}
					decimalScale={2}
					displayType={"text"}
					thousandSeparator={true}
					prefix={'£'}
				/>
			</TableCell>
		</TableRow>
)}

const BuildEstimate = ({
		auth0Profile, 
		items, 
		sector, 
		clientId, 
		loading, 
		setLoading,
		statusMsg,
		setStatusMsg
	}) => {
	const faItentity = faCredentials(auth0Profile);
	let subTotal = 0;
	const itemsForFA = [];
	items.forEach((item) => {
		const priceList = (sector === "Education" || sector === "Charity") && item.eduPriceList !== null  ? item.eduPriceList[0] : item.stdPriceList[0];
		const total = priceList.price * item.qty;
		subTotal += total;
		const faItem = {
			itemType : priceList.itemType,
			quantity : item.qty,
			price : priceList.price,
			description: priceList.description
		};
		itemsForFA.push(faItem);
	})
	return (
		<>
				<TableContainer>
					<Table sx={{ minWidth: '100%' }}>
						<TableHead className='w-full'>
							<TableRow className='w-full'>
								<TableCell>Qty</TableCell>
								<TableCell>Product</TableCell>
								<TableCell>Unit Price</TableCell>
								<TableCell>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((item => (
								<EstimateItem key={item.id} item={item} sector={sector} />
							)))}
							<TableRow>
								<TableCell rowSpan={3} colSpan={2}/>
								<TableCell className='font-medium'>Subtotal</TableCell>
								<TableCell align="left">
									<NumericFormat
										value={subTotal}
										fixedDecimalScale={true}
										decimalScale={2}
										displayType={"text"}
										thousandSeparator={true}
										prefix={'£'}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<div className='flex'>
					<LoadingButton className="mr-4 mt-2" size="small" loadingPosition="start" loading={loading} startIcon={<SaveIcon />} variant="outlined" onClick={() => handleCreateEstimate(itemsForFA, clientId, faItentity["refresh_token"], (l) => setLoading(l), (s) => setStatusMsg(s))}>Create</LoadingButton>
					{statusMsg !== "" ? <Alert variant="filled" severity={statusMsg === "Sending you to FreeAgent" ? "success" : statusMsg === "Error!" ? "error" : "info"} size="small" className='mt-2' sx={{ paddingTop: 0, paddingBottom: 0 }}>{statusMsg}</Alert> : null}
				</div>
				
		</>
	)
}

export default function CreateEstimate({isOpen, handleClose, auth0Profile, refreshAuth0, items, sector, clientId}) {
	const [ needsAuthorization, setNeedsAuthorization ] = useState(!connectedToFA(auth0Profile));
	const [ loading, setLoading ] = useState(false);
	const [ statusMsg, setStatusMsg ] = useState('');
	useEffect(() => {
		console.log('[CreateEstimate.js] auth0Profile has changed!', !connectedToFA(auth0Profile));
		setNeedsAuthorization(!connectedToFA(auth0Profile));
	}, [auth0Profile])

	return (
		<div>
		<Modal
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<h2 className='text-2xl mb-8'>Create Estimate in FreeAgent</h2>
				{needsAuthorization ? 
					<AuthorizeFA refreshAuth0={refreshAuth0}/>
					: 
					<BuildEstimate 
						auth0Profile={auth0Profile} 
						items={items} 
						sector={sector} 
						clientId={clientId}
						loading={loading}
						setLoading={(l) => setLoading(l)}
						statusMsg={statusMsg}
						setStatusMsg={(s) => setStatusMsg(s)}
					/>
				}
			</Box>
		</Modal>
		</div>
	);
}