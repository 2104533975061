import React from "react";
import Icon from "react-icon-base";

const convoIcon = (props) => {
  return (
    <Icon viewBox="0 0 500 500" {...props}>
      <title>{props.title || "Convo"}</title>
      <g>
        <path
          className="st0"
          d="M463.8,253.5c-1,11.6-3.3,22.9-7.6,33.8c-28.1,70.6-119.5,91.6-175.4,40.2c-3.6-3.3-6.9-6.8-7.5-12
	c-0.9-7,2.4-13.5,8.5-16.7c6.4-3.4,13.6-2.4,19.3,3c12.5,11.8,27.1,19,44.3,21c39.2,4.7,75.4-20.8,84.2-59.2
	c8.7-38.1-12.9-76.9-49.9-89.6c-32.3-11.1-65-0.8-85,26.8c-9.7,13.4-15.8,28.5-20.2,44.2c-5.9,20.6-13.8,40.3-26.2,57.9
	c-30,42.8-83.8,63.1-131.7,48.5C76,339.3,50.8,311.8,40.2,271c-0.4-1.5-0.1-3.4-1.8-4.4c-0.7-12.3-2-24.6,0.1-36.8
	c3-15.1,8.1-29.4,16.5-42.5c29.8-45.9,89.6-63.1,138.6-39.6c8.6,4.1,12.5,11.8,10.1,19.9c-3,10.1-13.5,14.5-23.7,9.7
	c-19.2-8.9-38.8-10-58.8-3c-37.2,13.2-58.1,51.9-49,90.6c8.8,37.3,45.2,62.6,83.7,58.1c35.6-4.2,59.7-24,75.2-55.4
	c6.6-13.4,10.3-27.7,15-41.7c7.6-22.7,18.6-43.5,36.1-60.2c46.1-43.9,122.5-35.9,160.7,16.9c12.1,16.7,19.5,35.1,20.2,56
	c0,0.4,0.4,0.9,0.5,1.3C463.8,244.4,463.8,248.9,463.8,253.5z"
        />
      </g>
    </Icon>
  );
};

export default convoIcon;