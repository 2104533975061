import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import CheckInsTable from "./CheckIns/CheckIns";
import ConvoUsersTable from "./ConvoUsers/ConvoUsers";
import ZoomEvents from "./ZoomEvents/ZoomEvents";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import ClientsContext from "../../../../../context/clients-context";

const customerSuccessMutation = gql`
  mutation customerSuccess($id: ID, $checkIns: [UpdateCheckIns]) {
    updateCustomerSuccess(input: { id: $id, checkIns: $checkIns }) {
      checkIns {
        id
        type
        date
        outcome
      }
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const classes = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appbar: {
    border: 0,
    backgroundColor: "unset",
    marginLeft: -20,
  },
  indicator: {
    backgroundColor: "#009be5",
  },
  tabPanelRoot: {
    paddingBottom: 0,
  },
  card: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
    },
    cursor: "pointer",
    maxWidth: "calc(100vw - 20px)",
  },
  sectionTitle: {
    color: "#6f6f6f",
    marginRight: 20,
    height: 40,
  },
  edit: {
    marginTop: -5,
  },
  flexGrow: {
    flexGrow: 1,
  },
  toolButtons: {
    marginBottom: 10,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardContentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

export default function CustomerSuccess(props) {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [client, setClient] = useState(props.client);
  const [loadedClient, setLoadedClient] = useState(props.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addingNew, setAddingNew] = useState(false);
  const [checkIns, setCheckIns] = useState([]);
  const [checkInCount, setCheckInCount] = useState(0);
  const [convoUserCount, setConvoUserCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [reloadViz, setReloadViz] = useState(false);
  const clientsContext = useContext(ClientsContext);

  useEffect(() => {
    setClient({ ...props.client });
    setLoadedClient({ ...props.client });
  }, [props.client]);

  useEffect(
    () => {
      if (client.customerSuccess && client.customerSuccess.checkIns) {
        setCheckIns([...client.customerSuccess.checkIns]);
        setCheckInCount(client.customerSuccess.checkIns.length);
      }
      if (client.customerSuccess && client.customerSuccess.convoUsers) {
        setConvoUserCount(client.customerSuccess.convoUsers.length);
      }
    } /* eslint-disable */,
    [client] /* eslint-enable */
  );

  function addCheckIn() {
    setAddingNew(true);
    const updatedCheckIns = [...checkIns];
    updatedCheckIns.unshift({
      id: 999999,
      clientId: client.id,
      type: "Chat",
      date: "",
      outcome: "Upcoming",
    });
    setClient({ ...client, customerSuccess: { checkIns: updatedCheckIns } });
  }

  function handleUpdateCheckIn(checkIn) {
    if (checkIn.type) {
      console.log("[CustomerSuccess.js] context", clientsContext.checkInTypes);
      const type = clientsContext.checkInTypes.find((a, idx) => {
        return checkIn.type === a.label;
      });
      checkIn.typeId = parseInt(type.id);
    }
    if (checkIn.outcome) {
      const outcome = clientsContext.checkInOutcomes.find((a, idx) => {
        return checkIn.outcome === a.outcome;
      });
      checkIn.outcomeId = parseInt(outcome.id);
    }
    const idx = client.customerSuccess.checkIns.findIndex((r) => {
      return r.id === checkIn.id;
    });
    const updatedCheckIns = [...client.customerSuccess.checkIns];
    updatedCheckIns[idx] = checkIn;
    setClient({ ...client, customerSuccess: { checkIns: updatedCheckIns } });
  }

  function handleCancel() {
    console.log("[CustomerSuccess.js] loadedClient", loadedClient);
    setIsEditing(false);
    setError(null);
    setClient({ ...loadedClient });
  }

  function handleDeleteCheckIn(id) {
    console.log("[CustomerSuccess.js] Delete check in", id);
    const idx = client.customerSuccess.checkIns.findIndex((r) => {
      return r.id === id;
    });
    const updatedCheckIns = [...client.customerSuccess.checkIns];
    updatedCheckIns.splice(idx, 1);
    setClient({ ...client, customerSuccess: { checkIns: updatedCheckIns } });
  }

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [
    saveCustomerSuccess,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(customerSuccessMutation, {
    update: (store, { data: { updateCustomerSuccess }, error }) => {
      console.log("[CustomerSuccess.js] graphql update", updateCustomerSuccess);
      const newClient = { ...client };
      client.customerSuccess.checkIns = updateCustomerSuccess.checkIns;
      setClient(newClient);
    },
    onError: (error) => {
      console.error("[CustomerSuccess.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (loading && !mutationLoading) {
      if (!mutationError) {
        console.log("[CustomerSuccess.js] Mutation Complete");
        setError(null);
        setIsEditing(false);
        setLoading(false);
      } else {
        console.log("[CustomerSuccess.js] Mutation Error");
        setIsEditing(true);
        setLoading(false);
      }
    } else if (!loading && mutationLoading) {
      console.log("[CustomerSuccess.js] Saving Mutation");
      setError(null);
      setIsEditing(true);
      setLoading(true);
    } /* eslint-disable */
  }, [mutationLoading, mutationError]); /* eslint-enable */

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover && selectedTab < 1 ? onHoverStyle : offHoverStyle}
      onClick={() =>
        !isEditing && selectedTab < 1 ? setIsEditing(true) : null
      }
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{
              display:
                hover && !isEditing && selectedTab < 1 ? "inherit" : "none",
            }}
          />
        }
        title={
          <div className={classes.toolButtons}>
            Customer Success
            <Button
              color="primary"
              size="small"
              className={classes.button}
              style={{ display: !isEditing ? "none" : "unset", marginLeft: 10 }}
              onClick={addCheckIn}
            >
              Add New
            </Button>
          </div>
        }
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <AppBar
          position="static"
          color="inherit"
          className={classes.appbar}
          elevation={0}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{ className: classes.indicator }}
          >
            <Tab label={"Check Ins (" + checkInCount + ")"} {...a11yProps(0)} />
            <Tab
              label={"Convo Users (" + convoUserCount + ")"}
              {...a11yProps(1)}
            />
            <Tab
              label={"Zoom Event Attendance"}
              {...a11yProps(2)}
              onClick={() => setReloadViz(!reloadViz)}
            />
          </Tabs>
        </AppBar>
        <TabPanel style={{ marginBottom: -30 }} value={selectedTab} index={0}>
          <CheckInsTable
            checkIns={checkIns}
            isEditing={isEditing}
            editCheckIn={(checkIn) => handleUpdateCheckIn(checkIn)}
            addingNew={addingNew}
            deleteCheckIn={(id) => handleDeleteCheckIn(id)}
          />
        </TabPanel>
        <TabPanel style={{ marginBottom: -30 }} value={selectedTab} index={1}>
          <ConvoUsersTable
            convoUsers={
              client.customerSuccess && client.customerSuccess.convoUsers
                ? client.customerSuccess.convoUsers
                : []
            }
            isEditing={false}
          />
        </TabPanel>
        <TabPanel style={{ marginBottom: -30 }} value={selectedTab} index={2}>
          <ZoomEvents
            domains={client.domains}
            clicked={reloadViz}
            active={selectedTab === 2}
          />
        </TabPanel>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: loading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              const checkIns = [...client.customerSuccess.checkIns];
              console.log('[CustomerSuccess.js] submitCheckIns', checkIns);
              const submitCheckIns = checkIns.map((c) => {
                const checkIn = {...c}
                checkIn.clientId = parseInt(client.id);
                console.log('[CustomerSuccess.js] Check In', checkIn);
                if (checkIn.type) {
                  const type = clientsContext.checkInTypes.find((a, idx) => {
                    return checkIn.type === a.label;
                  });
                  checkIn.typeId = parseInt(type.id);
                  delete checkIn.type;
                }
                if (checkIn.outcome) {
                  const outcome = clientsContext.checkInOutcomes.find(
                    (a, idx) => {
                      return checkIn.outcome === a.outcome;
                    }
                  );
                  checkIn.outcomeId = parseInt(outcome.id);
                  delete checkIn.outcome;
                }
                if (checkIn.__typename) {
                  delete checkIn.__typename;
                }
                return checkIn;
              });
              saveCustomerSuccess({
                variables: {
                  id: parseInt(client.id),
                  checkIns: submitCheckIns,
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
}
