export const saleSignStatus = (sale) => {
    // console.log('[saleSignStatus] sale', sale);
    if (sale.signed) {
        // console.log('[saleSignStatus] status signed');
      return "signed";
    } else if (
      !sale.signed &&
      sale.region === "UK" &&
      sale.faInvoiceNumber &&
      sale.faInvoiceNumber.length > 1 &&
      (sale.amountEuro > 0 || sale.amountGbp > 0 || sale.amountDollars > 0)
    ) {
        // console.log('[saleSignStatus] status ukReady');
      return "ukReady";
    } else if (
      !sale.signed &&
      sale.region !== "UK" &&
      (sale.amountEuro > 0 || sale.amountGbp > 0 || sale.amountDollars > 0)
    ) {
        // console.log('[saleSignStatus] status euReady');
      return "euReady";
    }
    // console.log('[saleSignStatus] status not ready');
    return "notReady";
  }