import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import EditField from "../../../../../Shared/EditField/EditField";

export default function Experience({
		experience, 
		softwareExperienceList, 
		languages, 
		passportList, 
		edit
	}) {

	const [ softwareList, setSoftwareList ] = useState([]);
	const [ languageList, setLanguageList ] = useState([]);
	const [ passports, setPassports ] = useState([]);

	useEffect(() => {
		if (softwareExperienceList && softwareExperienceList.length > 0) {
			const newList = softwareExperienceList.map((i) => {
				return {
					label: i.value,
					value: i.id}
			})
			setSoftwareList(newList);
		}
	}, [softwareExperienceList])

	useEffect(() => {
		if (languages && languages.length > 0) {
			const newList = languages.map((i) => {
				return {
					label: i.value,
					value: i.id}
			})
			setLanguageList(newList);
		}
	}, [languages])

	useEffect(() => {
		if (passportList && passportList.length > 0) {
			const newList = passportList.map((i) => {
				return {
					label: i.value,
					value: i.id}
			})
			setPassports(newList);
		}
	}, [passportList])

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={8} xl={6}>
				<div className="pt-2">What software do you have experience using?</div>
			</Grid>
			<Grid item xs={12} sm={4} xl={6}>
				<EditField
					type="multiselect"
					value={(experience && experience.softwareExperience) || ''}
					list={softwareList}
					edit={true}
					updateValue={(val) => edit('softwareExperience', val)}
				/>
			</Grid>
			<Grid item xs={12} sm={8} xl={6}>
				<div className="pt-2">Which languages do you speak?</div>
			</Grid>
			<Grid item xs={12} sm={4} xl={6}>
				<EditField
					type="multiselect"
					value={(experience && experience.languagesSpoken) || ''}
					list={languageList}
					edit={true}
					updateValue={(val) => edit('languagesSpoken', val)}
				/>
			</Grid>
			<Grid item xs={12} sm={8} xl={6}>
				<div className="pt-2">Which passports do you hold?</div>
			</Grid>
			<Grid item xs={12} sm={4} xl={6}>
				<EditField
					type="multiselect"
					value={(experience && experience.passports) || ''}
					list={passports}
					edit={true}
					updateValue={(val) => edit('passports', val)}
				/>
			</Grid>
			<Grid item xs={12} sm={8} xl={6}>
				<div className="pt-2">Do you hold dual citizenship?</div>
			</Grid>
			<Grid item xs={12} sm={4} xl={6} sx={{paddingLeft: 10}}>
				<EditField
					type="select"
					value={(experience && experience.dualCitizenship) || '0'}
					list={[{
						label: 'No',
						value: '254975262'
					},{
						label: 'Yes',
						value: '254975263'
					}]}
					edit={true}
					updateValue={(val) => edit('dualCitizenship', val)}
				/>
			</Grid>
			<Grid item xs={12}>
				<div className="pt-2">Any achievements or successes you are particularly proud of?</div>
			</Grid>
			<Grid item xs={12}>
				<EditField
					type="text"
					value={(experience && experience.achievements) || ''}
					edit={true}
					updateValue={(val) => edit('achievements', val)}
					fullWidth={true}
					maxRows={4}
				/>
			</Grid>
		</Grid>
	);
}
