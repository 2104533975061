import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const labels = {
	trainingStart: {
		icon: 'T',
		text: 'Training'
	},
	p1: {
		icon: 'P1',
		text: 'Placement 1'
	},
	b2s1: {
		icon: 'B2S',
		text: 'Back to School'
	},
	p2: {
		icon: 'P2',
		text: 'Placement 2'
	},
	b2s2: {
		icon: 'B2S',
		text: 'Back to School'
	},
	p3: {
		icon: 'P3',
		text: 'Placement 3'
	},
	b2s3: {
		icon: 'B2S',
		text: 'Back to School'
	},
	p4: {
		icon: 'P4',
		text: 'Placement 4'
	},
	contractEnd: {
		icon: 'E',
		text: 'Contract Ends'
	},
}

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 30,
	height: 30,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
	  backgroundColor: '#1976d2',
	  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
	  backgroundColor: '#7ea9ba',
	}),
  }));

export default function Timeline({dates}) {
	const [activeStep, setActiveStep] = useState(0);
	const [dateArr, setDateArr] = useState([]);
	const horizontalTimeline = useMediaQuery('(min-width:600px)');

	useEffect(() => {
		const da = [];
		for (const [key, value] of Object.entries(dates)) {
			if (key !== '__typename' && value) {
				da.push({
					icon: labels[key].icon,
					text: labels[key].text,
					key,
					value: parseInt(value)
				})
			}
		}
		setDateArr(da);
		const today = new Date();
		da.forEach((dt, idx) => {
			if (dt.value && moment(dt.value).isBefore(today)) {
				setActiveStep(idx);
			}
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	function ColorlibStepIcon(props, d) {
		const { active, completed, className } = props;
		return (
		  <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{d[props.icon-1] && d[props.icon-1] && d[props.icon-1].icon}
		  </ColorlibStepIconRoot>
		);
	  }

	return (
		<Box sx={{ width: '100%', marginTop: 4, marginBottom: 4 }}>
		<Stepper activeStep={activeStep} alternativeLabel={horizontalTimeline} orientation={horizontalTimeline ? "horizontal" : "vertical"}>
			{dateArr.map((date) => {
			const stepProps = {};
			const labelProps = {
				optional: moment(date.value).format('Do MMM YY')
			};
			return (
				<Step key={date.key} {...stepProps}>
				<StepLabel StepIconComponent={(props) => ColorlibStepIcon(props, dateArr)} {...labelProps}>{date.text}</StepLabel>
				</Step>
			);
			})}
		</Stepper>
		</Box>
	);
}