import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import EditField from "../../../../../Shared/EditField/EditField";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  { id: "surname", numeric: false, disablePadding: true, label: "Surname" },
  { id: "title", numeric: false, disablePadding: true, label: "Title" },
  { id: "status", numeric: false, disablePadding: true, label: "Status" },
  {
    id: "lastLoginDate",
    numeric: false,
    disablePadding: true,
    label: "Last Login Date",
  },
  {
    id: "lastInviteReminderDate",
    numeric: false,
    disablePadding: true,
    label: "Last Invite Reminder",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              display: !editMode && headCell.editOnly ? "none" : "table-cell",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={!props.addingNew ? createSortHandler(headCell.id) : null}
              style={{ whiteSpace: "nowrap" }}
            >
              {headCell.label}
              {orderBy === headCell.id && !editMode ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const classes = (theme) => ({
  root: {
    width: `calc(100% + 40px)`,
    padding: 0,
    marginLeft: -20,
    marginTop: -20,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  comment: {
    maxWidth: 340,
  },
  deleteBtn: {
    color: "rgba(0,0,0,0.2)",
    backgroundColor: "rgba(0,0,0,0.0)",
    height: 30,
    width: 30,
    minHeight: 20,
    lineHeight: "2.25rem",
    boxShadow: "none",
    marginTop: 5,
  },
  deleteIcon: {
    fontSize: "1rem",
  },
  tableCell: {
    paddingLeft: 0,
  },
});

export default function CheckInsTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("surname");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(props.convoUsers);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(
    () => {
      if (props.convoUsers) {
        setRows([...props.convoUsers]);
      }
    } /* eslint-disable */,
    [props.convoUsers] /* eslint-enable */
  );

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            editMode={props.isEditing}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row" padding="none">
                      <EditField
                        type={"text"}
                        value={row.firstName}
                        href={`https://app.convo.com/#/v1/acc-26f67bc7-4b15-4825-e053-760e880a4be5/feed?q=from:%22${row.id}%22`}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.surname}
                        href={`https://app.convo.com/#/v1/acc-26f67bc7-4b15-4825-e053-760e880a4be5/feed?q=from:%22${row.id}%22`}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField type={"text"} value={row.title} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField
                        type={"text"}
                        value={row.status}
                        href={
                          row.status === "INVITED"
                            ? `mailto:${row.email}`
                            : null
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField type={"date"} value={row.lastLoginDate} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField
                        type={"date"}
                        value={row.lastInviteReminderDate}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
    </div>
  );
}
