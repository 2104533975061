import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useClasses from '../../../hooks/useClasses';

const styles = (theme) => ({
  iconButtonAvatar: {
    padding: 4,
  },
});

function Profile(props) {
  const classes = useClasses(styles);
  const { user, isAuthenticated, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    logout({ returnTo: window.location.origin })
  };

  return isAuthenticated ? 
    (
      <div>
        <IconButton
          color="inherit"
          className={classes.iconButtonAvatar}
          onClick={handleClick}
          size="large">
          <Avatar
            className={classes.avatar}
            src={user.picture || "/static/images/whiteBubbles.png"}
            alt="My Avatar"
            aria-controls="profile-menu"
            aria-haspopup="true"
          />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </div>
    )
    : null;
}

export default Profile;
