import React from "react";
import useClasses from '../../../../../../hooks/useClasses';
import Avatar from "@mui/material/Avatar";

const styles = theme => ({
  avatar: {
    marginLeft: -10,
    fontSize: 14,
    backgroundColor: "#18202c",
    border: 0.5,
  },
});

export default function AMAvatar(props) {
  const classes = useClasses(styles);
  let name = "";
  let photo = "";
  if (props.am && props.am.name) {
    name = props.am.name;
  }
  if (props.am && props.am.photo) {
    photo = props.am.photo;
  }
  return props.am.percentOwned > 0 ? (
    <Avatar alt={name} src={photo} className={classes.avatar}>
      {photo === ""
        ? name
            .split(" ")
            .map((n) => n[0])
            .join("")
        : ""}
    </Avatar>
  ) : null;
}
