import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import FilterBar from "../../../Shared/FilterBar/FilterBar";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    filter: "text",
    label: "Name",
  },
  {
    id: "reportingName",
    numeric: false,
    disablePadding: true,
    filter: "text",
    label: "Reporting Name",
  },
  {
    id: "sector",
    numeric: false,
    disablePadding: true,
    filter: "selector",
    filterSource: "sectors",
    label: "Sector",
  },
  {
    id: "accountManager",
    numeric: false,
    disablePadding: true,
    filter: "selector",
    filterSource: "accountManagers",
    label: "Account Managers",
  },
  {
    id: "nextRenewal",
    numeric: false,
    disablePadding: true,
    filter: "date",
    label: "Next Renewal",
  },
];

export default function TableHeaderRow(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow style={{ visibility: props.filter ? "visible" : "collapse" }}>
        <TableCell colSpan={6}>
          <FilterBar
            changeFilters={props.changeFilters}
            filters={props.filters}
            filterable={props.filterable}
            setFilterable={props.setFilterable}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

TableHeaderRow.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
