import React, {useState, useEffect} from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import BasicInfo from "./BasicInfo/BasicInfo";
import DSInfoSelector from "./DSInfoSelector/DSInfoSelector";
import Timeline from "./Timeline/Timeline";

const bobQuery = gql`
    query BobProfile {
      getMyBobProfile {
        id
        firstName
        surname
        displayName
        email
        avatar
        cohort
        contractStart
        startDate
        softwareExperience
        languagesSpoken
        passports
        dualCitizenship
        achievements
        shiftedHours
        otherOffices
        sixMonthInFiveYears
        problemCompanies
        problemCompaniesReason
        companiesLikeToTry
        companiesApprehensive
        companiesPreviousDser
        placementComment
        coach {
          id
          displayName
          avatar
        }
        supportLead {
          id
          displayName
          avatar
        }
        holidayBalance {
          totalBalanceAsOfDate
          startingBalance
          startingBalanceAsOf
        }
        previousEmployment {
          firstJob
          jobTitle
          jobDescription
        }
        cohortDates {
          trainingStart
          p1
          b2s1
          p2
          b2s2
          p3
          b2s3
          p4
          contractEnd
        }
      }
      getMyBobBackgroundInfo {
        higher {
          id
          type
          date
          name
          institution
        }
      }
      softwareExperienceList: getBobListValues (id: "category_1670942753852.field_1671456953648") {
        id
        value
      }
      languagesList: getBobListValues (id: "about.field_1672832201143") {
        id
        value
      }
      passportList: getBobListValues (id: "identification.field_1672844619229") {
        id
        value
      }
      shiftedHoursAnswers: getBobListValues (id: "category_1655127931600.field_1672915288652") {
        id
        value
      }
      otherOfficesList: getBobListValues (id: "category_1655127931600.field_1672915459063") {
        id
        value
      }
    }
  `;

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

function Content({auth0Profile}) {
  const { user } = useAuth0();

  const [ country, setCountry ] = useState('');
  const [ dsConsultant, setDsConsultant ] = useState(false);

  useEffect(() => {
    console.log('[DSProfile.js] user', user )
    if (user) {
      setCountry(user["https://sales.theinformationlab.co.uk/scope"]);
    }
  }, [user])

  useEffect(() => {
    console.log('[Home.js] auth0Profile', auth0Profile )
    if (auth0Profile && auth0Profile.groups && auth0Profile.groups.indexOf("The Data School UK") > -1) {
		setDsConsultant(true);
    }
  }, [auth0Profile])

  const { loading, data, refetch } = useQuery(bobQuery);

  useEffect(() => {
    console.log('[DSProfile.js] Data', data);
  }, [data])

  if (!dsConsultant) {
    console.log('[DSProfile.js] No access to DS Profile page', dsConsultant);
    return (
      <GeneralMessage type="error" message="You're not a DS Consultant"/>
    )
  }

  if (loading) {
    return (
      <GeneralMessage type="loading" />
    )
  }

  if (!loading) {
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <Typography variant="h4" gutterBottom>
              Data School Central
            </Typography>
            <Timeline dates={data && data.getMyBobProfile && data.getMyBobProfile.cohortDates && data.getMyBobProfile.cohortDates[0] ? data.getMyBobProfile.cohortDates[0] : null}/>
            <DSInfoSelector
              background={data && data.getMyBobBackgroundInfo ? data.getMyBobBackgroundInfo : null} 
              previousEmployment={data && data.getMyBobProfile && data.getMyBobProfile.previousEmployment ? data.getMyBobProfile.previousEmployment : null}
              softwareExperienceList={data && data.softwareExperienceList ? data.softwareExperienceList : null}
              languages={data && data.languagesList ? data.languagesList : null}
              passportList={data && data.passportList ? data.passportList : null}
              shiftedHoursAnswers={data && data.shiftedHoursAnswers ? data.shiftedHoursAnswers : null}
              otherOfficesList={data && data.otherOfficesList ? data.otherOfficesList : null}
              profile={data && data.getMyBobProfile ? data.getMyBobProfile : null}
              refetch={refetch} />
          </Grid>
          <Grid item lg={4} xs={12}>
            <BasicInfo profile={data && data.getMyBobProfile ? data.getMyBobProfile : null} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withAuthenticationRequired(Content, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});
