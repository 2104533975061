import React, { useState, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import useClasses from '../../../hooks/useClasses';
import TableHeaderRow from "./TableHeaderRow/TableHeaderRow";
import TableActionsRow from './TableActionsRow/TableActionsRow';
import TableBodySales from "./TableBodySales/TableBodySales";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Sale from "./Sale/Sale";
import { useSetUrlFilters } from "../../Shared/UrlFiltering";
import GeneralMessage from "../../GeneralMessage/GeneralMessage";

const styles = theme => ({
  root: {
    maxWidth: "50%",
    flex: 1
  },
  paper: {
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
  },
  table: {
  },
  tableWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  edit: {
    marginTop: -5,
  },
});


const headCellDefault = [
  { 
    id: "id", 
    dbField: "ID",
    numeric: false, 
    date: false,
    disablePadding: true, 
    label: "ID",
    visible: true
  },{ 
    id: "status", 
    dbField: null,
    numeric: false, 
    date: false,
    disablePadding: true, 
    label: "",
    visible: true
  },{ 
    id: "date", 
    dbField: "Sales_Entry_Date",
    numeric: false, 
    date: true,
    disablePadding: true, 
    label: "Date",
    visible: true
  },{
    id: "vendorOrderNumber",
    dbField: "Vendor_Order_Number",
    numeric: false,
    date: false,
    disablePadding: true,
    label: "Vendor Order Number",
    visible: true
  },{
    id: "clientName",
    dbField: "Client_Name",
    numeric: false,
    date: false,
    disablePadding: true,
    label: "Client Name",
    visible: true
  },{ 
    id: "user", 
    dbField: "User",
    numeric: false, 
    date: false,
    disablePadding: true, 
    label: "Owner",
    visible: true
  },{ 
    id: "payee", 
    numeric: false, 
    date: false,
    disablePadding: true, 
    label: "Payee",
    visible: true
  }, {
    id: "amountDollars",
    numeric: false,
    date: false,
    disablePadding: true,
    label: "Amount",
    visible: true
  }, {
    id: "paymentGroup",
    numeric: false,
    date: false,
    disablePadding: true,
    label: "Payment Group",
    visible: false
  },
];

function Sales(props) {
  const classes = useClasses(styles);
  const { state, updateState } = props;
  const [sales, setSales] = useState([]);
  const [rowStates, setRowStates] = useState([]);
  const [rowCount, setRowCount] = useState(sales.length || 0);
  const [showFilter, setShowFilter] = useState(props.filters.length > 0);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedSale, setSelectedSale] = useState(0);
  const [ headCells, setHeadCells ] = useState(headCellDefault);
  const [ globalSearch , setGlobalSearch ] = useState();
  const [filterable, setFilterable] = useState([
    {
      id: "id",
      label: "ID",
      type: "text",
      inUse: false,
    },
    {
      id: "vendorOrderNumber",
      label: "Vendor Order Number",
      type: "text",
      inUse: false,
    },
    {
      id: "clientName",
      label: "Client Name",
      type: "text",
      inUse: false,
    },
    {
      id: "region",
      context: {
        array: "countries",
        label: "country",
        value: "country",
        type: "string",
      },
      label: "Country",
      type: "select",
      inUse: false,
    },
    {
      id: "payee",
      context: {
        array: "vendors",
        label: "vendor",
        value: "vendor",
        type: "string",
      },
      label: "Payee",
      type: "select",
      inUse: false,
    },
    {
      id: "user",
      context: {
        array: "accountManagers",
        label: "name",
        value: "name",
        type: "string",
      },
      label: "Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "tableauAccountManager",
      context: {
        array: "tableauAccountManagers",
        label: "name",
        value: "name",
        type: "string",
      },
      label: "Tableau Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "alteryxAccountManager",
      context: {
        array: "alteryxAccountManagers",
        label: "name",
        value: "name",
        type: "string",
      },
      label: "Alteryx Account Manager",
      type: "select",
      inUse: false,
    },
    {
      id: "signed",
      label: "Not Signed?",
      type: "boolean",
      inUse: false,
    },
    {
      id: "selectPaymentGroup",
      label: "Select Regional Payment Group",
      type: "select",
      context: {
        array: "paymentGroups",
        label: "label",
        value: "paymentId",
        type: "string",
      },
      inUse: false,
    },
    {
      id: "searchPaymentGroup",
      label: "Search All Payment Groups",
      type: "text",
      inUse: false,
    },
  ]);

  useEffect(() => {
    let showPaymentGroups = false;
    props.filters.forEach((filter) => {
      if (filter.id === "selectPaymentGroup" || filter.id === "searchPaymentGroup") {
        showPaymentGroups = true;
      }
    });
    const curHeadCells = headCells.map((cell) => {
      if (cell.id  === "paymentGroup") {
        return {
          id: "paymentGroup",
          numeric: false,
          date: false,
          disablePadding: true,
          label: "Payment Group",
          visible: showPaymentGroups
        }
      }
      return cell;
    })
    setHeadCells(curHeadCells);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters])

  function handleUpdateRowCount(count) {
    setRowCount(count);
  }
  
  useSetUrlFilters(filterable, props.changeFilters);

  function handleRequestSort(event, property) {
    console.log(
      "[Sales.js] Sort Order",
      state.orderBy,
      property,
      state.order,
      state.orderBy === property && state.order === "desc"
    );
    const sameProperty = state.orderBy === property;
    const isAsc = state.order === "asc";
    updateState({
      ...state,
      order: sameProperty && isAsc ? "desc" : "asc",
      orderBy: property,
      page: 0,
    });
  }

  function handleRowClick(event, id, ref) {
    console.log("[Sales.js] handleRowClick", id);
    const source = event.target.type;
    console.log("[Sales.js] handleRowClick source", source);
    const rowStateIndex = rowStates.findIndex((r) => {
      return r.id === id;
    });
    let newRowStates = [];
	if (rowStateIndex === -1) {
      const state = {
        id,
        state: "read",
        checked: source === "checkbox",
      };
      console.log("[Sales.js] handleRowClick - newly selected row", state);
      newRowStates = newRowStates.concat(rowStates, state);
    } else if (
      rowStateIndex === 0 &&
      rowStates[rowStateIndex].state === "read"
    ) {
      console.log("[Sales.js] handleRowClick - remove first item in array");
      newRowStates = newRowStates.concat(rowStates.slice(1));
    } else if (
      rowStateIndex === rowStates.length - 1 &&
      rowStates[rowStateIndex].state === "read"
    ) {
      console.log("[Sales.js] handleRowClick - remove last item in array");
      newRowStates = newRowStates.concat(rowStates.slice(0, -1));
    } else if (rowStateIndex > 0 && rowStates[rowStateIndex].state === "read") {
      console.log("[Sales.js] handleRowClick - remove middle of array");
      newRowStates = newRowStates.concat(
        rowStates.slice(0, rowStateIndex),
        rowStates.slice(rowStateIndex + 1)
      );
    }
    console.log("[Sales.js] handleRowClick - new rowStates", newRowStates);
    setRowStates(newRowStates);
    if (source !== "checkbox") {
      setSelectedSale(id);
    }
  }

  function getRowStatus(id) {
    const selectedIndex = rowStates.findIndex((r) => {
      return r.id === id;
    });
    if (selectedIndex > -1) {
      return rowStates[selectedIndex];
    } else {
      return {
        id,
        state: "read",
        checked: false,
        msg: null,
      };
    }
  }

  function handleChangeRowState(id, state, msg) {
    console.log("[Sales.js] handleChangeRowState", id, state);
    const idx = rowStates.findIndex((r) => {
      return r.id === id;
    });
    const newStates = [...rowStates];
    newStates[idx].state = state;
    if (msg) {
      newStates[idx].msg = msg;
    } else {
      newStates[idx].msg = null;
    }
    setRowStates(newStates);
    console.log("[Sales.js] handleChangeRowState - New State", newStates);
  }

  function handleUpdateClient(client, name, value) {
    // console.log('[Sales.js] handleUpdateClient', client, name, value)
    const clientIndex = sales.findIndex((a) => {
      return a.id === client.id;
    });

    const account = sales[clientIndex];
    account[name] = value;

    const salesArr = [...sales];
    salesArr[clientIndex] = account;
    setSales(salesArr);
  }

  function handleChangePage(event, newPage) {
    updateState({
      ...state,
      page: newPage,
    });
  }

  function handleChangeRowsPerPage(event) {
    updateState({
      ...state,
      rowsPerPage: event.target.value,
      page: 0,
    });
  }

  function handleShowFilters() {
    setShowFilter(!showFilter);
  }

  function handleDeselectAll() {
    console.log("[Sales.js] handleDeselectAll");
    setRowStates([]);
  }

  function handleCloseSale() {
    if (rowStates.length === 1) {
      handleRowClick({target: {type: 'checkbox'}}, selectedSale);
    }
    setSelectedSale(null);
  }

  function handleGlobalSearch(query) {
    console.log("[Sales.js] handleGlobalSearch", query);
    if (query && query.length > 0) {
      setGlobalSearch(query);
    } else {
      setGlobalSearch(null);
    }
    updateState({
      ...state,
      page: 0,
    });
  }

  if (!props.salesAccess) {
    console.log('[Sales.js] No access to Sales page', props.salesAccess);
    return (
      <GeneralMessage type="error" message="No Sales Access"/>
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <TableActionsRow
					numSelected={rowStates.length}
					changeDisplayFilters={handleShowFilters}
					// archiveClients={() => archiveClients(rowStates)}
					deselectAll={handleDeselectAll}
          filterable={filterable}
          changeFilters={props.changeFilters}
          filters={props.filters}
          selectRecord={(id) => handleRowClick({target: {type: undefined}}, id)}
          updateGlobalSearch={(q) => handleGlobalSearch(q)}
				/>
        {(() => {
            if (tableLoading) {
              return <LinearProgress />;
            }
          })()}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHeaderRow
              classes={classes}
              numSelected={rowStates.length}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowCount}
              filter={showFilter}
              filters={props.filters}
              changeFilters={props.changeFilters}
              filterable={filterable}
              setFilterable={setFilterable}
              headCells={headCells}
            />
            <TableBodySales
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              rowStates={rowStates}
              getRowStatus={(id) => getRowStatus(id)}
              changeRowState={(id, state) => handleChangeRowState(id, state)}
              rowClick={handleRowClick}
              updateClient={(row, name, value) =>
                handleUpdateClient(row, name, value)
              }
              updateRowCount={(count) => handleUpdateRowCount(count)}
              changeLoading={(loading) => setTableLoading(loading)}
              order={state.order}
              orderBy={state.orderBy}
              filters={props.filters}
              globalSearch={globalSearch}
              headCells={headCells}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <SwipeableDrawer
        anchor={"right"}
        open={selectedSale ? true : false}
        onClose={() => handleCloseSale()}
        onOpen={() => console.log("[Sales.js] Drawer Open")}
        disableSwipeToOpen={true}
      >
        <Sale
          id={selectedSale}
          userProfile={props.auth0Profile} 
          close={() => handleCloseSale()}
          />
      </SwipeableDrawer>
    </>
  );
}

export default withAuthenticationRequired(Sales, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <GeneralMessage type="loading" message={"Checking you're logged in..."} />,
});