import React from "react";
import Icon from "react-icon-base";

const salesforceIcon = (props) => {
  return (
    <Icon viewBox="0 0 2500 1750" {...props}>
      <title>{props.title || "Salesforce"}</title>
      <g>
        <path
          d="M1040.4,190.9c80.6-84,192.9-136.1,317-136.1c165,0,309,92,385.7,228.6c66.6-29.8,140.4-46.3,218-46.3
		c297.6,0,539,243.4,539,543.7c0,300.3-241.3,543.7-539,543.7c-35.7,0-71.2-3.5-106.2-10.6c-67.5,120.4-196.2,201.8-343.9,201.8
		c-61.8,0-120.3-14.3-172.4-39.7c-68.4,161-227.9,273.9-413.8,273.9c-193.6,0-358.5-122.5-421.8-294.2c-27.7,5.9-56.3,8.9-85.8,8.9
		C186.8,1464.6,0,1275.9,0,1043C0,887,83.9,750.7,208.7,677.8c-25.7-59.1-40-124.3-40-192.8c0-267.8,217.4-484.9,485.6-484.9
		C811.7,0.1,951.6,74.9,1040.4,190.9"
        />
      </g>
    </Icon>
  );
};

export default salesforceIcon;