import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PrimaryIcon from "@mui/icons-material/HowToReg";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';
import frLocale from 'date-fns/locale/fr';
import { parseISO } from "date-fns";
import ClientsContext from "../../../../../../context/clients-context";
import UserContext from "../../../../../../context/user-context";
import AMAvatar from "../../../TableBodyClients/ClientRow/AMAvatar/AMAvater";
import EditField from "../../../../../Shared/EditField/EditField";

const classes = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return parseISO(year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3));
  } else {
    return null;
  }
}

const localeMap = {
  uk: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: '__/__/____',
  uk: '__/__/____',
  de: '__.__.____',
};

const momentFormat = {
  de: 'YYYY-MM-DD',
  fr: 'DD/MM/YYYY',
  uk: 'DD/MM/YYYY'
}

export default function AccountManager(props) {
  const [locale, setLocale] = useState('uk');
  const userContext = useContext(UserContext);
  const clientsContext = useContext(ClientsContext);

  useEffect(() => {
    if (userContext && userContext.attributes & userContext.attributes.scope) {
      if (userContext.attributes.scope === 'de') {
        setLocale('de');
      } 
      if (userContext.attributes.scope === 'fr') {
        setLocale('fr');
      } 
    }
  },[userContext])

  if (props.editMode) {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <Select
            variant="standard"
            value={props.am.amId}
            onChange={(e) => {
              const findAccountManager = clientsContext.accountManagers.filter(
                (el) => {
                  return el.amId === e.target.value;
                }
              );
              props.changeAccountManager({
                ...props.am,
                amId: e.target.value,
                name: findAccountManager[0].name,
                email: findAccountManager[0].email,
                photo: findAccountManager[0].photo,
              });
            }}
            inputProps={{
              name: "Name",
              id: "Email-simple",
            }}>
            <MenuItem disabled value={"0"} key={"0"}>
              Select Account Manager
            </MenuItem>
            {clientsContext.accountManagers.map((am, idx) => {
              return (
                <MenuItem value={am.amId} key={am.amId}>
                  {am.name}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            color="primary"
            checked={props.am.primaryOwner}
            value={props.am.primaryOwner}
            onChange={(e) =>
              props.changeAccountManager({
                ...props.am,
                primaryOwner: e.target.checked,
              })
            }
          />
        </TableCell>
        <TableCell align="right">
          <TextField
            variant="standard"
            id="standard-number"
            value={props.am.percentOwned * 100}
            onChange={(e) =>
              props.changeAccountManager({
                ...props.am,
                percentOwned: e.target.value ? e.target.value / 100 : 0,
              })
            }
            type="number"
            style={{ width: 70 }}
            className={classes.textField}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
        </TableCell>
        <TableCell align="right">
          <EditField
            type={"date"}
            value={
              props.am.fromDate
                ? props.am.fromDate
                : null
            }
            maxDate={
              props.am.toDate
                ? props.am.toDate
                : parseISO("2100-12-31")
            }
            updateValue={(value) => {
              props.changeAccountManager({
                ...props.am,
                fromDate: value ? value : "",
              });
            }}
            edit={true}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
            <DatePicker
              margin="normal"
              id="fromDate-date-picker"
              style={{ width: 130 }}
              format="yyyy-MM-dd"
              clearable
              maxDate={
                props.am.toDate
                  ? formatDate(parseInt(props.am.toDate))
                  : parseISO("2100-12-31")
              }
              value={
                props.am.fromDate
                  ? formatDate(parseInt(props.am.fromDate))
                  : null
              }
              onChange={(date) => {
                props.changeAccountManager({
                  ...props.am,
                  fromDate: date ? date.getTime().toString() : "",
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </LocalizationProvider> */}
        </TableCell>
        <TableCell align="right">
          <EditField
            type={"date"}
            value={
              props.am.toDate
                ? props.am.toDate
                : null
            }
            minDate={
              props.am.fromDate
                ? props.am.fromDate
                : parseISO("2000-01-01")
            }
            updateValue={(value) => {
              props.changeAccountManager({
                ...props.am,
                toDate: value ? value : "",
              });
            }}
            edit={true}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
            <DatePicker
              margin="normal"
              id="toDate-date-picker"
              style={{ width: 130 }}
              mask={maskMap[locale]}
              clearable
              minDate={
                props.am.fromDate
                  ? formatDate(parseInt(props.am.fromDate))
                  : parseISO("2000-01-01")
              }
              value={
                props.am.toDate ? formatDate(parseInt(props.am.toDate)) : null
              }
              onChange={(date) =>
                props.changeAccountManager({
                  ...props.am,
                  toDate: date ? date.getTime().toString() : "",
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </LocalizationProvider> */}
        </TableCell>
        <TableCell align="left">
          <Select
            variant="standard"
            value={props.am.scope}
            onChange={(e) => {
              props.changeAccountManager({
                ...props.am,
                scope: parseInt(e.target.value),
              });
            }}
            inputProps={{
              name: "Scope",
              id: "Email-simple",
            }}>
            {clientsContext.ownershipScope.map((sc, idx) => {
              return (
                <MenuItem value={sc.id} key={sc.id}>
                  {sc.name}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell style={{ width: 35, paddingRight: 5 }}>
          <AMAvatar am={props.am} />
        </TableCell>
        <TableCell component="th" scope="row">
          {props.am.name}
        </TableCell>
        <TableCell align="center">
          {(() => {
            if (props.am.primaryOwner) {
              return <PrimaryIcon />;
            }
          })()}
        </TableCell>
        <TableCell align="right">{props.am.percentOwned * 100}%</TableCell>
        <TableCell align="right">
          {(() => {
            if (props.am.fromDate) {
              return (
                <Moment format={momentFormat[locale]}>
                  {formatDate(parseInt(props.am.fromDate))}
                </Moment>
              );
            }
          })()}
        </TableCell>
        <TableCell align="right">
          {(() => {
            if (props.am.toDate) {
              return (
                <Moment format={momentFormat[locale]}>
                  {formatDate(parseInt(props.am.toDate))}
                </Moment>
              );
            }
          })()}
        </TableCell>
        <TableCell align="left">
          {clientsContext.ownershipScope.filter((scope) => parseInt(scope.id) === props.am.scope)[0]?.name}
        </TableCell>
      </TableRow>
    );
  }
}
