import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function Navigator(props) {
  const { open } = props;
  const theme = useTheme();

  const [menu, setMenu] = useState([
    {
      group: "Resources",
      items: [
        { type: 'external', label: "DS Rooms", link: "https://dsrooms.theinformationlab.io", icon: "voice_chat" },
        { type: 'external', label: "The Learning Hub", link: "https://learninghub.theinformationlab.io/", icon: "school" }
      ],
    },
  ]);

  useEffect(() => {
    const dynamicMenu = [];
    console.log('[Navigator.js] salesAccess', props.salesAccess);
    if (props.salesAccess) {
      dynamicMenu.push({
        group: "Sales Portal",
        items: [
          { type: 'internal', label: "Clients", link: "/clients", icon: "account_box" },
          { type: 'internal', label: "Sales", link: "/sales", icon: "view_list" },
        ],
      })
    }
    if (props.dsProfileAccess) {
      dynamicMenu.push({
        group: "Data School Central",
        items: [
          { type: 'internal', label: "Profile", link: "/dataschool", icon: "attribution" },
        ],
      })
    }
    dynamicMenu.push({
      group: "Resources",
      items: [
        { type: 'internal', label: "Email Signature", link: "/signature", icon: "contact_mail" },
        // { type: 'internal', label: "Office Locator", link: "/locator", icon: "person_pin" },
        { type: 'external', label: "DS Rooms", link: "https://dsrooms.theinformationlab.io", icon: "voice_chat" },
        { type: 'external', label: "The Learning Hub", link: "https://learninghub.theinformationlab.io/", icon: "school" }
      ],
    })
    setMenu(dynamicMenu);
  }, [props.salesAccess, props.dsProfileAccess])

  return (
    <Drawer
      variant="permanent"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={props.onClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <NavLink to={''} onClick={props.close}>
          {({ isActive }) => (
            <ListItem disablePadding sx={ {
                display: 'block'
              }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: isActive ? '#E66F1C' : null
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={'Home'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
        </NavLink>
        <Divider />
        {menu.map(({ group, items, app }) => (
          <React.Fragment key={group}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemText className='mt-2' sx={{ 
                '& .MuiTypography-root': {
                  fontSize: 14,
                  color: '#696969',
                  marginLeft: '12px'
                }
                }}>
                {open ? group : ' '}
              </ListItemText>
            </ListItem>
            {items.map(({ label: childId, icon, link, type }) => {
              if (type ==="external") {
                return (
                  <a
                    href={link}
                    key={childId}
                    onClick={props.close}
                  >
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={childId} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </a>
                )
              }
              return (
                <NavLink
                  to={link}
                  key={childId}
                  onClick={props.close}
                >
                  {({ isActive }) => (
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: isActive ? '#E66F1C' : null
                          }}
                        >
                          <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={childId} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </NavLink>
              )})}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Navigator;
