import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditField from "../../../../../Shared/EditField/EditField";
import ClientsContext from "../../../../../../context/clients-context";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "",
    editOnly: true,
  },
  { id: "typeId", numeric: false, disablePadding: true, label: "Type" },
  { id: "date", numeric: false, disablePadding: true, label: "Date" },
  { id: "outcomeId", numeric: false, disablePadding: true, label: "Outcome" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{
              display: !editMode && headCell.editOnly ? "none" : "table-cell",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={!props.addingNew ? createSortHandler(headCell.id) : null}
              style={{ whiteSpace: "nowrap" }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const classes = (theme) => ({
  root: {
    width: `calc(100% + 40px)`,
    padding: 0,
    marginLeft: -20,
    marginTop: -20,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  comment: {
    maxWidth: 340,
  },
  deleteBtn: {
    color: "rgba(0,0,0,0.2)",
    backgroundColor: "rgba(0,0,0,0.0)",
    height: 30,
    width: 30,
    minHeight: 20,
    lineHeight: "2.25rem",
    boxShadow: "none",
    marginTop: 5,
  },
  deleteIcon: {
    fontSize: "1rem",
  },
  tableCell: {
    paddingLeft: 0,
  },
});

export default function CheckInsTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(props.checkIns);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const clientsContext = useContext(ClientsContext);

  let typeSelect = [];

  if (clientsContext.checkInTypes) {
    typeSelect = clientsContext.checkInTypes.map((item, idx) => {
      return {
        id: item.id,
        value: item.label,
        label: item.label,
      };
    });
  }

  let outcomeSelect = [];

  if (clientsContext.checkInOutcomes) {
    outcomeSelect = clientsContext.checkInOutcomes.map((item, idx) => {
      return {
        id: item.id,
        value: item.outcome,
        label: item.outcome,
      };
    });
  }

  useEffect(
    () => {
      if (props.checkIns) {
        const activeRows = props.checkIns.filter((row, idx) => {
          return row.outcome !== "Completed";
        });
        const completedRows = props.checkIns.filter((row, idx) => {
          return row.outcome === "Completed";
        });
        const sortedRows = stableSort(activeRows, getSorting(order, orderBy));
        setRows([...sortedRows.concat(completedRows)]);
      }
    } /* eslint-disable */,
    [props.checkIns] /* eslint-enable */
  );

  useEffect(
    () => {
      if (props.addingNew) {
        setOrderBy("id");
        setOrder("desc");
        if (rows.length > rowsPerPage) {
          setPage(rows.length % rowsPerPage);
        }
      }
    } /* eslint-disable */,
    [props.addingNew] /* eslint-enable */
  );

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    const activeRows = rows.filter((row, idx) => {
      return row.outcome !== "Completed";
    });
    const completedRows = rows.filter((row, idx) => {
      return row.outcome === "Completed";
    });
    const sortedRows = stableSort(activeRows, getSorting(order, orderBy));
    setRows([...sortedRows.concat(completedRows)]);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleUpdateValue = (record, field, value) => {
    console.log("[CheckIns.js] Update Value", record, field, value);
    const updatedRecord = { ...record };
    updatedRecord[field] = value;
    console.log("[CheckIns.js] Updated record", updatedRecord);
    props.editCheckIn(updatedRecord);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            editMode={props.isEditing}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell style={{ display: "contents" }}>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        style={{ display: props.isEditing ? "unset" : "none" }}
                        onClick={() => props.deleteCheckIn(row.id)}
                        size="large">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <EditField
                        type={"select"}
                        value={row.type}
                        updateValue={(value) =>
                          handleUpdateValue(row, "type", value)
                        }
                        list={typeSelect}
                        edit={props.isEditing}
                        focus={true}
                        greyOut={row.outcome === "Completed"}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <EditField
                        type={"date"}
                        value={row.date}
                        updateValue={(value) =>
                          handleUpdateValue(row, "date", value)
                        }
                        edit={props.isEditing}
                        greyOut={row.outcome === "Completed"}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <EditField
                        type={"select"}
                        value={row.outcome}
                        updateValue={(value) =>
                          handleUpdateValue(row, "outcome", value)
                        }
                        list={outcomeSelect}
                        edit={props.isEditing}
                        focus={props.isEditing}
                        greyOut={row.outcome === "Completed"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
    </div>
  );
}
