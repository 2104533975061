import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const classes = (theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const ZoomEvents = (props) => {
  const [viz, setViz] = useState(null);
  const [curToken, setCurToken] = useState("");
  const [width, setWidth] = useState(getWidth());

  const vizRef = useRef(null);

  const tableauToken = gql`
    query TableauToken {
      getTableauJWT {
        jwt
        result
      }
    }
  `;

  const { data, refetch } = useQuery(tableauToken, {
    fetchPolicy: "no-cache",
  });

  function loadViz(token, domains) {
    console.log("[ZoomEvents.js] loadviz", token, domains);
    const showMobile = width <= 1050;
    let vizWidth = (width / 3) * 2;
    let vizHeight = 600;
    if (showMobile && width <= 600) {
      vizWidth = width - 80;
      vizHeight = 850;
    } else if (showMobile) {
      vizWidth = (width / 3) * 2;
      vizHeight = 800;
    }

    console.log(domains);

    setViz(
      <tableau-viz 
        ref={vizRef}
        id="tableauViz"       
        src={"https://tableauserver.theinformationlab.co.uk/t/til2/views/ZoomWebinars/EventAttendancePortalEmbed"}
        height={vizHeight}
        width={vizWidth}
        device={showMobile ? "phone" : "desktop"}
        hide-tabs={false}
        token={token}
        toolbar='hidden'
        >
          {domains && domains.length > 0 ? <viz-filter field="Participant Domain" value={domains}> </viz-filter> : null}
        </tableau-viz>
    );
  }

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurToken("");
    if (data && data.getTableauJWT && props.active && props.domains && props.domains.length > 0) {
      refetch();
    } else if (!props.active || !props.domains || props.domains.length < 1) {
      setViz(<p style={{marginLeft: 20}}>Set client email domain to show zoom event attendance</p>);
    }
    // eslint-disable-next-line
  }, [props.active]);

  useEffect(() => {
    if (data && data.getTableauJWT && data.getTableauJWT.jwt) {
      setCurToken(data.getTableauJWT.jwt);
    }
  }, [data]);

  useEffect(() => {
    if (props.active && curToken && curToken.length > 0 && props.domains.length > 0) {
      let domains = "";
      props.domains.forEach((element) => {
        if (domains.length > 0) {
          domains += ",";
        }
        domains += element.domain;
      });
      loadViz(curToken, domains);
    }
    return () => {
      setCurToken("");
    };
    // eslint-disable-next-line
  }, [curToken, props.domains]);

  return (
    <>
    <Helmet> 
        <script type="module" src="https://tableauserver.theinformationlab.co.uk/javascripts/api/tableau.embedding.3.1.0.min.js" async></script>
      </Helmet>
    <div
      classes={{ root: classes.root }}
      style={{ marginLeft: -30, paddingBottom: 30 }}
    >
      {viz}
    </div>
    </>
  );
};

ZoomEvents.propTypes = {
  // bla: PropTypes.string,
};

ZoomEvents.defaultProps = {
  // bla: 'test',
};

export default ZoomEvents;
