import React, { useState, useEffect, useContext } from "react";
import useClasses from '../../../../hooks/useClasses';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import Moment from "react-moment";
import { parseISO } from "date-fns";
import UserContext from "../../../../context/user-context";

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const year = date.getFullYear();
    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return parseISO(year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3));
  } else {
    return null;
  }
}

const localeMap = {
  uk: 'en-gb',
  fr: 'de',
  de: 'fr',
};

const maskMap = {
  fr: '__/__/____',
  uk: '__/__/____',
  de: '__.__.____',
};

const momentFormat = {
  de: 'YYYY-MM-DD',
  fr: 'DD/MM/YYYY',
  uk: 'DD/MM/YYYY'
}

export default function MySelectField(props) {
  const [value, setValue] = useState(props.value || "");
  const [locale, setLocale] = useState('uk');
  const userContext = useContext(UserContext);

  const styles = theme => ({
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  });

  useEffect(() => {
    if (userContext && userContext.attributes & userContext.attributes.scope) {
      if (userContext.attributes.scope === 'de') {
        setLocale('de');
      } 
      if (userContext.attributes.scope === 'fr') {
        setLocale('fr');
      } 
    }
  },[userContext])

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const classes = useClasses(styles);

  const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "6px 0px 10px 0px",
      width: props.style && props.style.width ? props.style.width : "100%",
      maxWidth:
        props.style && props.style.maxWidth ? props.style.maxWidth : "100%",
      textAlign:
        props.style && props.style.textAlign
          ? props.style.textAlign
          : "inherit",
    },
    autoFocus: props.focus ? true : false,
  };
  if (props.edit) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap[locale]} >
          <DatePicker
            margin="normal"
            style={{ width: 130 }}
            mask={maskMap[locale]}
            clearable
            minDate={
              props.minDate ? formatDate(parseInt(props.minDate)) : parseISO("2010-01-01")
            }
            maxDate={
              props.maxDate ? formatDate(parseInt(props.maxDate)) : parseISO("2100-01-01")
            }
            value={value ? formatDate(parseInt(value)) : null}
            onChange={(date) => {
              if (date) {
                setValue(parseISO(date.toDate().getTime().toString()));
                props.updateValue(date.toDate().getTime().toString());
              } else {
                setValue(null);
                props.updateValue(null);
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            inputProps={inputProps}
            classes={classes}
            renderInput={(params) => <TextField variant="standard" {...params} />}
          />
        </LocalizationProvider>
      </span>
    );
  } else {
    if (props.value) {
      return (
        <Moment format={momentFormat[locale]} style={{ ...props.style }}>
          {formatDate(parseInt(props.value))}
        </Moment>
      );
    } else {
      return "";
    }
  }
}
