import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import TagSelector from "./Tags/Tags";
import EmailDomains from "./EmailDomains/EmailDomains";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const domainTagsMutation = gql`
  mutation DomainsTags($id: ID, $domains: [String], $tags: [String]) {
    updateDomains(input: { id: $id, domains: $domains }) {
      domain
      lastContacted
    }
    updateTags(input: { id: $id, tags: $tags }) {
      tag
    }
  }
`;

const classes = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
  card: {
    cursor: "pointer",
  },
  sectionTitle: {
    color: "#6f6f6f",
    flexGrow: 1,
  },
  edit: {
    marginTop: -5,
  },
  textField: {
    marginTop: 0,
  },
  navBtn: {
    color: "rgba(0, 0, 0, 0.34)",
    marginLeft: 5,
    marginBottom: 2,
  },
  navIco: {
    height: 15,
    width: 15,
  },
  toolButtons: {
    marginBottom: 10,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
  errorIcon: {
    color: "#e54900",
  },
});

export default function DomainsTags(props) {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [client, setClient] = useState(props.client);
  const [loadedClient, setLoadedClient] = useState(props.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setClient({ ...props.client });
    setLoadedClient({ ...props.client });
  }, [props.client]);

  React.useEffect(() => {
    const retDomains = [];
    const clientDomains = [...client.domains];
    if (!isEditing) {
      for (let i = 0; i < clientDomains.length; i += 1) {
        if (clientDomains[i].domain.trim() !== "") {
          retDomains[i] = {
            domain: clientDomains[i].domain.trim()
          }
        }
      }
      if (
        retDomains &&
        retDomains[retDomains.length - 1] &&
        retDomains[retDomains.length - 1].domain &&
        retDomains[retDomains.length - 1].domain === ""
      ) {
        retDomains.pop();
      }
      // props.updateClient({...props.client, domains : retDomains, tags: tags})
      setClient({ ...client, domains: retDomains });
    } else {
      clientDomains.push({ domain: "" });
      setClient({ ...client, domains: clientDomains });
    }
    /* eslint-disable */
  }, [isEditing]); /* eslint-enable */

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setClient({ ...loadedClient });
  }

  function compareDomains(a, b) {
    if (a.domain < b.domain) {
      return 1;
    }
    if (a.domain > b.domain) {
      return -1;
    }
    return 0;
  }

  function handleChangeDomain(idx, domain) {
    const retDomains = [...client.domains];
    retDomains[idx] = { domain: domain };
    if (idx === client.domains.length - 1) {
      retDomains.push({ domain: "" });
    }
    retDomains.sort(compareDomains);
    setClient({ ...client, domains: retDomains });
  }

  function handleDeleteDomain(idx) {
    console.log(client.domains, idx);
    const retDomains = [...client.domains];
    retDomains.splice(idx, 1);
    setClient({ ...client, domains: retDomains });
  }

  function handleAddDomain() {
    const retDomains = [...client.domains];
    retDomains.push({ domain: "" });
    setClient({ ...client, domains: retDomains });
  }

  function handleChangeTags(tags) {
    setClient({ ...client, tags: tags });
  }

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  const headerStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
  };

  const [
    saveDomainTags,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(domainTagsMutation, {
    update: (store, { data: { updateDomains, updateTags }, error }) => {
      console.log("[DomainsTags.js] graphql update", updateDomains, updateTags);
      setClient({
        ...client,
        domains: updateDomains,
        tags: updateTags,
      });
    },
    onError: (error) => {
      console.error("[DomainsTags.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (loading && !mutationLoading) {
      if (!mutationError) {
        console.log("[DomainsTags.js] Mutation Complete");
        setError(null);
        setIsEditing(false);
        setLoading(false);
      } else {
        console.log("[DomainsTags.js] Mutation Error");
        setIsEditing(true);
        setLoading(false);
      }
    } else if (!loading && mutationLoading) {
      console.log("[DomainsTags.js] Saving Mutation");
      setError(null);
      setIsEditing(true);
      setLoading(true);
    } /* eslint-disable */
  }, [mutationLoading, mutationError]); /* eslint-enable */

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={"Domains & Tags"}
      />
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Email Domains
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                <EmailDomains
                  editMode={isEditing}
                  domains={client.domains}
                  changeDomain={handleChangeDomain}
                  deleteDomain={handleDeleteDomain}
                  addDomain={handleAddDomain}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Tags
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                <TagSelector
                  tags={client.tags}
                  editMode={isEditing}
                  changeTags={handleChangeTags}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: loading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              const cleanDomains = client.domains.filter((obj) => {
                return obj.domain && obj.domain.length > 0;
              });
              saveDomainTags({
                variables: {
                  id: parseInt(client.id),
                  domains:
                    cleanDomains.length > 0
                      ? cleanDomains.map((obj) => {
                          return obj.domain;
                        })
                      : [],
                  tags:
                    client.tags.length > 0
                      ? client.tags.map((obj) => {
                          return obj.tag;
                        })
                      : [],
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
}
