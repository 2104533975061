import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "../../../../Shared/TextField/TextField";
import Tooltip, { tooltipClasses }  from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// import LocationMap from "./LocationMap/LocationMap";
import NavigationIcon from "@mui/icons-material/Navigation";
// import HelpIcon from "@mui/icons-material/Help";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";
import GDriveIcon from "../../../../../icons/gdrive";
import ConvoIcon from "../../../../../icons/convo";
import SalesforceIcon from "../../../../../icons/salesforce";
import AWSIcon from "../../../../../icons/aws";
import ClientsContext from "../../../../../context/clients-context";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import EditField from "../../../../Shared/EditField/EditField";

const clientMutation = gql`
  mutation Client(
    $id: ID
    $name: String
    $reportingName: String
    $sector: String
    $accountType: String
    $postcode: String
    $latitude: Float
    $longitude: Float
    $location: String
    $postcodeVerified: Boolean
    $cmmLevel: Int
    $cmmRanking: String
    $clientDiaryUrl: String
    $sfdcCustomer: Boolean
    $awsCustomer: Boolean
    $endConversion: String
  ) {
    updateClient(
      input: {
        id: $id
        name: $name
        reportingName: $reportingName
        sector: $sector
        accountType: $accountType
        postcode: $postcode
        latitude: $latitude
        longitude: $longitude
        location: $location
        postcodeVerified: $postcodeVerified
        cmmLevel: $cmmLevel
        cmmRanking: $cmmRanking
        clientDiaryUrl: $clientDiaryUrl
        sfdcCustomer: $sfdcCustomer
        awsCustomer: $awsCustomer
        endConversion: $endConversion
      }
    ) {
      id
      name
      reportingName
      sector
      country
      accountType
      postcode
      latitude
      longitude
      location
      postcodeVerified
      cmmLevel
      cmmRanking
      clientDiaryUrl
      sfdcCustomer
      awsCustomer
      endConversion
    }
  }
`;

const classes = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
  card: {
    marginTop: 10,
    cursor: "pointer",
  },
  sectionTitle: {
    color: "#6f6f6f",
    flexGrow: 1,
  },
  edit: {
    marginTop: -5,
  },
  textField: {
    marginTop: 0,
  },
  navBtn: {
    color: "rgba(0, 0, 0, 0.34)",
    marginLeft: 5,
    marginBottom: 2,
  },
  navIco: {
    height: 15,
    width: 15,
  },
  helpIco: {
    height: 20,
    width: 20,
  },
  avatar: {
    fontSize: 14,
    backgroundColor: "#18202c",
    border: 0.5,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  postcodeWarning: {
    color: "#f18746",
    marginLeft: 10,
    fontSize: "1.2rem",
  },
  verifiedLabel: {
    fontSize: 14,
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeadAction: {
    paddingTop: 15,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
  input: {
    fontSize: 14
  }
});

export default function CompanyInfo(props) {
  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [client, setClient] = useState(props.client);
  const [loadedClient, setLoadedClient] = useState(props.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const clientsContext = useContext(ClientsContext);

  useEffect(() => {
    setClient({ ...props.client });
    setLoadedClient({ ...props.client });
  }, [props.client]);

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  const headerStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
  };

  const subTextStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
  };

  // const MapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     maxWidth: 500,
  //     backgroundColor: "#f5f5f9",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     width: 400,
  //     height: 160,
  //     border: "1px solid #dadde9",
  //   },
  // });

  // function BootstrapTooltip(props) {
  //   return <Tooltip placement="right" arrow classes={tooltipClasses} {...props} />;
  // }

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setClient({ ...loadedClient });
  }

  const geocodePostcode = (search) => {
    return axios.get(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        encodeURI(search) +
        ".json?access_token=pk.eyJ1IjoiaW5mb2xhYnVrLWRldiIsImEiOiI2d2dEb2w0In0.gvFxiOLO9jxBMlFn-xeCZw&autocomplete=false&types=postcode&limit=1"
    );
  };

  function updateClient(name, value) {
    console.log("[CompanyInfo.js] Update client", name, value);
    const clientObj = { ...client };
    if (name === "postcode" && value.length > 4) {
      geocodePostcode(value).then((resp) => {
        if (
          resp.data &&
          resp.data.features &&
          resp.data.features[0] &&
          resp.data.features[0].center
        ) {
          console.log("[Clients.js] Postcode Geocode", resp.data);
          const point = resp.data.features[0].center;
          const context = resp.data.features[0].context;
          clientObj.latitude = point[1];
          clientObj.longitude = point[0];
          clientObj.location = context[0].text + ", " + context[1].text;
        } else {
          clientObj.latitude = null;
          clientObj.longitude = null;
          clientObj.location = "Unkown Postcode";
        }
        clientObj.postcode = value;
        setClient(clientObj);
      });
    } else {
      clientObj[name] = value;
      setClient(clientObj);
    }
  }

  const [
    saveClient,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(clientMutation, {
    update: (store, { data: { updateClient }, error }) => {
      console.log("[CompanyInfo.js] graphql update", updateClient);
      if (
        clientsContext.sectors.findIndex((sector) => {
          return sector.sector === client.sector;
        }) === -1
      ) {
        console.log("[CompanyInfo.js] Add sector to context", client.sector);
        addSector(client.sector);
      }
      setClient({
        ...client,
        updateClient,
      });
    },
    onError: (error) => {
      console.error("[CompanyInfo.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (loading && !mutationLoading) {
      if (!mutationError) {
        console.log("[CompanyInfo.js] Mutation Complete");
        setError(null);
        setIsEditing(false);
        setLoading(false);
      } else {
        console.log("[CompanyInfo.js] Mutation Error");
        setIsEditing(true);
        setLoading(false);
      }
    } else if (!loading && mutationLoading) {
      console.log("[CompanyInfo.js] Saving Mutation");
      setError(null);
      setIsEditing(true);
      setLoading(true);
    } /* eslint-disable */
  }, [mutationLoading, mutationError]); /* eslint-enable */

  function compare(a, b) {
    if (a.sector < b.sector) {
      return -1;
    }
    if (a.sector > b.sector) {
      return 1;
    }
    return 0;
  }

  function addSector(sector) {
    console.log("[CompanyInfo.js] clientsContext", clientsContext);
    if (clientsContext.updateContext) {
      const sectors = [...clientsContext.sectors];
      sectors.push({ sector });
      clientsContext.updateContext("sectors", sectors.sort(compare));
      console.log("[CompanyInfo.js] New Sector Added", clientsContext.sectors);
    }
  }

  function externalRef() {
    let appName = "";
    if (client.externalRef && client.externalRef.indexOf("freeagent") > -1) {
      appName = "FreeAgent";
    }
    const freeagentReplacements = {
      UK: "theinformationlab.freeagent.com",
      DE: "theinformationlabdeutschlandgmbh.freeagent.com",
      IE: "theinformationlab.freeagent.com",
      FR: "theinformationlab.freeagent.com",
      NL: "theinformationlab.freeagent.com",
      LU: "theinformationlab.freeagent.com",
      IT: "theinformationlab.freeagent.com",
      ES: "theinformationlab.freeagent.com",
    };
    switch (appName) {
      case "FreeAgent":
        const url = client.externalRef.replace(
          "api.freeagent.com/v2",
          freeagentReplacements[client.country]
        );
        return (
          <div>
            <Link
              href={url}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              FreeAgent
            </Link>
            : {client.externalRef}
          </div>
        );
      default:
        return null;
    }
  }

  function clientDiary() {
    let appName = "";
    if (isEditing) {
      return (
        <TextField
          value={client.clientDiaryUrl}
          state={"edit"}
          updateValue={(value) => updateClient("clientDiaryUrl", value)}
          style={{ marginTop: 0 }}
          fullWidth={true}
        />
      );
    } else if (
      client.clientDiaryUrl &&
      client.clientDiaryUrl.indexOf("convo") > -1
    ) {
      appName = "Convo";
    } else if (
      client.clientDiaryUrl &&
      client.clientDiaryUrl.indexOf("google.com") > -1
    ) {
      appName = "Drive";
    }
    switch (appName) {
      case "Convo":
        return (
          <div>
            <Link
              href={client.clientDiaryUrl}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <ConvoIcon title="Convo" /> Convo Post
            </Link>
          </div>
        );
      case "Drive":
        return (
          <div>
            <Link
              href={client.clientDiaryUrl}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <GDriveIcon title="Google Drive" /> Google Drive Doc
            </Link>
          </div>
        );
      default:
        return null;
    }
  }

  function crmLink() {
    let appName = "Capsule";
    if (isEditing) {
      return (
        <TextField
          value={client.capsuleId}
          state={"edit"}
          updateValue={(value) => updateClient("capsuleId", value)}
          style={{ marginTop: 0 }}
          fullWidth={true}
        />
      );
    }
    switch (appName) {
      case "Capsule":
        if (client.capsuleId) {
          return (
            <div>
              <Link
                href={"https://theinformationlab.capsulecrm.com/party/" + client.capsuleId}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
              >
                Capsule
              </Link>
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  }

  function verifyOrNav() {
    if (client.postcodeVerified && !isEditing) {
      return (
        <IconButton
          aria-label="email"
          size="small"
          className={classes.navBtn}
          href={
            "https://www.google.com/maps/dir/?api=1&destination=" +
            client.postcode
          }
          rel="noopener"
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <NavigationIcon className={classes.navIco} />
        </IconButton>
      );
    } else if (!client.postcodeVerified && !isEditing) {
      return (
        <Tooltip title="Please check postcode">
          <WarningIcon className={classes.postcodeWarning} />
        </Tooltip>
      );
    } else if (isEditing) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={client.postcodeVerified ? true : false}
              onClick={() =>
                updateClient(
                  "postcodeVerified",
                  client.postcodeVerified ? false : true
                )
              }
            />
          }
          label="Verified"
          labelPlacement="end"
          style={{ marginLeft: 10 }}
          classes={{ label: classes.verifiedLabel }}
        />
      );
    }
    return null;
  }

  function companyAvatar() {
    if (
      client &&
      client.domains &&
      client.domains[0] &&
      client.domains[0].domain
    ) {
      return (
        <Avatar
          alt={client.name}
          src={`https://logo.clearbit.com/${client.domains[0].domain}`}
          className={classes.avatar}
        />
      );
    }
    return (
      <Avatar
        alt={client && client.name ? client.name : ""}
        className={classes.avatar}
      >
        {client && client.name
          ? client.name
              .split(" ")
              .map((n) => n[0])
              .join("")
          : ""}
      </Avatar>
    );
  }

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        avatar={companyAvatar()}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={client.name}
      />
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          <Grid item xs={12} sm={12}>
            <div style={{ display: "flex", paddingTop: 20 }}>
              <Typography variant="subtitle1" className={classes.sectionTitle}>
                Company Info
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Reporting Name
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                {(() => {
                  if (isEditing) {
                    return (
                      <TextField
                        value={client.reportingName}
                        state={"edit"}
                        updateValue={(value) =>
                          updateClient("reportingName", value)
                        }
                        style={{ marginTop: 0 }}
                        fullWidth={true}
                      />
                    );
                  } else {
                    return client.reportingName;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Sector
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                {(() => {
                  const hideDropdown =
                    client.sector &&
                    (client.sector === "Other" ||
                      clientsContext.sectors.findIndex((s) => {
                        return s.sector === client.sector;
                      }) < 0) &&
                    client.sector !== "";

                  if (isEditing) {
                    return (
                      <span>
                        <FormControl size="small" className={classes.formControl}>
                          <Select
                            variant="standard"
                            value={client.sector || ""}
                            onChange={(e) => {
                              updateClient("sector", e.target.value);
                            }}
                            style={{ display: hideDropdown ? "none" : "unset", fontSize: 14 }}
                            inputProps={{
                              name: "sector",
                              id: "sector-simple",
                            }}>
                            {clientsContext.sectors.map((item) => {
                              return (
                                <MenuItem key={item.sector} value={item.sector}>
                                  {item.sector}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value={"Other"}>Other</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl variant="standard" className={classes.formControl}>
                          <TextField
                            value={client.sector}
                            state={"edit"}
                            updateValue={(value) =>
                              updateClient("sector", value)
                            }
                            hidden={!hideDropdown}
                            fullWidth={true}
                          />
                        </FormControl>
                      </span>
                    );
                  } else {
                    return client.sector;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Account Type
              </Grid>
              <Grid item xs={12} sm={12}>
                {(() => {
                  if (isEditing) {
                    return (
                      <Select
                        variant="standard"
                        value={client.accountType || ""}
                        onChange={(event) =>
                          updateClient("accountType", event.target.value)
                        }
                        style={{ fontSize: 14 }}
                        className={classes.selectEmpty}>
                        <MenuItem value="" disabled>
                          Select Account Type
                        </MenuItem>
                        <MenuItem value="Commercial">Commercial</MenuItem>
                        <MenuItem value="Enterprise">Enterprise</MenuItem>
                      </Select>
                    );
                  } else {
                    return client.accountType;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: client.sfdcCustomer || isEditing ? "block" : "none",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12}>
                {(() => {
                  if (isEditing) {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={client.sfdcCustomer ? true : false}
                            onClick={() =>
                              updateClient(
                                "sfdcCustomer",
                                client.sfdcCustomer ? false : true
                              )
                            }
                          />
                        }
                        label={"Salesforce Customer"}
                        labelPlacement="end"
                        style={{ fontSize: 14 }}
                      />
                    );
                  } else if (client.sfdcCustomer) {
                    return (
                      <div className="flex">
                        <SalesforceIcon
                          title="Salesforce"
                          style={{
                            color: "#00A1E0",
                            width: 25,
                            height: 25,
                            marginRight: 10,
                          }}
                        />{" "}
                        Salesforce Customer
                      </div>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: client.awsCustomer || isEditing ? "block" : "none",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12}>
                {(() => {
                  if (isEditing) {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={client.awsCustomer ? true : false}
                            onClick={() =>
                              updateClient(
                                "awsCustomer",
                                client.awsCustomer ? false : true
                              )
                            }
                          />
                        }
                        label={"AWS Customer"}
                        labelPlacement="end"
                        style={{ fontSize: 14 }}
                      />
                    );
                  } else if (client.awsCustomer) {
                    return (
                      <div className="flex">
                        <AWSIcon
                          title="AWS"
                          style={{
                            color: "#FF9900",
                            width: 25,
                            height: 25,
                            marginRight: 10,
                          }}
                        />{" "}
                        AWS Customer
                      </div>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Postcode
              </Grid>
              <Grid item xs={12} sm={12}>
                {(() => {
                  if (isEditing) {
                    return (
                      <FormControl variant="standard" component="fieldset">
                        <FormGroup
                          aria-label="position"
                          style={{ display: "table" }}
                          row
                        >
                          <TextField
                            value={client.postcode}
                            state={"edit"}
                            updateValue={(value) =>
                              updateClient("postcode", value)
                            }
                            style={{
                              marginTop: 0,
                              width: 80,
                              display: "table-cell",
                            }}
                          />
                          {verifyOrNav()}
                        </FormGroup>
                      </FormControl>
                    );
                  } else {
                    if (
                      client.postcode &&
                      client.latitude &&
                      client.longitude
                    ) {
                      return (
                        <div style={{ display: "flex" }}>
                          {/* <MapTooltip
                            title={
                              <LocationMap
                                latitude={client.latitude}
                                longitude={client.longitude}
                              ></LocationMap>
                            }
                          >
                            <Typography
                              color="inherit"
                              style={{ fontSize: "0.875rem" }}
                            >
                              {client.postcode}
                            </Typography>
                          </MapTooltip>
                          {verifyOrNav()} */}
                        </div>
                      );
                    } else if (client.postcode) {
                      return (
                        <div style={{ display: "flex" }}>
                          <Typography
                            color="inherit"
                            style={{ fontSize: "0.875rem" }}
                          >
                            {client.postcode}
                          </Typography>
                          {verifyOrNav()}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                })()}
              </Grid>
              <Grid item style={subTextStyle} xs={12} sm={12}>
                {client.location}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                Client Diary
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                {clientDiary()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                External Reference
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                {externalRef()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                CRM Record
              </Grid>
              <Grid item style={headerStyle} xs={12} sm={12}>
                {crmLink()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item style={headerStyle} xs={12} sm={12}>
                End Conversion Date
              </Grid>
              <Grid item xs={12} sm={12}>
                <EditField
                  type="date"
                  value={client.endConversion}
                  edit={isEditing}
                  updateValue={(value) =>
                    updateClient("endConversion", value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{ display: "flex", paddingTop: 20 }}>
              <Typography variant="subtitle1" className={classes.sectionTitle}>
                Customer Categories
              </Typography>
              {/* <BootstrapTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="h6">
                      Customer Ranking
                    </Typography>
                    <b>{"A"}</b>
                    {
                      " - High value & high potential, low value high potential value"
                    }
                    <br />
                    <b>{"B"}</b>
                    {" - High value low potential (maintain)"}
                    <br />
                    <b>{"C"}</b>
                    {" - Low value, high potential (move to A)"}
                    <br />
                    <Typography color="inherit" variant="h6">
                      Maturity Level
                    </Typography>
                    <b>{"0 Base Camp"}</b>
                    {
                      " - Only the basics: key contacts, customer aware of products and services offered by TIL."
                    }
                    <br />
                    <b>{"1 Ben Nevis"}</b>
                    {
                      " - Basics +: key roles and use cases known; customer aware of events, COE, support."
                    }
                    <br />
                    <b>{"2 K2"}</b>
                    {
                      " - Strong and stable: TIL has a range of contacts; events, COE and support utilised."
                    }
                    <br />
                    <b>{"3 Everest"}</b>
                    {
                      " - Trailblazer: many conversations happening in different parts of business. Promotor of TIL."
                    }
                    <br />
                  </React.Fragment>
                }
              >
                <IconButton
                  aria-label="email"
                  size="small"
                  className={classes.navBtn}
                >
                  <HelpIcon className={classes.helpIco} />
                </IconButton>
              </BootstrapTooltip> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid item style={headerStyle} xs={12} md={12}>
                  Ranking
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(() => {
                    if (isEditing) {
                      return (
                        <Select
                          variant="standard"
                          value={client.cmmRanking || ""}
                          onChange={(event) =>
                            updateClient("cmmRanking", event.target.value)
                          }
                          className={classes.selectEmpty}>
                          <MenuItem value="" disabled>
                            Select Ranking
                          </MenuItem>
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="C">C</MenuItem>
                        </Select>
                      );
                    } else {
                      return client.cmmRanking;
                    }
                  })()}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item style={headerStyle} xs={12} md={12}>
                  Opportunity
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(() => {
                    if (isEditing) {
                      return (
                        <Select
                          variant="standard"
                          value={
                            client.cmmLevel ? client.cmmLevel.toString() : "0"
                          }
                          onChange={(event) =>
                            updateClient(
                              "cmmLevel",
                              parseInt(event.target.value)
                            )
                          }
                          className={classes.selectEmpty}>
                          <MenuItem value="0">0 - Low</MenuItem>
                          <MenuItem value="1">1 - Medium</MenuItem>
                          <MenuItem value="2">2 - High</MenuItem>
                          <MenuItem value="3">3 - Significant</MenuItem>
                        </Select>
                      );
                    } else {
                      let ret;
                      if (parseInt(client.cmmLevel)) {
                        switch (parseInt(client.cmmLevel)) {
                          case 0:
                            ret = "0 - Low";
                            break;
                          case 1:
                            ret = "1 - Medium";
                            break;
                          case 2:
                            ret = "2 - High";
                            break;
                          case 3:
                            ret = "3 - Significant";
                            break;
                          default:
                            ret = "0 - Low";
                        }
                      } else {
                        ret = "0 - Low";
                      }
                      return ret;
                    }
                  })()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: loading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              saveClient({
                variables: {
                  id: client.id,
                  name: client.name,
                  reportingName: client.reportingName,
                  sector: client.sector,
                  accountType: client.accountType,
                  postcode: client.postcode,
                  postcodeVerified: client.postcodeVerified,
                  latitude: client.latitude,
                  longitude: client.longitude,
                  location: client.location,
                  cmmLevel: client.cmmLevel,
                  cmmRanking: client.cmmRanking,
                  clientDiaryUrl: client.clientDiaryUrl,
                  sfdcCustomer: client.sfdcCustomer,
                  awsCustomer: client.awsCustomer,
                  endConversion: client.endConversion
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
    </Card>
  );
}
