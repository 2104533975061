import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Checkbox from '@mui/material/Checkbox';
import EditField from "../../../../../../Shared/EditField/EditField";
import ClientsContext from "../../../../../../../context/clients-context";
import CreateEstimate from "../../CreateEstimate";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "",
    editOnly: true,
  },
  {
    id: "softwareProduct",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  { id: "qty", numeric: false, disablePadding: true, label: "Qty" },
  {
    id: "originalOrderId",
    numeric: false,
    disablePadding: true,
    label: "Orig Order ID",
  },
  {
    id: "renewalDate",
    numeric: false,
    disablePadding: true,
    label: "Renewal Date",
  },
  {
    id: "renewalYear",
    numeric: true,
    disablePadding: true,
    label: "Renewal Year",
  },
  {
    id: "contractLength",
    numeric: true,
    disablePadding: true,
    label: "Contract Length",
  },
  { id: "comment", numeric: false, disablePadding: false, label: "Comment" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{
              display: !editMode && headCell.editOnly ? "none" : "table-cell",
              width: 82
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "actions" ? 
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={!props.addingNew ? createSortHandler(headCell.id) : null}
                style={{ whiteSpace: "nowrap" }}
              >
                {headCell.label}
              </TableSortLabel>
            : <>
              <IconButton
                aria-label="createEstimate"
                className={classes.margin}
                style={{ display: props.showEstimate ? "unset" : "none" }}
                onClick={() => props.createEstimate()}
                size="large">
                <PostAddIcon fontSize="small" />
              </IconButton>
            </> }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  showEstimate: PropTypes.bool.isRequired
};

const classes = (theme) => ({
  root: {
    width: `calc(100% + 40px)`,
    padding: 0,
    marginLeft: -20,
    marginTop: -20,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  comment: {
    maxWidth: 340,
  },
  deleteBtn: {
    color: "rgba(0,0,0,0.2)",
    backgroundColor: "rgba(0,0,0,0.0)",
    height: 30,
    width: 30,
    minHeight: 20,
    lineHeight: "2.25rem",
    boxShadow: "none",
    marginTop: 5,
  },
  deleteIcon: {
    fontSize: "1rem",
  },
});

const orderIdLinks = {
  UK: "https://sales.theinformationlab.co.uk/sales?id=",
  DE: "https://theinformationlabdeutschlandgmbh.freeagent.com/invoices/",
  IE: "https://sales.theinformationlab.co.uk/sales?id=",
  FR: "https://sales.theinformationlab.co.uk/sales?id=",
  NL: "https://sales.theinformationlab.co.uk/sales?id=",
  LU: "https://sales.theinformationlab.co.uk/sales?id=",
  IT: "https://sales.theinformationlab.co.uk/sales?id=",
  ES: "https://sales.theinformationlab.co.uk/sales?id=",
};

export default function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("renewalDate");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(props.renewals);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showCreateEstimate, setShowCreateEstimate] = useState(false);
  const clientsContext = useContext(ClientsContext);

  let productSelect = [];

  if (clientsContext.software) {
    productSelect = clientsContext.software
      .filter((item, idx) => {
        return item.vendor === props.vendor;
      })
      .map((item, idx) => {
        return {
          id: item.id,
          value: item.product,
          label: item.product,
        };
      });
  }


  useEffect(() => {
    if (props.renewals) {
      setRows(props.renewals);
    }
  }, [props.renewals]);

  useEffect(
    () => {
      if (props.addingNew) {
        setOrderBy("id");
        setOrder("desc");
        if (rows.length > rowsPerPage) {
          setPage((rows.length - (rows.length % rowsPerPage))/10);
        }
      }
    } /* eslint-disable */,
    [props.addingNew] /* eslint-enable */
  );

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowSelect = (event, record) => {
    const newSelected = [...selected];
    if (event.target.checked) {
      newSelected.push(record);
    } else {
      const idx = newSelected.findIndex(r => {
        return r.id === record.id;
      });
      if (idx > -1) {
        newSelected.splice(idx, 1);
      }
    }
    setSelected(newSelected);
  }

  const handleUpdateValue = (record, field, value) => {
    console.log("[RenewalsTable.js] Update Value", record, field, value);
    console.log("[RenewalsTable.js] productSelect", productSelect);
    const updatedRecord = { ...record };
    updatedRecord[field] = value;
    if (field === "softwareProduct") {
      const product = productSelect.find((p, idx) => {
        return p.value === value;
      });
      updatedRecord["softwareId"] = parseInt(product.id);
    }
    console.log("[RenewalsTable.js] Updated record", updatedRecord);
    props.editRenewal(updatedRecord);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const isSelected = (id) => {
    const row = selected.find(r => {
      return r.id === id;
    });
    return row !== undefined;
  }

  return (
    <div className={classes.root}>
      <CreateEstimate
        isOpen={showCreateEstimate}
        handleClose={() => setShowCreateEstimate(false)}
        items={selected}
        sector={props.sector}
        auth0Profile={props.auth0Profile}
        refreshAuth0={props.refreshAuth0}
        clientId={props.externalRef}
      />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            editMode={props.isEditing}
            showEstimate={selected.length > 0}
            createEstimate={() => setShowCreateEstimate(true)}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const hrefId = row.originalOrderId ? row.originalOrderId.replaceAll(',','%2C') : '';
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell style={{ display: "contents" }}>
                      <Checkbox
                        color="primary"
                        style={{ display: props.isEditing ? "unset" : "none" }}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        onClick={(event) => handleRowSelect(event, row)}
                      />
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        style={{ display: props.isEditing ? "unset" : "none" }}
                        onClick={(event) => {
                          handleRowSelect(event, row);
                          props.deleteRenewal(row.id);
                        }}
                        size="large">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <EditField
                        type={"select"}
                        value={row.softwareProduct}
                        updateValue={(value) =>
                          handleUpdateValue(row, "softwareProduct", value)
                        }
                        list={productSelect}
                        edit={props.isEditing}
                        focus={true}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <EditField
                        type={"number"}
                        value={row.qty}
                        maxWidth={50}
                        width={50}
                        updateValue={(value) =>
                          handleUpdateValue(row, "qty", parseInt(value))
                        }
                        edit={props.isEditing}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <EditField
                        type={"text"}
                        value={row.originalOrderId}
                        updateValue={(value) =>
                          handleUpdateValue(
                            row,
                            "originalOrderId",
                            value
                          )
                        }
                        edit={props.isEditing}
                        href={ orderIdLinks[props.country] + hrefId}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <EditField
                        type={"date"}
                        value={row.renewalDate}
                        updateValue={(value) =>
                          handleUpdateValue(row, "renewalDate", value)
                        }
                        edit={props.isEditing}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <EditField
                        type={"number"}
                        value={row.renewalYear}
                        maxWidth={30}
                        width={30}
                        updateValue={(value) =>
                          handleUpdateValue(row, "renewalYear", parseInt(value))
                        }
                        edit={props.isEditing}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <EditField
                        type={"number"}
                        value={row.contractLength}
                        maxWidth={30}
                        width={30}
                        updateValue={(value) =>
                          handleUpdateValue(
                            row,
                            "contractLength",
                            parseInt(value)
                          )
                        }
                        edit={props.isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <EditField
                        type={"text"}
                        value={row.comment}
                        maxWidth={340}
                        updateValue={(value) =>
                          handleUpdateValue(row, "comment", value)
                        }
                        edit={props.isEditing}
                        naked={row.comment && row.comment.length > 0}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
    </div>
  );
}
