import React, { useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Moment from "react-moment";
import { parseISO } from "date-fns";
import UserContext from "../../../../../context/user-context";

function formatDate(d) {
	if (d) {
	  const date = new Date(d);
	  const day = "0" + date.getDate();
	  const month = "0" + (date.getMonth() + 1);
	  const year = date.getFullYear();
	  // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
	  return parseISO(year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3));
	} else {
	  return null;
	}
  }

  const momentFormat = {
	de: 'YYYY-MM-DD',
	fr: 'DD/MM/YYYY',
	uk: 'DD/MM/YYYY'
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      marginLeft: -15,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export default function ConsultantList({consultants}) {
  const [locale, setLocale] = useState('uk');
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext && userContext.attributes & userContext.attributes.scope) {
      if (userContext.attributes.scope === 'de') {
        setLocale('de');
      } 
      if (userContext.attributes.scope === 'fr') {
        setLocale('fr');
      } 
    }
  },[userContext])
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Cohort</TableCell>
            <TableCell>Placement</TableCell>
            <TableCell>Placement Start</TableCell>
            <TableCell>Placement End</TableCell>
            <TableCell>Status</TableCell>
			      <TableCell>Total Days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consultants && consultants.length > 0 ? consultants.map((consultant) => (
            <TableRow
              key={consultant.email + "-" + consultant.placement}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{paddingLeft: 4}}>
              {consultant.placementActive ? 
                <StyledBadge overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      variant="dot"></StyledBadge> : null }
                {consultant.firstName} {consultant.lastName}
              </TableCell>
              <TableCell>{consultant.cohort}</TableCell>
              <TableCell>{consultant.placement}</TableCell>
              <TableCell>
				        <Moment format={momentFormat[locale]}>
                  {formatDate(parseInt(consultant.startDate))}
                </Moment>
			        </TableCell>
              <TableCell>
				        <Moment format={momentFormat[locale]}>
                  {formatDate(parseInt(consultant.placementEnd))}
                </Moment>
			        </TableCell>
              <TableCell>{consultant.placementActive ? 
                'Active' : 'Complete'
              }</TableCell>
			  <TableCell>{consultant.hours/8}</TableCell>
            </TableRow>
          )): null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
