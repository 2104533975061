import React, {useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import Avatars from "./Avatar/Avatars";
import Moment from "react-moment";
import Avatar from '@mui/material/Avatar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import LinearProgress from '@mui/material/LinearProgress';

export default function OoO() {

  const [ shortTerm, setShortTerm ] = useState([]);
  const [ longTerm, setLongTerm ] = useState([]);
  const [ days, setDays ] = useState(5);

  const oooQuery = gql`
    query OoO {
      getOutOfOffice {
        date
        policy
        name
        email
        avatar
        startDate
        endDate
      }
    }
  `;

  const { loading, data } = useQuery(oooQuery, {
    fetchPolicy: 'cache-first'
  });
    
  useEffect(() => {
    const shortObj = {};
    if (data && data.getOutOfOffice) {
      data.getOutOfOffice.filter((booking) => {
        return booking.policy === "Holiday" || booking.policy === "DS Consultants Annual Leave"
      }).forEach((person) => {
        if (shortObj[person.date] && shortObj[person.date].length > 0) {
          const people = [...shortObj[person.date]];
          people.push(person);
          shortObj[person.date] = people;
        } else {
          shortObj[person.date] = [person];
        }
      })
      Object.entries(shortObj).sort((a, b) => a[0]-b[0]);
      setShortTerm(shortObj);

      const longObj = {};

      data.getOutOfOffice.filter((booking) => {
        return booking.policy === "Parental Leave" || booking.policy.indexOf("Sabbatical") > -1
      }).forEach((day) => {
        if (!longObj[day.name]) {
          longObj[day.name] = {
            avatar: day.avatar,
            email: day.email,
            endDate: day.endDate,
            policy: day.policy,
            startDate: day.startDate
          }
        }
      })
      Object.entries(longObj).sort((a, b) => a[0]-b[0]);
      setLongTerm(longObj);
    }
  }, [data]);

  return (
    <Card>
      <CardHeader  title="Out of Office" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
        <CardMedia image="/static/images/whiteBubbles.png" />
      </CardHeader>
      {loading ? <LinearProgress /> : null}
      <CardContent>
        {!loading && data && data.getOutOfOffice &&  data.getOutOfOffice.length > 0 ? (
          <>
          <h2 className="text-gray-800 text-xl">Holidays</h2>
          <Grid container spacing={3} className="p-4">
            {Object.keys(shortTerm).splice(0, days).map(key => {
              const dateToFormat = new Date(parseInt(key));
              return (
                <Grid item className="grid grid-cols-1" key={key}>
                  <h2 className="text-gray-400 text-sm">{<Moment format="ddd D" date={dateToFormat} />}</h2>
                  <Avatars key={key} people={shortTerm[key]} />
                </Grid>
              )
            })}
            <Grid item>
              <div className="md:mt-6">
                <IconButton aria-label="more days" size="large" onClick={() => {
                  if (days === 5) {
                    setDays(10)
                  } else {
                    setDays(5)
                  }
                }}>
                  {days === 5 ? <><AddCircleOutlineIcon fontSize="inherit"/><span className="md:hidden text-sm ml-4">More</span></> : <><RemoveCircleOutlineIcon fontSize="inherit"/><span className="md:hidden text-sm ml-4">Less</span></>}
                </IconButton>
              </div>
            
            </Grid>
          </Grid>
          <h2 className="text-gray-800 text-xl">Long Term Leave</h2>
          <Grid container spacing={3} className="p-4">
            {Object.keys(longTerm).map(key => {
              return (
                <Grid item className="grid grid-cols-1" key={key}>
                  <div className="flex flex-row">
                    <div>
                      <Avatar alt={key} src={longTerm[key].avatar} />
                    </div>
                    <div className="grid grid-cols-1 gap-0 ml-3">
                      <div className="text-sm">{key}</div>
                      <div className="text-sm">
                        {(longTerm[key].startDate === longTerm[key].endDate) ? 
                          <Moment format="ddd D MMM YY" date={new Date(parseInt(longTerm[key].startDate))} />
                        : <><Moment format="ddd D MMM YY" date={new Date(parseInt(longTerm[key].startDate))} /> - <Moment format="ddd D MMM YY" date={new Date(parseInt(longTerm[key].endDate))} /></>
                        }
                      </div>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </>) : <>Loading</>}
      </CardContent>
    </Card>
  );
  
}
