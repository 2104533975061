import React, { useState } from "react";
import useClasses from '../../../hooks/useClasses';
import TextField from "@mui/material/TextField";
import InputBase from "@mui/material/InputBase";
import { Link } from "react-router-dom";

export default function MyTextField(props) {
  const styles = theme => ({
    root: {
      width: props.style && props.style.width ? props.style.width : "100%",
    },
  });

  const classes = useClasses(styles);

  const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "0px 0px 3px 0px",
      width: props.style && props.style.width ? props.style.width : "100%",
      maxWidth:
        props.style && props.style.maxWidth ? props.style.maxWidth : "100%",
      textAlign:
        props.style && props.style.textAlign
          ? props.style.textAlign
          : "inherit",
    },
  };

  const [filterTimeout, setFilterTimeout] = React.useState(0);
  const [value, setValue] = useState(props.value || "");

  function handleChange(value) {
    setValue(value);
    if (filterTimeout) clearTimeout(filterTimeout);
    setFilterTimeout(
      setTimeout(() => {
        props.updateValue(value);
      }, 500)
    );
  }

  if (props.state === "edit" && !props.naked) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <TextField
          id={props.id}
          classes={classes}
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          margin="normal"
          autoFocus={true}
          fullWidth={props.fullWidth}
          style={{ ...props.style, display: props.hidden ? "none" : "unset" }}
          inputProps={inputProps}
          type={props.type}
          variant="standard"
        />
      </span>
    );
  } else if (props.state === "edit" && props.naked) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <InputBase
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          classes={classes}
          autoFocus={true}
          fullWidth={props.fullWidth}
          style={{
            ...props.style,
            display: props.hidden ? "none" : "grid",
            marginTop: 0,
            marginBottom: 0,
          }}
          inputProps={{ "aria-label": "naked", ...inputProps }}
          type={props.type}
        />
      </span>
    );
  } else if (props.href) {
    return (
      <Link
        to={props.href}
        style={{
          fontWeight: 500,
          textDecoration: "none",
          color: "#009be5",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {value}
      </Link>
    );
  } else {
    return value;
  }
}
