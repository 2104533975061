import React, {useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Badge from '@mui/material/Badge';
import { SvgIcon } from '@mui/material';
import { applications } from './applications';

export default function LatestNews({country}) {

  const [ apps, setApps ] = useState([]);
  const [ unreadEmail, setUnreadEmail ] = useState(0);

  const handleGetUnreadEmailCount = () => {
    const token = localStorage.getItem('token');
    fetch('/api/google/emailUnread', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (data && data.threadsUnread) {
                setUnreadEmail(data.threadsUnread);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  };

  useEffect(() => {
    const filtered = applications.filter((a) => {
      return a.country === "All" || a.country === country;
    });
    setApps(filtered);
  }, [country])

  useEffect(() => {
    handleGetUnreadEmailCount();
  }, [])

  return (
    <Card>
      <CardHeader  title="Applications" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
        <CardMedia image="/static/images/whiteBubbles.png" />
      </CardHeader>
      <CardContent>
        <Grid container spacing={3} className="p-4">
          {apps.map((app) => 
            {
              if (app.label === 'GMail' && unreadEmail > 0) {
                return (
                  <Grid key={app.label} item xs={4} sm={2} lg={2}>
                  <a rel="noreferrer" href={app.url} target="_blank">
                    <div className="group grid grid-cols-1">
                      <Badge badgeContent={unreadEmail} overlap='circular' color="primary" className="max-h-20">
                        <SvgIcon className="w-full h-full" viewBox="0 0 227.8 227.8">{app.icon}</SvgIcon>
                      </Badge>
                      <div className="text-center leading-4 text-lg mb-2 font-bold text-gray-600 mt-3">{app.label}</div>
                      <div className="text-center text-sm text-gray-400">{app.description}</div>
                    </div>
                  </a>
                </Grid>
                )
              }
              return (
                <Grid key={app.label} item xs={4} sm={2} lg={2}>
                  <a rel="noreferrer" href={app.url} target="_blank">
                    <div className="group grid grid-cols-1">
                      <SvgIcon className="w-full h-full max-h-20"  viewBox="0 0 227.8 227.8" fontSize="inherit">{app.icon}</SvgIcon>
                      <div className="text-center leading-4 text-lg mb-2 font-bold text-gray-600 mt-3">{app.label}</div>
                      <div className="text-center text-sm text-gray-400">{app.description}</div>
                    </div>
                  </a>
                </Grid>
              )
            }
          )}
          
        </Grid>
      </CardContent>
    </Card>
  );
}
