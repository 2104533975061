import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

const classes = (theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const DataSchoolViz = (props) => {
  const [viz, setViz] = useState(null);
  const [width, setWidth] = useState(getWidth());

  const vizRef = useRef(null);

  function loadViz(externalRef) {
    console.log("[DataSchoolViz.js] loadviz", externalRef);
    const showMobile = width <= 1480;
    let vizWidth = 1000;
    let vizHeight = 170;
    if (width > 1000) {
      vizWidth = (width / 3) * 2;
    }
    if (showMobile && width <= 600) {
      vizWidth = width - 80;
      vizHeight = 300;
    } else if (showMobile) {
      vizWidth = (width / 3) * 2;
      vizHeight = 300;
    }

    console.log(externalRef);

    setViz(
      <tableau-viz 
        ref={vizRef}
        id="tableauViz"       
        src={"https://tableauserver.theinformationlab.co.uk/t/til2/views/TILPortalEmbedStats/DSStats"}
        height={vizHeight}
        width={vizWidth}
        device={showMobile ? "phone" : "desktop"}
        hide-tabs={false}
        toolbar='hidden'
        >
          {externalRef && externalRef.length > 0 ? <viz-filter field="Account URL" value={externalRef}> </viz-filter> : null}
		      {externalRef && externalRef.length > 0 ? <viz-filter field="External Ref" value={externalRef}> </viz-filter> : null}
        </tableau-viz>
    );
  }

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
	if (props && props.externalRef && props.externalRef.length > 0) {
		loadViz(props.externalRef);
	}
    // return () => {
    //   setCurToken("");
    // };
    // eslint-disable-next-line
  }, [props.externalRef, width]);

  return (
    <>
    <div
      classes={{ root: classes.root }}
      style={{ marginLeft: -10 }}
    >
      {viz}
    </div>
    </>
  );
};

export default DataSchoolViz;
