import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import AccountManager from "./AccountManager/AccountManager";
import ClientsContext from "../../../../../context/clients-context";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import SelectAccountManager from "./AccountManager/SelectAccountManager";
import AddAccountManager from "./AccountManager/AddAccountManager";

const accountManagerMutation = gql`
  mutation AccountManagers(
    $id: ID
    $accountManagers: [UpdateAccountManager]
    $tableauAM: Int
    $alteryxAM: Int
    $salesforceAM: Int
  ) {
    updateAccountManagers(
      input: { id: $id, accountManagers: $accountManagers }
    ) {
      id
      primaryOwner
      percentOwned
      fromDate
      toDate
      scope
    }
    updateClient(
      input: {
        id: $id
        tableauAM: $tableauAM
        alteryxAM: $alteryxAM
        salesforceAM: $salesforceAM
      }
    ) {
      id
      name
      reportingName
      sector
      country
      accountType
      postcode
      latitude
      longitude
      location
      externalRef
      tableauAM {
        id
        name
        email
        manager
        director
      }
      alteryxAM {
        id
        name
        email
        manager
        director
      }
      salesforceAM {
        id
        name
        email
        manager
        director
      }
      accountManagers {
        id
        amId
        name
        email
        country
        primaryOwner
        percentOwned
        fromDate
        toDate
        scope
        photo
      }
      domains {
        domain
      }
      tags {
        tag
      }
      softwareRenewals {
        id
        originalOrderId
        softwareId
        softwareVendor
        softwareProduct
        qty
        renewalDate
        contractLength
        renewalYear
        comment
      }
    }
  }
`;

const classes = (theme) => ({
  root: {
    width: "100%",
  },
  card: {
    marginTop: 10,
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
    },
    cursor: "pointer",
    maxWidth: "calc(100vw - 20px)",
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  sectionTitle: {
    color: "#6f6f6f",
    marginRight: 20,
    height: 40,
  },
  flexGrow: {
    flexGrow: 1,
  },
  toolButtons: {
    marginBottom: 10,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
  emailIco: {
    height: 15,
    width: 15,
  },
});

const subHeaderStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.75rem",
  lineHeight: "1.3125rem",
  fontWeight: 500,
};

export default function AccountManagers(props) {

  const newAMObj = {
    name: '',
    email: '',
    supplier: '',
    manager: '',
    director: ''
  }

  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [client, setClient] = useState(props.client);
  const [loadedClient, setLoadedClient] = useState(props.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newRecordId, setNewRecordId] = useState(0);
  const [newAM, setNewAM] = useState(newAMObj);
  const [openNewAM, setOpenNewAM] = useState(false);
  const clientsContext = useContext(ClientsContext);

  useEffect(() => {
    setClient({ ...props.client });
    setLoadedClient({ ...props.client });
  }, [props.client]);

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  function handleCancel() {
    setIsEditing(false);
    setError(null);
    setClient({ ...loadedClient });
  }

  function handleChangeAccountManager(idx, am) {
    const updatedAccountManagers = [...client.accountManagers];
    updatedAccountManagers[idx] = am;
    console.log('[AccountManagers.js] handleChangeAccountManager am', am);
    setClient({ ...client, accountManagers: updatedAccountManagers });
  }

  function addAccountManager() {
    const am = {
      id: newRecordId,
      amId: 0,
      name: "",
      email: "",
      photo: "",
      primaryOwner: false,
      percentOwned: 1,
      fromDate: new Date().getTime().toString(),
      scope: 0,
    };
    setNewRecordId(newRecordId - 1);
    const updatedAccountManagers = [...client.accountManagers];
    updatedAccountManagers.push(am);
    setClient({ ...client, accountManagers: updatedAccountManagers });
  }

  const [
    saveAccountManagers,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(accountManagerMutation, {
    onError: (error) => {
      console.error("[CompanyInfo.js] GraphQL Error", error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (loading && !mutationLoading) {
      if (!mutationError) {
        console.log("[AccountManagers.js] Mutation Complete");
        setError(null);
        setIsEditing(false);
        setLoading(false);
      } else {
        console.log("[AccountManagers.js] Mutation Error");
        setIsEditing(true);
        setLoading(false);
      }
    } else if (!loading && mutationLoading) {
      console.log("[AccountManagers.js] Saving Mutation");
      setError(null);
      setIsEditing(true);
      setLoading(true);
    } /* eslint-disable */
  }, [mutationLoading, mutationError]); /* eslint-enable */

  function updateClient(name, value) {
    const clientObj = { ...client };
    clientObj[name] = value;
    setClient(clientObj);
  }

  function handleAddToAMList(type, value) {
    console.log('[AccountManagers.js] handleAddToAMList', type, value);
    setNewAM({
      name: value,
      supplier: type
    });
    setOpenNewAM(true);
  }

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      onClick={() => (!isEditing ? setIsEditing(true) : null)}
      raised={isEditing}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <EditIcon
            className={classes.editIcon}
            style={{ display: hover && !isEditing ? "inherit" : "none" }}
          />
        }
        title={"Account Managers"}
      />
      <CardContent>
        <div style={{ display: "flex", height: 40 }}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            TIL Account Managers
          </Typography>
          <div className={classes.toolButtons}>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              style={{ display: !isEditing ? "none" : "unset" }}
              onClick={addAccountManager}
            >
              Add New
            </Button>
          </div>
          <div className={classes.flexGrow} />
        </div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {!isEditing ? (
                  <TableCell style={{ width: 35, paddingRight: 5 }}></TableCell>
                ) : null}
                <TableCell>Name</TableCell>
                <TableCell align="center">Primary</TableCell>
                <TableCell align="right">Percent Owned</TableCell>
                <TableCell align="right">From Date</TableCell>
                <TableCell align="right">To Date</TableCell>
                <TableCell align="left">Scope</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {client.accountManagers.map((AM, idx) => {
                console.log('[AccountManagers.js] client.accountManagers:', client.accountManagers);
                return (
                  <AccountManager
                    am={AM}
                    key={AM.id}
                    editMode={isEditing}
                    changeAccountManager={(am) =>
                      handleChangeAccountManager(idx, am)
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Tableau Account Manager
            </Typography>
            {(() => {
              if (isEditing) {
                return (
                  <SelectAccountManager
                    value={client.tableauAM}
                    options={clientsContext.tableauAccountManagers}
                    updateClient={(newValue) => updateClient("tableauAM",newValue)}
                    addNew={(newAM) => handleAddToAMList("tableauAM",newAM)}
                  />
                );
              } else {
                let amName;
                if (client.tableauAM && client.tableauAM.name) {
                  amName = <b>{client.tableauAM.name}</b>;
                  if (client.tableauAM.email) {
                    amName = (
                      <span>
                        <b>{client.tableauAM.name}</b>
                        <IconButton
                          aria-label="email"
                          size="small"
                          className={classes.emailBtn}
                          href={"mailto:" + client.tableauAM.email}
                          rel="noopener"
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                        >
                          <EmailIcon className={classes.emailIco} />
                        </IconButton>
                      </span>
                    );
                  }
                  return (
                    <div style={{ paddingLeft: 16 }}>
                      {amName}
                      <br />
                      <span style={subHeaderStyle}>Manager: </span>
                      {client.tableauAM.manager}
                      <br />
                      <span style={subHeaderStyle}>Director: </span>
                      {client.tableauAM.director}
                      <br />
                    </div>
                  );
                }
              }
            })()}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Alteryx Account Manager
            </Typography>
            {(() => {
              if (isEditing) {
                return (
                  <SelectAccountManager
                    value={client.alteryxAM}
                    options={clientsContext.alteryxAccountManagers}
                    updateClient={(newValue) => updateClient("alteryxAM",newValue)}
                    addNew={(newAM) => handleAddToAMList("alteryxAM",newAM)}
                  />
                );
              } else {
                let amName;
                if (client.alteryxAM && client.alteryxAM.name) {
                  amName = <b>{client.alteryxAM.name}</b>;
                  if (client.alteryxAM.email) {
                    amName = (
                      <span>
                        <b>{client.alteryxAM.name}</b>
                        <IconButton
                          aria-label="email"
                          size="small"
                          className={classes.emailBtn}
                          href={"mailto:" + client.alteryxAM.email}
                          rel="noopener"
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                        >
                          <EmailIcon className={classes.emailIco} />
                        </IconButton>
                      </span>
                    );
                  }
                  return (
                    <div style={{ paddingLeft: 16 }}>
                      {amName}
                      <br />
                      <span style={subHeaderStyle}>Manager: </span>
                      {client.alteryxAM.manager}
                      <br />
                      <span style={subHeaderStyle}>Director: </span>
                      {client.alteryxAM.director}
                      <br />
                    </div>
                  );
                }
              }
            })()}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Salesforce Account Manager
            </Typography>
            {(() => {
              if (isEditing) {
                return (
                  <SelectAccountManager
                    value={client.salesforceAM}
                    options={clientsContext.salesforceAccountManagers}
                    updateClient={(newValue) => updateClient("salesforceAM",newValue)}
                    addNew={(newAM) => handleAddToAMList("salesforceAM",newAM)}
                  />
                );
              } else {
                let amName;
                if (client.salesforceAM && client.salesforceAM.name) {
                  amName = <b>{client.salesforceAM.name}</b>;
                  if (client.salesforceAM.email) {
                    amName = (
                      <span>
                        <b>{client.salesforceAM.name}</b>
                        <IconButton
                          aria-label="email"
                          size="small"
                          className={classes.emailBtn}
                          href={"mailto:" + client.salesforceAM.email}
                          rel="noopener"
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                        >
                          <EmailIcon className={classes.emailIco} />
                        </IconButton>
                      </span>
                    );
                  }
                  return (
                    <div style={{ paddingLeft: 16 }}>
                      {amName}
                      <br />
                      <span style={subHeaderStyle}>Manager: </span>
                      {client.salesforceAM.manager}
                      <br />
                      <span style={subHeaderStyle}>Director: </span>
                      {client.salesforceAM.director}
                      <br />
                    </div>
                  );
                }
              }
            })()}
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={isEditing}>
        <LinearProgress style={{ display: loading ? "inherit" : "none" }} />
        <CardActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              saveAccountManagers({
                variables: {
                  id: parseInt(client.id),
                  accountManagers: client.accountManagers.map((am) => {
                    return {
                      id: parseInt(am.amId),
                      primaryOwner: am.primaryOwner,
                      percentOwned: am.percentOwned,
                      fromDate: am.fromDate,
                      toDate: am.toDate,
                      scope: am.scope
                    };
                  }),
                  tableauAM:
                    client.tableauAM && client.tableauAM.id
                      ? parseInt(client.tableauAM.id)
                      : null,
                  alteryxAM:
                    client.alteryxAM && client.alteryxAM.id
                      ? parseInt(client.alteryxAM.id)
                      : null,
                  salesforceAM:
                    client.salesforceAM && client.salesforceAM.id
                      ? parseInt(client.salesforceAM.id)
                      : null,
                },
              });
            }}
          >
            Save
          </Button>
          <Tooltip
            title={error ? error : ""}
            aria-label="error"
            style={{ display: error ? "inherit" : "none" }}
          >
            <ErrorIcon
              className={classes.errorIcon}
              style={{ display: error ? "inherit" : "none" }}
            />
          </Tooltip>
        </CardActions>
      </Collapse>
      <AddAccountManager
        showDialog={openNewAM}
        closeDialog={() => {
          setOpenNewAM(false);
          setNewAM(newAMObj);
        }}
        updateNewAM={(am) => setNewAM(am)}
        newAM={newAM}
        updateClient={(newValue) => updateClient(newAM.supplier,newValue)}
      />
    </Card>
  );
}
