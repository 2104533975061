import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditField from "../../../../../Shared/EditField/EditField";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const higherEduMutation = gql`
  mutation HigherEducation(
    $id: ID
	$type: String
    $date: String
    $name: String
    $institution: String
	$new: Boolean
	$edit: Boolean
	$delete: Boolean
  ) {
    updateBobHigherEducation(
      input: {
        id: $id
		type: $type
        date: $date
        name: $name
        institution: $institution
		new: $new
		edit: $edit
		delete: $delete
      }
    ) {
		id
		type
		date
		name
		institution
	}
  }
`;

let convertDate = (d) => {
	const today = new Date(d);
	const offset = today.getTimezoneOffset();
	const newDate = new Date(today.getTime() + (offset*60*1000));
	return newDate.toISOString().split('T')[0];
}

export default function HigherEdu({higher, loading}) {

	const [ rows, setRows ] = useState(higher);
	const [ edit, setEdit ] = useState(false);

	const [
		saveEdu,
		{ loading: mutationLoading },
	  ] = useMutation(higherEduMutation, {
		update: (store, { data, error }) => {
		  console.log("[HigherEdu.js] graphql update", data);
		  if (data && data.updateBobHigherEducation) {
			setRows(data.updateBobHigherEducation.map((row) => {
				const newRow = {...row,
					edit: false,
					new: false,
					delete: false
				}
				if (newRow.date && newRow.date.indexOf('-') > -1) {
					newRow.date = new Date(newRow.date).getTime();
				}
				return newRow;
			}));
		  }
		},
		onError: (error) => {
		  console.error("[HigherEdu.js] GraphQL Error", error);
		},
	  });	

	useEffect(() => {
		loading(mutationLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mutationLoading])
	
	useEffect(() => {
		const newHigher = higher.map((row) => {
			const newRow = {
				...row,
				edit: false,
				new: false,
				delete: false
			}
			if (newRow.date && newRow.date.indexOf('-') > -1) {
				newRow.date = new Date(newRow.date).getTime();
			}
			return newRow;
		})
		setRows(newHigher)
	}, [higher])

	const handleStartEdit = () => {
		console.log('[HigherEdu.js] Edit', edit);
		const newRows = [...rows];
		newRows.push({
			id: rows.length + 1,
			type : null,
			date: null,
			name: null,
			institution: null,
			new: true
		});
		setRows(newRows);
		setEdit(true);
	};

	const handleEdit = (idx, name, value) => {
		console.log('[HigherEdu.js] Update', idx, name, value);
		const newRows = [...rows];
		let updateRecord = {...newRows[idx]};
		console.log('[HigherEdu.js] updateRecord', updateRecord);
		updateRecord[name] = value;
		updateRecord = {
			...updateRecord,
			edit: true
		};
		newRows[idx] = updateRecord;
		setRows(newRows);
	}

	const handleSave = () => {
		console.log('[HigherEdu.js] Save', rows);
		rows.forEach((row) => {
			if (row.type !== null && (row.new || row.edit || row.delete)) {
				saveEdu({
					variables: {
						id: row.id,
						type: row.type,
						date: convertDate(parseInt(row.date)),
						name: row.name,
						institution: row.institution,
						new: row.new,
						edit: row.edit,
						delete: row.delete
					},
				  });
			}
			
		})
		setEdit(false);
	}

	return (
		<>
		{!edit ? <Fab 
			size="small" 
			color="primary" 
			aria-label="add"
			className="absolute right-4 -mt-2 bg-[#1976d2]"
			onClick={() => handleStartEdit()}
		>
			<EditIcon />
		</Fab>
		: null}
		{edit ? <Fab 
			size="small" 
			color="primary" 
			aria-label="add"
			className="absolute right-4 -mt-2 bg-[#1976d2]"
			onClick={() => handleSave()}
		>
			<SaveIcon />
		</Fab>
		: null}
		<TableContainer>
			<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
				
				<TableHead>
				<TableRow>
					<TableCell>Type</TableCell>
					<TableCell>Date Awarded</TableCell>
					<TableCell>Name/Subject</TableCell>
					<TableCell>Institution</TableCell>
					<TableCell></TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{rows ? rows.filter((row) => !row.delete).map((row, idx) => {
					return (
					<TableRow
					key={row.id}
					sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
					<TableCell component="th" scope="row">
						<EditField 
							type={"select"}
							value={row.type}
							edit={edit}
							list={[
								{
									label: 'Undergraduate',
									value: 'Undergraduate'
								},{
									label: 'Masters',
									value: 'Masters'
								},{
									label: 'PhD',
									value: 'PhD'
								},{
									label: 'Other',
									value: 'Other'
								},
							]}
							updateValue={(val) => {
								handleEdit(idx, 'type', val);
							}}
						/>
					</TableCell>
					<TableCell>
						<EditField 
							type={"date"}
							value={row.date}
							edit={edit}
							minDate={'1'}
							updateValue={(val) => {
								handleEdit(idx, 'date', val);
							}}
						/>
					</TableCell>
					<TableCell>
						<EditField 
							type={"text"}
							value={row.name}
							edit={edit}
							updateValue={(val) => {
								handleEdit(idx, 'name', val);
							}}
						/>
					</TableCell>
					<TableCell>
						<EditField 
							type={"text"}
							value={row.institution}
							edit={edit}
							updateValue={(val) => {
								handleEdit(idx, 'institution', val);
							}}
						/>
					</TableCell>
					<TableCell>
						{edit && 
							<IconButton
								className="color-grey"
								aria-label="delete record"
								component="label"
								onClick={() => {
									handleEdit(idx, 'delete', true);
								}}
							>
								<DeleteOutlineIcon />
							</IconButton> 
						}
					</TableCell>
					</TableRow>
				)}) : null}
				</TableBody>
			</Table>
		</TableContainer>
		</>
	);
}
