import React, { useState, useEffect } from "react";
import useClasses from '../../../../hooks/useClasses';
import TextField from "@mui/material/TextField";
import InputBase from "@mui/material/InputBase";
import {NumericFormat} from "react-number-format";

export default function MyTextField(props) {
  const styles = theme => ({
    root: {
      width: props.style && props.style.width ? props.style.width : "100%",
    },
    link: {
      textDecoration: "none",
      color: "#009be5",
    },
  });

  const classes = useClasses(styles);

  const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "6px 0px 10px 0px",
      width: props.style && props.style.width ? props.style.width : "100%",
      maxWidth:
        props.style && props.style.maxWidth ? props.style.maxWidth : "100%",
      textAlign:
        props.style && props.style.textAlign
          ? props.style.textAlign
          : "inherit",
    },
  };

  const [filterTimeout, setFilterTimeout] = React.useState(0);
  const [value, setValue] = useState(props.value || "");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function handleChange(value) {
    setValue(value);
    if (filterTimeout) clearTimeout(filterTimeout);
    setFilterTimeout(
      setTimeout(() => {
        props.updateValue(value);
      }, 500)
    );
  }

  if (props.state === "edit" && !props.naked) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <TextField
          id={"MyTextField"}
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          margin="normal"
          autoFocus={props.focus ? true : false}
          fullWidth={props.fullWidth}
          style={{ ...props.style, display: props.hidden ? "none" : "unset" }}
          inputProps={inputProps}
          type={props.type}
          variant="standard"
          multiline={props.multiline}
          maxRows={props.maxRows}
        />
      </span>
    );
  } else if (props.state === "edit" && props.naked) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <InputBase
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          autoFocus={props.focus ? true : false}
          fullWidth={props.fullWidth}
          style={{
            ...props.style,
            display: props.hidden ? "none" : "grid",
            marginTop: 0,
            marginBottom: 0,
          }}
          inputProps={{ "aria-label": "naked", ...inputProps }}
          type={props.type}
        />
      </span>
    );
  } else if (props.href && props.href.length > 0) {
    return (
      <a
        href={props.href}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        {props.value}
      </a>
    );
  } else if (props.currency) {
    return (
      <NumericFormat
        value={value}
        fixedDecimalScale={true}
        decimalScale={2}
        displayType={"text"}
        thousandSeparator={true}
        prefix={props.currency}
      />
    );
  } else {
    return <span style={{ ...props.style }}>{value}</span>;
  }
}
