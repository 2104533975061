import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import ClientsContext from "../../../../context/clients-context";
import UserContext from "../../../../context/user-context";

const contextQuery = gql`
  query ContextQuery($region: String) {
    getSectors {
      sector
    }
    getSoftware {
      id
      vendor
      product
    }
    getCountries {
      country
    }
    getAccountManagers {
      amId
      name
      email
      country
      photo
    }
    getTags {
      tag
    }
    tableauAccountManagers: getSupplierAccountManagers(supplier: "Tableau") {
      id
      name
      supplier
      email
      director
      manager
    }
    alteryxAccountManagers: getSupplierAccountManagers(supplier: "Alteryx") {
      id
      name
      supplier
      email
      director
      manager
    }
    salesforceAccountManagers: getSupplierAccountManagers(
      supplier: "Salesforce"
    ) {
      id
      name
      supplier
      email
      director
      manager
    }
    getCheckInTypes {
      id
      label
      type
    }
    getCheckInOutcomes {
      id
      outcome
    }
    regionPaymentGroups: getPaymentGroups (
      orderBy: "o.ID",
      order: "desc",
      region: $region
    ) {
      id
      paymentId
      region
    }
    getOwnershipScope {
      id
      name
    }
  }
`;

export default function ClientsContextProvider(props) {

  const userContext = useContext(UserContext);

  const [context, setContext] = useState({
    tableauAccountManagers: [],
    alteryxAccountManagers: [],
    salesforceAccountManagers: [],
    tags: [],
    software: [],
    accountManagers: [],
    countries: [],
    sectors: [],
    cmmLevel: [
      { id: "0", name: "0 - Base Camp" },
      { id: "1", name: "1 - Ben Nevis" },
      { id: "2", name: "2 - K2" },
      { id: "3", name: "3 - Everest" },
    ],
    cmmRanking: [{ rank: "A" }, { rank: "B" }, { rank: "C" }],
    checkInTypes: [],
    checkInOutcomes: [],
    paymentGroups: [],
    vendors: [{id: 0, vendor: "Tableau"},{id: 1, vendor: "SFDC"},{id: 2, vendor: "Alteryx"},{id: 3, vendor: "Snowflake"}],
    ownershipScope: []
  });

  const updateContext = (ctx, value) => {
    const contextObj = { ...context };
    contextObj[ctx] = value;
    setContext(contextObj);
  };

  const { data } = useQuery(contextQuery, {
    variables: {
      region: userContext.attributes.scope
    }
  });

  const zeroPaymentGroup = {
      id: "null-zz",
      paymentId: "0",
      region: userContext.attributes.scope,
      label: "Not Assigned"
    }


  useEffect(() => {
    if (data && data.getSectors) {
      const accountManagers = data.getAccountManagers.map((a) => {
        const ret = {...a};
        ret.accountManager = ret.name;
        ret.id = ret.amId;
        return ret;
      });
      const regionPaymentGroupsData = data.regionPaymentGroups;
      const paymentGrps = regionPaymentGroupsData.map((g) => {
        return {
          ...g,
          label: g.paymentId
        }
      })
      paymentGrps.unshift(zeroPaymentGroup);

      const ctx = {
        tableauAccountManagers: data.tableauAccountManagers,
        alteryxAccountManagers: data.alteryxAccountManagers,
        salesforceAccountManagers: data.salesforceAccountManagers,
        tags: data.getTags,
        software: data.getSoftware,
        accountManagers: accountManagers,
        countries: data.getCountries,
        sectors: data.getSectors,
        cmmLevel: [
          { id: "0", name: "0 - Base Camp" },
          { id: "1", name: "1 - Ben Nevis" },
          { id: "2", name: "2 - K2" },
          { id: "3", name: "3 - Everest" },
        ],
        cmmRanking: [{ rank: "A" }, { rank: "B" }, { rank: "C" }],
        checkInTypes: data.getCheckInTypes,
        checkInOutcomes: data.getCheckInOutcomes,
        vendors: [{id: 0, vendor: "Tableau"},{id: 1, vendor: "SFDC"},{id: 2, vendor: "Alteryx"},{id: 3, vendor: "Snowflake"}],
        paymentGroups: paymentGrps,
        ownershipScope: data.getOwnershipScope,
        contextLoaded: true
      };
      setContext(ctx);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ClientsContext.Provider
      value={{
        ...context,
        updateContext,
      }}
    >
      {props.children}
    </ClientsContext.Provider>
  );
}
