import { useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ClientsContext from "../../../context/clients-context";

const useSetUrlFilters = (filterable, changeFilters) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const clientsContext = useContext(ClientsContext);
    const defaultCountryFilter = {
      context: {
        array: "countries",
        label: "country",
        type: "string",
        value: "country"
      },
      id: "country",
      inUse: true,
      label: "Country",
      type: "select",
      value: [
        {value: 'UK', label: 'UK', type: "string"}
      ]
    };
    const salesPage = location.pathname === "/sales";
    useEffect(() => {
        if (clientsContext.contextLoaded) {
          const urlParams = [];
          let setCountry = true;
          if (salesPage) {
            setCountry = false;
          }
          for(var key of query.keys()) {
            // eslint-disable-next-line no-loop-func
            const filterIdx = filterable.findIndex((filter) => {
              return filter.id === key;
            });
            const filterObj = {...filterable[filterIdx]};
            if (filterObj.id === "country" || filterObj.id === "region" || salesPage) {
              setCountry = false;
            }
            if (filterObj.type === "select") {
              //console.log('[setUrlFilters] query.get', query.get(key), query.get(key).split(","), filterObj.context.array);
              const urlValues = query.get(key).split(",");
              //console.log('[setUrlFilters] clientsContext', clientsContext);
              const selectedValues = clientsContext[filterObj.context.array].filter((a) => {
                //console.log('[setUrlFilters] Test ', urlValues, a[filterObj.context.value]);
                return urlValues.indexOf(a[filterObj.context.value]) > -1 || urlValues.indexOf(a[filterObj.context.value]+"") > -1;
              });
              const filterValues = selectedValues.map((val) => {
                return {
                  value : val[filterObj.context.value],
                  label : val.name || val[filterObj.id] || val[filterObj.context.value]
                }
              })
              filterObj.value = filterValues;
            } else {
              console.log('[setUrlFilters] query.get', query.get(key));
              let queryValue;
              if (query.get(key) === "true") {
                queryValue = true;
              } else if (query.get(key) === "false") {
                queryValue = null;
              } else if (parseInt(query.get(key)) > -999) {
                queryValue = parseInt(query.get(key));
              } else {
                queryValue = query.get(key);
              }
              filterObj.value = queryValue;
            }
            filterObj.inUse = true;
            urlParams.push(filterObj);
          }
          if (setCountry) {
            console.log('[setUrlFilters] setting default country to', defaultCountryFilter);
            urlParams.push(defaultCountryFilter);
          }
          console.log('[setUrlFilters] changeFilters', urlParams);
          changeFilters(urlParams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientsContext.contextLoaded]);
}

const updateUrlFilters = (query, history, filters) => {
    // console.log('[UrlFiltering.js] props.filters changed', filters);
    const keysInUrl = [],
          expectedKeys = [];
    for(var key of query.keys()) {
      keysInUrl.push(key);
    };
    filters.forEach((filter) => {
        expectedKeys.push(filter.id);
        if (filter.type === "select") {
          let valStr = "";
            filter.value.forEach((val) => {
              if (valStr === "") {
                valStr = val.value;
              } else {
                valStr += ","+val.value;
              }
              query.set(filter.id, valStr);
            })
        } else {
            query.set(filter.id, filter.value);
        }
    });
    keysInUrl.forEach((key) => {
      if (expectedKeys.indexOf(key) === -1) {
        // console.log('[Sales.js] delete key', key);
        query.delete(key);
      }
    });
    history({search: query.toString()});
}

export {
    useSetUrlFilters,
    updateUrlFilters
};
