import React, { useState, useEffect } from "react";
import useClasses from '../../../../hooks/useClasses';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Moment from "react-moment";

function formatDate(d) {
  if (d) {
    const date = new Date(d);
    const day = "0" + date.getUTCDate();
    const month = "0" + (date.getUTCMonth() + 1);
    const year = date.getUTCFullYear();

    // console.log('[AccountManager] Date', year + '-' + month + '-' +day);
    return year + "-" + month.slice(-2, 3) + "-" + day.slice(-2, 3) + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
  } else {
    return null;
  }
}

export default function MySelectField(props) {
  const [value, setValue] = useState(props.value || "");

  const styles = theme => ({
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const classes = useClasses(styles);

  const inputProps = {
    style: {
      fontSize: "0.875rem",
      padding: "6px 0px 10px 0px",
      width: props.style && props.style.width ? props.style.width : "100%",
      maxWidth:
        props.style && props.style.maxWidth ? props.style.maxWidth : "100%",
      textAlign:
        props.style && props.style.textAlign
          ? props.style.textAlign
          : "inherit",
    },
    autoFocus: props.focus ? true : false,
  };
  if (props.edit) {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ width: "100%" }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            margin="normal"
            style={{ width: 130 }}
            format="yyyy-MM-dd hh:mm:ss"
            clearable
            minDate={
              props.minDate ? formatDate(parseInt(props.minDate)) : "2010-01-01"
            }
            maxDate={
              props.maxDate ? formatDate(parseInt(props.maxDate)) : "2100-01-01"
            }
            value={value ? formatDate(parseInt(value)) : null}
            onChange={(date) => {
              if (date) {
                setValue(date.getTime().toString());
                props.updateValue(date.getTime().toString());
              } else {
                setValue(null);
                props.updateValue(null);
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            inputProps={inputProps}
            classes={classes}
          />
        </LocalizationProvider>
      </span>
    );
  } else {
    if (props.value) {
      console.log('[DateTimeField] value', parseInt(props.value)/1000);
      return (
        <Moment local unix format="DD-MM-YYYY LTS" style={{ ...props.style }}>
          {parseInt(props.value)/1000}
        </Moment>
      );
    } else {
      return "";
    }
  }
}
