import React from "react";
import { Grid } from "@mui/material";
import EditField from "../../../../../Shared/EditField/EditField";

export default function PreviousEmployment({previousEmployment, edit}) {

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={8} xl={6}>
				<div className="pt-2">Is this your first job after graduation?</div>
			</Grid>
			<Grid item xs={12} sm={4} xl={6}>
				<EditField
					type="select"
					value={(previousEmployment && previousEmployment.firstJob) || '0'}
					list={[{
						label: 'Yes',
						value: '254626567'
					},{
						label: 'No',
						value: '254626568'
					}]}
					edit={true}
					updateValue={(val) => edit('firstJob', val)}
				/>
			</Grid>
			{previousEmployment.firstJob && previousEmployment.firstJob === '254626568' && <>
				<Grid item xs={12}>
					<div>Job Title</div>
					<EditField
						type="text"
						value={(previousEmployment && previousEmployment.jobTitle) || ''}
						edit={true}
						fullWidth={true}
						updateValue={(val) => edit('jobTitle', val)}
					/>
				</Grid>
				<Grid item xs={12}>
					<div>Job Description</div>
					<EditField
						type="text"
						value={(previousEmployment && previousEmployment.jobDescription) || ''}
						edit={true}
						updateValue={(val) => edit('jobDescription', val)}
						fullWidth={true}
						maxRows={4}
					/>
				</Grid>
				</>
			}
		</Grid>
	);
}
