import React from "react";

const userContext = React.createContext({
  active: false,
  applications: [],
  attributes: {},
  auth0Id: "",
  boards: [],
  config: {},
  google: {},
  lambdaAuth: {},
  profile: {},
  _id: "",
});

export default userContext;
