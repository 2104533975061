import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CurrentSignature({newSignature, getCurrentSignature, logout}) {

  const [ copyLabel, setCopyLabel ] = useState('Copy to Clipboard');
  const [ saveLabel, setSaveLabel ] = useState('Save to GMail');
  const [ saving, setSaving ] = useState(false);

  const copied = (text, result) => {
    if (result) {
      setCopyLabel('Copied!');
      setTimeout(() => {
        setCopyLabel('Copy to Clipboard');
      }, 2500)
    } else {
      setCopyLabel('Error!');
      setTimeout(() => {
        setCopyLabel('Copy to Clipboard');
      }, 2500)
    }
  }

  const handleStartSaving = () => {
    setSaving(true);
    setSaveLabel('Saving');
  };

  const handleSaved = () => {
    getCurrentSignature();
    setSaving(false);
    setSaveLabel('Saved!');
    setTimeout(() => {
      setSaveLabel('Save to GMail');
    }, 2500)
  }

  const handleError = () => {
    setSaving(false);
    setSaveLabel('Error!');
    setTimeout(() => {
      setSaveLabel('Save to GMail');
    }, 2500)
  }

  const saveToGmail = () => {
    handleStartSaving();
    const token = localStorage.getItem('token');
    fetch('/api/google/signature', {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
            },
        body: newSignature
        },
        )
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (data && data.code && data.code === 401) {
              logout({ returnTo: window.location.href })
            }
            handleSaved();
        })
        .catch((error) => {
            console.error('Error:', error);
            handleError();
        });
  }

  return (
    <Card className="mt-4" >
      <CardHeader  title="New Signature Preview" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
      </CardHeader>
      <CardContent>
        <div dangerouslySetInnerHTML={{ __html: newSignature }} />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => saveToGmail()}>{saveLabel} {saving ? <CircularProgress size={20} className="ml-2"/> : null}</Button>
        <CopyToClipboard text={newSignature} options={{format: "text/html"}} onCopy={copied}>
          <Button size="small">{copyLabel}</Button>
        </CopyToClipboard>
      </CardActions>
    </Card>
  );
}
