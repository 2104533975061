import React from "react";
import Icon from "react-icon-base";

const alteryxIcon = (props) => {
  return (
    <Icon viewBox="0 0 500 500" {...props}>
      <title>{props.title || "Alteryx"}</title>
      <g>
        <path
          d="M297.9,393.7l-1.6,1.2l-1.7,1c-29.8,20.6-74.3,34.4-110.8,34.4h0c-53.5-1.2-73-20.1-74.1-71.6
        c1.2-59.3,21-77.6,87.8-81.8l92.2-4.3l4.1-0.2l4-0.1V393.7z M465.9,422.9c-3-10-11.3-16.1-21.4-16.1c-2.7,0.1-5.2,0.3-8.4,1
        c-14.3,3.4-22.2,4.5-33.2,5.3c-20.9,1-24.6-7.1-25.9-30.6V61.4c-0.6-7.4-4.5-14.8-10.2-19.3l-2.1-1.3c-0.7-0.5-1.3-1.2-2.2-1.6
        C345,28.9,316,20.6,291.4,14.4l-1.8-0.5C260.3,6.8,228,3.1,196,3.1c-18.8,0-35.7,1.4-42.5,2.3c-14.1,1.4-52,7.7-64.7,12
        c-3.7,1.2-15.7,6.9-16.1,18.2c0.2,5.9,6.8,24.6,6.8,24.6c4.3,13.1,8.9,16.3,17.2,18c6.7,0.8,21-2.3,30.6-3.9
        c24.7-4.6,40.9-6.3,52.5-6.4c15-0.2,33.8-0.4,55.9,2.7c41.9,6.4,58.1,19.3,58.1,19.3v124.7l-3.7,0.1l-89.6,2.2
        C80.8,220.8,25.3,268.3,26.1,364c-0.2,43.5,13.4,78.3,39.4,100.9c24.1,21.2,59.5,32.3,102,32.3h0.2c48.8,0,90.9-15.1,131.8-47.5
        l3.9-3l2,4.5c9.8,21.7,26.6,35.7,49.7,41.6c10.3,2.9,22.1,4.4,37.2,4.4c3.9,0,7.8-0.2,11.2-0.6c13.7-1.9,31.9-4.3,52.1-13.9
        c11.1-5.3,19-11.8,18.4-23.5C473.6,448.9,465.9,422.9,465.9,422.9"
        />
      </g>
    </Icon>
  );
};

export default alteryxIcon;