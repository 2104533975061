import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

export default function CurrentSignature({signature}) {

  return (
    <Card>
      <CardHeader  title="Current Signature" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
      </CardHeader>
      <CardContent>
          <div dangerouslySetInnerHTML={{ __html: signature }} />
      </CardContent>
    </Card>
  );
}
