import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import SalesOverviewViz from "./SalesOverviewViz";

const classes = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appbar: {
    border: 0,
    backgroundColor: "unset",
    marginLeft: -20,
  },
  indicator: {
    backgroundColor: "#009be5",
  },
  tabPanelRoot: {
    paddingBottom: 0,
  },
  card: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
    },
    cursor: "pointer",
    maxWidth: "calc(100vw - 20px)",
  },
  sectionTitle: {
    color: "#6f6f6f",
    marginRight: 20,
    height: 40,
  },
  edit: {
    marginTop: -5,
  },
  flexGrow: {
    flexGrow: 1,
  },
  toolButtons: {
    marginBottom: 10,
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.34)",
  },
  errorIcon: {
    color: "#e54900",
  },
  cardContentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    paddingRight: 30,
  },
  cardHeaderTitle: {
    fontSize: "1rem",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function DataSchool(props) {
  const [hover, setHover] = useState(false);

  const onHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(250, 250, 250)",
  };

  const offHoverStyle = {
    transition: "background-color 0.2s ease",
    backgroundColor: "rgb(255, 255, 255)",
  };

  return (
    <Card
      className={classes.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? onHoverStyle : offHoverStyle}
      square={true}
    >
      <CardHeader
        classes={{
          content: classes.cardHeader,
          action: classes.cardHeadAction,
          title: classes.cardHeaderTitle,
        }}
        title={<div className={classes.toolButtons}>Sales Overview</div>}
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <AppBar
          position="static"
          color="inherit"
          className={classes.appbar}
          elevation={0}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
        </AppBar>
          <SalesOverviewViz tableauToken={props.tableauToken} clientId={props.client.id}/>
      </CardContent>
    </Card>
  );
}
