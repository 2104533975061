import React from "react";

const clientsContext = React.createContext({
  tableauAccountManagers: [],
  alteryxAccountManagers: [],
  salesforceAccountManagers: [],
  tags: [],
  software: [],
  accountManagers: [],
  countries: [],
  sectors: [],
  cmmLevel: [],
  cmmRating: [],
  checkInTypes: [],
  checkInOutcomes: [],
  vendors: [],
  paymentGroups: [],
  ownershipScope: [],
  updateContext: () => {},
  contextLoaded: false,
});

export default clientsContext;
