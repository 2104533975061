import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import moment from 'moment'

export default function BasicInfo({profile}) {

  return (
    <Card>
      <CardHeader  title="Data School Profile" sx={{
        backgroundColor: "#7ea9ba",
        color: "#FFF",
        paddingTop: "50px",
        paddingBottom: "10px"
      }}>
        <CardMedia image="/static/images/whiteBubbles.png" />
      </CardHeader>
      <CardContent>
        <Grid container spacing={3} className="p-4">
          <Grid item >
			<div className="flex">
				<Avatar
					alt={(profile && profile.displayName) || ""}
					src={(profile && profile.avatar) || ""}
					sx={{ width: 150, height: 150 }}
				/>
				<div>
					<div className="font-extrabold text-4xl pl-10 text-[#535353]">
						{(profile && profile.displayName) || ""}
					</div>
					<div className="text-2xl pl-10 text-[#535353]">
						DS {(profile && profile.cohort)}
					</div>
				</div>
			</div>
		  </Grid>
		  <Grid item >
			<Grid container spacing={2}>
				{profile && profile.coach ?
				<Grid item xs={6}>
					<span className="font-bold">Coach</span>
					<div className="flex mt-2">
						<a href={'https://app.hibob.com/employee-profile/' + (profile && profile.coach && profile.coach.id)} className="flex" target={"_blank"}>
							<Avatar
								alt={(profile && profile.coach && profile.coach.displayName) || ""}
								src={(profile && profile.coach && profile.coach.avatar) || ""}
								sx={{ width: 42, height: 42 }}
							/>
							<div className="ml-4">
								<div>{(profile && profile.coach && profile.coach.displayName) || ""}</div>
							</div>
						</a>
					</div>
				</Grid>
				: null }
				{profile && profile.supportLead ?
				<Grid item xs={6}>
					<span className="font-bold">Support Lead</span>
					<div className="flex mt-2">
						<a href={'https://app.hibob.com/employee-profile/' + (profile && profile.supportLead && profile.supportLead.id)} className="flex" target={"_blank"}>
							<Avatar
								alt={(profile && profile.supportLead && profile.supportLead.displayName) || ""}
								src={(profile && profile.supportLead && profile.supportLead.avatar) || ""}
								sx={{ width: 42, height: 42 }}
							/>
							<div className="ml-4">
								<div>{(profile && profile.supportLead && profile.supportLead.displayName) || ""}</div>
							</div>
						</a>
					</div>
				</Grid>
				: null }
				<Grid item xs={12}>
					<div className="font-bold">Holiday Balance</div>
					{(profile && profile.holidayBalance && profile.holidayBalance.totalBalanceAsOfDate) || ""} days
				</Grid>
				<Grid item xs={6}>
					<Button 
						variant="contained" 
						onClick={() => window.open("https://app.hibob.com/time-off/my-time-off", "_blank")}
						className="bg-[#1976d2]"
						endIcon={<ScheduleSendIcon />}
					>
						Req. Holiday
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button 
						variant="contained"
						className="bg-[#1976d2]"
						onClick={() => window.open("https://theinformationlab.freeagent.com/timeslips/new", "_blank")}
						endIcon={<InsertInvitationIcon />}
					>
						Record Holiday
					</Button>
				</Grid>
			</Grid>
		  </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
