import React from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import {
  StyledEngineProvider,
} from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import Navigator from "../../components/Navigator/Navigator";
import Header from "../../components/Header/Header";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import AuthorizedApolloProvider from '../../apolloProvider';
import ClientsContextProvider from "../../components/Content/Clients/Context/Context";
import Home from "../../components/Content/Home/Home";
import Clients from "../../components/Content/Clients/Clients";
import Client from "../../components/Content/Clients/Client/Client";
import Sales from "../../components/Content/Sales/Sales";
import OfficeLocator from "../../components/Content/OfficeLocator/OfficeLocator";
import EmailSignature from "../../components/Content/EmailSignature/EmailSignature";
import DSProfile from "../../components/Content/DSProfile/DSProfile";
import Callback from "../../components/Callback/Callback";

let theme = createTheme({
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
});

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

class Portal extends React.Component {
  state = {
    navOpen: false,
    tokenSet: false,
    auth0Profile: {},
    salesAccess: false,
    dsProfileAccess: false,
    clientState: {
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 25,
    },
    clientFilters: [],
    salesState: {
      order: "desc",
      orderBy: "id",
      page: 0,
      rowsPerPage: 25,
    },
    salesFilters: [],
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ navOpen: !state.navOpen }));
  };

  handleDrawerClose = () => {
    this.setState({ navOpen: false });
  };

  handleUserProfileChange = (profile) => {
    console.log("[Portal.js] User profile updating to", profile);
    if (profile.error && profile.error === "Unauthorized") {
      if (typeof window !== "undefined") {
        // window.location.href = 'https://auth.theinformationlab.co.uk';
      }
    }
    this.setState({ auth0Profile: profile });
    if (profile && profile.groups && profile.groups.length > 0) {
      let hasSalesAccess = profile.groups.indexOf("TIL Global Sales Team") > -1 || profile.groups.indexOf("UK Core Team") > -1;
      let hasDSProfileAccess = profile.groups.indexOf("The Data School UK") > -1;
      console.log("[Portal.js] Sales access", hasSalesAccess);
      console.log("[Portal.js] DS Profile access", hasDSProfileAccess);
      this.setState({
        salesAccess: hasSalesAccess,
        dsProfileAccess: hasDSProfileAccess
      });
    }
    console.log("[Portal.js] New auth0Profile", this.state);
  };

  handleClientsStateChange = (state) => {
    console.log("[Portal.js] Setting Clients State", state);
    const salesState = this.state.salesState;
    salesState.rowsPerPage = state.rowsPerPage;
    this.setState({ clientState: state });
    this.setState({ salesState: salesState})
    console.log("[Portal.js] New State", this.state);
  };

  handleChangeClientsFilters = (filters) => {
    console.log("[Portal.js] Filtering Clients", filters);
    // useUpdateUrlFilters(filters);
    this.setState({ clientFilters: filters });
  };

  handleSalesStateChange = (state) => {
    console.log("[Portal.js] Setting Sales State", state);
    const clientState = this.state.clientState;
    clientState.rowsPerPage = state.rowsPerPage;
    this.setState({ salesState: state });
    this.setState({ clientState: clientState });
    console.log("[Portal.js] New State", this.state);
  };

  handleChangeSalesFilters = (filters) => {
    console.log("[Portal.js] Filtering Sales", filters);
    // useUpdateUrlFilters(filters);
    this.setState({ salesFilters: filters });
  };

  handleGetAuth0Profile = () => {
    const token = localStorage.getItem('token');
    fetch('/api/auth0/usermeta', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('[Portal.js] Auth0 Usermeta Success:', data);
            this.handleUserProfileChange(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }

  localStorageSetHandler = function(e, tokenSet) {
    if (e.key === 'token' && !tokenSet) {
      this.setState({ tokenSet: true });
      document.removeEventListener("itemInserted", this.localStorageSetHandler, false);
      this.handleGetAuth0Profile();
    }
  };

  componentDidMount() {

    var originalSetItem = localStorage.setItem;

    localStorage.setItem = function(key, value) {
      var event = new Event('itemInserted');
  
      event.value = value; // Optional..
      event.key = key; // Optional..
  
      document.dispatchEvent(event);
  
      originalSetItem.apply(this, arguments);
    };
  
    document.addEventListener("itemInserted", (e) => this.localStorageSetHandler(e, this.state.tokenSet), false);

    console.log('[Portal.js] ')

  
  }

  render() {
    const { classes } = this.props;

    return (
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain="theinformationlab.eu.auth0.com"
          clientId="J1i2FSnhUHJsguHPzpSMrYM4TB7xm75p"
          redirectUri={window.location.origin}
          audience="https://theinformationlab.eu.auth0.com/api/v2/"
          useRefreshTokens={true}
        >
          <AuthorizedApolloProvider>
            <ClientsContextProvider auth0Profile={this.state.auth0Profile}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <div style={{display: "flex", minHeight: "100vh"}}>
                    <CssBaseline />
                    <Header
                      onDrawerToggle={this.handleDrawerToggle}
                      profile={this.state.auth0Profile}
                      pageTitle={"The Information Lab"}
                      open={this.state.navOpen}
                    />
                    <Navigator
                      open={this.state.navOpen}
                      salesAccess={this.state.salesAccess}
                      dsProfileAccess={this.state.dsProfileAccess}
                      onClose={this.handleDrawerToggle}
                      close={this.handleDrawerClose}
                    />
                    <div style={{flex: 1,display: "flex",flexDirection: "column",width: 'calc(100% - 56px)'}}>
                      <main style={{flex: 1,padding: "75px 12px 0",background: "#eaeff1"}}>
                        <Routes>
                          <Route path="/" element={<Home auth0Profile={this.state.auth0Profile}/>} />
                          <Route path="clients/:id" element={<Client
                            salesAccess={this.state.salesAccess}
                            auth0Profile={this.state.auth0Profile}
                            refreshAuth0={this.handleGetAuth0Profile}
                          />} />
                          <Route 
                            path="/clients" 
                            element={<Clients
                                auth0Profile={this.state.auth0Profile}
                                salesAccess={this.state.salesAccess}
                                updateState={this.handleClientsStateChange}
                                state={this.state.clientState}
                                filters={this.state.clientFilters}
                                changeFilters={this.handleChangeClientsFilters}
                              />} 
                            />
                          <Route 
                            path="sales" 
                            element={<Sales
                              auth0Profile={this.state.auth0Profile}
                              salesAccess={this.state.salesAccess}
                              updateState={this.handleSalesStateChange}
                              state={this.state.salesState}
                              filters={this.state.salesFilters}
                              changeFilters={this.handleChangeSalesFilters}
                              />} 
                          />
                          {/* <Route 
                            exact 
                            path="/dataschool"
                            element={<Navigate to="/dataschool/profile" replace />}
                            /> */}
                          <Route path="/dataschool" element={<DSProfile auth0Profile={this.state.auth0Profile}/>} />
                          <Route path="locator" element={<OfficeLocator auth0Profile={this.state.auth0Profile}/>} />
                          <Route 
                            path="signature" 
                            element={<EmailSignature />} 
                          />
                          <Route path='callback' element={<Callback />}/>
                          <Route
                            path="*"
                            element={
                              <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                              </main>
                            }
                          />
                        </Routes>
                      </main>
                    </div>
                  </div>
                </ThemeProvider>
              </StyledEngineProvider>
            </ClientsContextProvider>
          </AuthorizedApolloProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    );
  }
}

export default Portal;